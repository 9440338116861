import React from "react";

const SignupSigninFooter = () => {
    return (
        <footer class="">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a href="privacy-policy.html">Privacy Policy</a>
                            </li>
                            <li class="list-inline-item">
                                <a href="terms-of-service.html">Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 text-right">
                        <span class="mr-1">
                            Copyright
                            <script>
                                document.write(new Date().getFullYear())
                            </script>
                            ©{" "}
                            <a href="#" class="">
                                RedSpeed
                            </a>
                            All Rights Reserved.
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default SignupSigninFooter;
