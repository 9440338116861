import React from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";

const DisciplinaryMeasure = () => {
    return(
        <>
         <NavBar/>
        <SideBar/>
        </>
    )
}

export default DisciplinaryMeasure;