import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableContainer,
  TableSortLabel,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../state/actions/rider.actions";
import Select from "react-select";
import { styled, alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { getWallet, downloadWalletReport, downloadWalletBalanceReport } from "../../state/actions";
import moment from "moment";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function getNestedProperty(obj, path) {
  const pathArray = path.split('.');
  return pathArray.reduce((acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined), obj);
}

function descendingComparator(a, b, orderBy) {
  let valueA = getNestedProperty(a, orderBy);
  let valueB = getNestedProperty(b, orderBy);

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    valueA = valueA.toUpperCase();
    valueB = valueB.toUpperCase();
  }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "paymentDate",
    numeric: false,
    disablePadding: true,
    label: "PAYMENT DATE",
  },
  {
    id: "wallet.customerName",
    numeric: false,
    disablePadding: false,
    label: "CUSTOMER",
  },
  {
    id: "reference",
    numeric: false,
    disablePadding: false,
    label: "TRANSACTION DESCRIPTION",
  },
  {
    id: "mode",
    numeric: false,
    disablePadding: false,
    label: "AMOUNT CREDIT",
  },
  {
    id: "mode",
    numeric: false,
    disablePadding: false,
    label: "AMOUNT DEBIT",
  },
  // {
  //   id: "wallet.currentBalance",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "CURRENT BALANCE",
  // },
  {
    id: "mode",
    numeric: false,
    disablePadding: false,
    label: "TRANSACTION TYPE",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ whiteSpace: "nowrap" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Wallet = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("2022-09-01");
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const walletPayment = useSelector((state) => state.finance.walletPayments);
  const isLoading = useSelector((state) => state.finance.status);
  const isDownload = useSelector((state) => state.finance.download);
  console.log("wallet :>>", walletPayment);

  const [firstName, setFirstName] = useState();
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [filterMode, setFilterMode] = useState(null);

  const w = {
    name: firstName ? firstName.value : "",
    startDate: startDate,
    endDate: endDate,
  };

  useEffect(() => {
    dispatch(getWallet(w));
    dispatch(getCustomers()).then((res) => {
      const custs = res.map((c) => ({
        label: c.companyName
          ? c.firstName + " " + c.lastName + " ( " + c.companyName + " )"
          : c.firstName + " " + c.lastName,
        value: c.firstName,
      }));
      const custSorted = custs.sort((a, b) => {
        const nameA = a.value?.toLowerCase();
        const nameB = b.value?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setOptions(custSorted);
    });
  }, []);

  const filteredPayments = useCallback(() => {
    if (!searchParam && !dateFilter) {
      return walletPayment;
    }

    return walletPayment.filter((wallet) => {
      // Convert the search parameter to lowercase for case-insensitive search
      const searchTerm = searchParam.toLowerCase();

      const containsSearchTerm = (obj) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {
            if (containsSearchTerm(value)) {
              return true;
            }
          } else if (String(value).toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
        return false;
      };

      // Date filter
      const dateMatches =
        !dateFilter || moment(wallet.paymentDate).isSame(dateFilter, "day");

      return containsSearchTerm(wallet) && dateMatches;
    });
  }, [walletPayment, searchParam, dateFilter]);

  const closeFilter = () => {
    setDateFilter(null);
    setFilterMode(null);
  };

  const handleNameChange = (data) => {
    console.log("selected", data);
    setFirstName(data);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - walletPayment.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleExport = () => {
    dispatch(downloadWalletReport(w));
  };
  const handleExportBalance = () => {
    dispatch(downloadWalletBalanceReport(w));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = (e) => {
    const { value } = e.target;
    setSearchParam(value);
  };

  const [openViewDetail, setOpenViewDetail] = useState(false);
  const handleCloseViewDetail = () => {
    setOpenViewDetail(false);
  };

  const [selectedWalletPayment, setSelectedWalletPayment] = useState({});
  const selectWalletPayment = (wallet) => {
    setSelectedWalletPayment(wallet);
    console.log("selectedWallet :>>", wallet);
    setOpenViewDetail(true);
  };

  const handleGetWalletPayments = (e) => {
    e.preventDefault();
    console.log("w :>>", w);
    dispatch(getWallet(w));
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("paymentDate");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredPayments(), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredPayments, order, orderBy, page, rowsPerPage]
  );
  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Wallet</h4>
                  </div>
                  {walletPayment.length > 0 ? (
                    <div className="">
                      {isDownload === "idle" ? (
                        <>
                        <button
                          type="button"
                          className="btn btn-md btn-primary"
                          onClick={() => handleExport()}
                        >
                          Export Report
                        </button>&nbsp;&nbsp;
                        <button
                        type="button"
                        className="btn btn-md btn-primary"
                        onClick={() => handleExportBalance()}
                      >
                        Export Balance Report
                      </button>
                      </>
                      ) : (
                        <button className="btn btn-md btn-primary" disabled>
                          Downloading...
                        </button>
                      )}
                      <FilterListIcon
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        onClick={() => setFilterMode(0)}
                      />
                    </div>
                  ) : (
                    <div className=""></div>
                  )}
                </div>
                <div className="card-body">
                  <div>
                    <form onSubmit={handleGetWalletPayments} className="d-flex">
                      <div className="row">
                        <div className="col-lg-11 col-xl-11">
                          <div className="row">
                            <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                              <p
                                style={{ fontSize: "14px" }}
                                className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                              >
                                FirstName:
                              </p>
                              <Select
                                className="col-6 col-sm-7 col-xl-8 px-0"
                                options={options}
                                value={firstName}
                                isSearchable={true}
                                isClearable={true}
                                onChange={handleNameChange}
                              />
                            </div>
                            <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                              <p
                                style={{ fontSize: "14px" }}
                                className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                              >
                                Start Date:
                              </p>
                              <input
                                className="col-6 col-sm-7 col-xl-8 form-control form-control-sm"
                                name="earnPercentage"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                            </div>
                            <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                              <p
                                style={{ fontSize: "14px" }}
                                className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                              >
                                End Date:
                              </p>
                              <input
                                className="col-6 col-sm-7 col-xl-8 form-control form-control-sm"
                                name="endDate"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1 col-xl-1 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                    {isLoading === "loading" ? (
                      <div className="text-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <p className="small">Fetching Wallet Payments...</p>
                      </div>
                    ) : walletPayment.length > 0 ? (
                      <>
                        <div className="flex flex-row-reverse">
                          <div className="col-4">
                            {filterMode === 0 && (
                              <>
                                <Close
                                  style={{ float: "right" }}
                                  onClick={() => closeFilter()}
                                />
                                <div className="form-group">
                                  <label for="dateFilter">Select Date</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={dateFilter}
                                    onChange={(e) =>
                                      setDateFilter(e.target.value)
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <Box>
                          <div className="form-group">
                            <label className="form-label">Search</label>
                            <input
                              type="text"
                              onChange={search}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </Box>
                        <TableContainer>
                          <Table aria-labelledby="tableTitle">
                            <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              rowCount={rowsPerPage.length}
                            />
                            <TableBody>
                              {visibleRows?.map((wallet, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      margin: "0 !important",
                                      padding: "0.2 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {moment(wallet.paymentDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      margin: "0 !important",
                                      padding: "0.2 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {wallet.wallet.customerName}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      margin: "0 !important",
                                      padding: "0.2 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {wallet.reference === null
                                      ? "Wallet Top-up"
                                      : wallet.reference}
                                  </TableCell>
                                  {wallet.mode === "CREDIT" ? (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{
                                        margin: "0 !important",
                                        padding: "0.2 !important",
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      &#8358;
                                      {wallet.amount
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{
                                        margin: "0 !important",
                                        padding: "0.2 !important",
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                    ></TableCell>
                                  )}
                                  {wallet.mode === "DEBIT" ? (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{
                                        margin: "0 !important",
                                        padding: "0.2 !important",
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      &#8358;
                                      {wallet.amount
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{
                                        margin: "0 !important",
                                        padding: "0.2 !important",
                                      }}
                                      style={{ whiteSpace: "nowrap" }}
                                    ></TableCell>
                                  )}
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      margin: "0 !important",
                                      padding: "0.2 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    &#8358;
                                    {wallet.balance
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      margin: "0 !important",
                                      padding: "0.2 !important",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {wallet.mode}
                                  </TableCell>
                                </TableRow>
                              ))}
                              {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                  <TableCell colSpan={6} />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : null}
                  </div>
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      component="div"
                      count={filteredPayments().length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
