import {
  Box,
   Table,
   TableHead,
   TableBody,
   TableRow,
   TableCell,
   TablePagination,
   Dialog,
   DialogContent,
   DialogContentText,
 } from "@mui/material";
 import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import React, { useCallback, useEffect, useState} from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux"
import { 
  getApprovedRiders,
  getRiderTemplate,
  importRider
} from "../../state/actions/rider.actions";
import {
  getApprovedPartners
} from "../../state/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const ConfirmedRider = () => {

   const dispatch = useDispatch();
   const approvedRiders = useSelector((state) => state.riders.approvedRiders);
   const partners = useSelector((state) => state.partner.approvedPartners);
   const isLoading = useSelector((state) => state.riders.status)

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("")
   const [openViewDetail, setOpenViewDetail] = useState(false);
   const [importModal, setImportModal] = useState(false)
  const [partnerId, setPartnerId] = useState(null)
  const [uploadFile, setUploadFile] = useState(null);

   const handleCloseViewDetail = () => {
    setOpenViewDetail(false);
   };

   const openImportModal = () => {
    setImportModal(true)
  }
  const closeImportModal = () => {
    setImportModal(false)
    setPartnerId(null)
    setUploadFile(null)
  }

  const handleChange = (e) => {
    const { files } = e.target;
    setUploadFile(files[0])
    console.log('file', uploadFile)
  }

   useEffect(() => {
    dispatch(getApprovedRiders())
    dispatch(getApprovedPartners())
   }, []);

   const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - approvedRiders.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = (e) => {
    const { value } = e.target
    setSearchParam(value)
  }

   const [selectedRider, setSelectedRider] = useState({});
   const selectRider = (rider) => {
    setSelectedRider(rider);
    console.log("selectedRider :>> ", rider);
    setOpenViewDetail(true);
   };

   function riderStatus(status) {
    switch (status) {
      case 0:
        return (<span className="badge badge-warning">Pending</span>);
      case 1: 
        return (<span className="badge badge-success">Approved</span>);
      default:
        return (<span className="badge badge-warning">Pending</span>);
    }
   }

   const handleRiderTemplate = () => {
    dispatch(getRiderTemplate())
  }

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setPartnerId(value)
  }

  const handleImport = () => {
    var id = parseInt(partnerId)
    var file = uploadFile
    dispatch(importRider(id, file)).then(() => {
      dispatch(getApprovedRiders());
      setImportModal(false);
      setPartnerId(null)
    })
  }
  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Confirmed Riders</h4>
                  </div>
                  <button
                    type="button"
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      openImportModal();
                    }}
                  >
                    Import
                  </button>
                </div>
                <div className="card-body">
                  <div>
                    {isLoading === 'loading' ?
                    <div className="text-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p className="small">Getting Approved Riders...</p>
                    </div> :
                    approvedRiders.length > 0 ? (
                      <>
                      <Box>
                          <div className="form-group">
                            <label className="form-label">Search</label>
                            <input type="text" onChange={search} className="form-control form-control-sm" />
                          </div>
                        </Box>
                      <Table ariaa-labelledby="tableTitle">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                            {/* <TableCell>Email</TableCell> */}
                            <TableCell>Telephone</TableCell>
                            <TableCell>Verification Status</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? approvedRiders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : approvedRiders
                            )?.filter((o) =>
                              o.firstName.toLowerCase().includes(searchParam.toLowerCase()) ||
                              o.lastName.toLowerCase().includes(searchParam.toLowerCase())
                            )?.map((approvedRider, index) => (
                              <TableRow hover tabindex={-1} key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 {approvedRider.firstName} {approvedRider.lastName}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 { approvedRider.address }
                                </TableCell>
                                {/* <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 { approvedRider.email }
                                </TableCell> */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 { approvedRider.telephone}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 {/* <riderStatus status={approvedRider.status} /> */}
                                 {/* { riderStatus(approvedRider.status) } */}
                                 { approvedRider.status === 1 && <span className="badge badge-success">Approved</span>}
                                </TableCell>
                                <TableCell 
                                component="th" 
                                scope="row"
                                sx={{
                                  padding: "0 !important"
                                }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {
                                      selectRider(approvedRider);
                                    }}
                                  >
                                    View Details
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>
                      </>
                    ) :
                    <div className="text-center">
                      <p>No Rider available</p>
                    </div>
                    }
                  </div>
                  <div>
                    <TablePagination
                     rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
                     component="div"
                     count={approvedRiders.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     SelectProps={{
                       inputProps: {
                         'aria-label': 'rows per page',
                       },
                       native: true,
                     }}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                     ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openViewDetail}
        onClose={handleCloseViewDetail}
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <DialogTitle>Rider Details</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <div className="modalContent">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalCenteredScrollableTitle"
                >
                  Rider Information
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                  aria-hidden="true"
                  onClick={handleCloseViewDetail}
                  >×</span>
                </button>
              </div>
              <div className="modal-body">

                <div className="media align-items-start">
                  <img
                    src="assets/images/user/1.jpg"
                    className="img-fluid avatar-rounded avatar-60"
                    alt="user"
                  />
                  <div className="media-body ml-2 row">
                    <div className="col-md-8">
                      <p className="mb-0 mt-1">
                        { selectedRider.firstName } { selectedRider.lastName}
                      </p>
                      <span className="font-weight-bold">
                        {selectedRider.telephone }
                      </span>
                    </div>
                    {/* <div className="col-md-3 pt-2">
                      <button type="button" className="btn btn-primary btn-sm">
                        Disable
                      </button>
                    </div> */}
                  </div>
                </div>
                {/* <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Contact Email</p>
                    <span className="font-weight-bold d-block">
                     { selectedRider.email }
                    </span>
                  </div>
                </div> */}
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Contact Address</p>
                    <span className="font-weight-bold d-block">
                     { selectedRider.address }
                    </span>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <Table aria-labelledby="tableTitle">
                     <TableBody>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              1
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              Driver's License
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                           </TableCell>
                           <TableCell component="th">
                              <span className="badge badge-success">
                                  Approved
                              </span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <a
                              href={`${selectedRider.driverLicenseUrl}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-secondary btn-sm">
                                 Open
                              </a>
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              2
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              LASSRA Card
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                           </TableCell>
                           <TableCell component="th">
                              <span className="badge badge-success">
                                  Approved
                              </span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <a
                              href={`${selectedRider.lassraCardUrl}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-secondary btn-sm">
                                 Open
                              </a>
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              3
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              Passport
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                           </TableCell>
                           <TableCell component="th">
                              <span className="badge badge-success">
                                  Approved
                              </span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <a
                              href={`${selectedRider.passportUrl}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-secondary btn-sm">
                                 Open
                              </a>
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Import Dialog */}
      <Dialog
        open={importModal}
        onClose={closeImportModal}
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <DialogTitle>Rider Details</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <div className="modal-content">
              <div className="modal-header">
                <p
                  className="modal-title"
                  id="exampleModalCenteredScrollableTitle"
                >
                  Import Riders
                </p>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={closeImportModal}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
              ` <p className="">
                  You are expected to maintain the format in the
                  template. Kindly confirm all data before upload to
                  avoid mistake. Any incorrect data in the
                  spreadsheet might lead to upload error.
                </p>
                <div className="">
                  <button
                    class='btn btn-primary add-stock-btn px-4 mb-3 w-100'
                    onClick={() => handleRiderTemplate()}
                  >
                      Download Template
                  </button>
                </div>
                <div>
                  <div class="form-group in-border">
                    <label for="partnerId">Select Partner</label>
                    <select
                    class="form-select custom-select shadow-none"
                    id="partnerId"
                    name="partnerId"
                    value={partnerId}
                    onChange={(e) => handleSelectChange(e)}
                    >
                      <option selected>Please Select</option>
                      { partners.map(p => (
                        <option key={p.id} value={p.id}>
                          { p.companyName }
                        </option>
                      ))}
                    </select>
                  </div>
                  {partnerId && <div class="upload-file mb-4">
                    <label for="partnerId">Select File (Excel)</label>
                    <input class="form-control form-control-sm" 
                    name="uploadFile" 
                    id="uploadFile" 
                    type="file" 
                    onChange={handleChange}
                    />
                  </div>}
                  {uploadFile && <div>
                    { isLoading === 'idle' && <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleImport}
                      >
                        Import
                      </button>}
                      { isLoading === 'loading' && <button
                        type="button"
                        className="btn btn-success"
                        disabled
                      >
                        Uploadind File <i className="fas fa-spinner fa-spin"></i>
                      </button>}
                  </div>}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default ConfirmedRider;
