import React, { useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import Input from "../../components/UI/Input";

const UserProfile = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [city, setCity] = useState("");
    const [maritalStatus, setMaritalStatus] = useState([]);

    const handleSubmitUserProfile = (e) => {
        e.preventDefault();
        console.log("firstName", firstName);
        console.log("lastName :>> ", lastName);
        console.log("userName :>> ", userName);
        console.log("city :>> ", city);
        console.log("maritalStatus :>> ", maritalStatus);
    };
    const computeFullName = () => {
        return `${firstName} ${lastName}`;
    };
    console.log("computeFullName()", computeFullName());
    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mm-edit-list-data">
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade active show"
                                        id="personal-information"
                                        role="tabpanel"
                                    >
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <div className="header-title">
                                                    <h4 className="card-title">
                                                        Personal Information
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <form
                                                    onSubmit={
                                                        handleSubmitUserProfile
                                                    }
                                                >
                                                    <div className="form-group row align-items-center">
                                                        <div className="col-md-12">
                                                            <div className="profile-img-edit">
                                                                <div className="crm-profile-img-edit">
                                                                    <img
                                                                        className="crm-profile-pic rounded-circle avatar-100"
                                                                        src="assets/profile_files/1.jpg"
                                                                        alt="profile-pic"
                                                                    />
                                                                    <div className="crm-p-image bg-primary">
                                                                        <i className="las la-pen upload-button"></i>
                                                                        <input
                                                                            className="file-upload"
                                                                            type="file"
                                                                            accept="image/*"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" row align-items-center">
                                                        <div className="form-group col-sm-6">
                                                            {/* <label for="fname">
                                                                First Name:
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fname"
                                                                value="Barry"
                                                            /> */}
                                                            <Input
                                                                label="First Name"
                                                                value={
                                                                    firstName
                                                                }
                                                                placeholder={``}
                                                                onChange={(e) =>
                                                                    setFirstName(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <Input
                                                                label="Last Name"
                                                                value={lastName}
                                                                placeholder={``}
                                                                onChange={(e) =>
                                                                    setLastName(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <Input
                                                                label="User Name"
                                                                value={userName}
                                                                placeholder={``}
                                                                onChange={(e) =>
                                                                    setUserName(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <Input
                                                                label="City"
                                                                value={city}
                                                                placeholder={``}
                                                                onChange={(e) =>
                                                                    setCity(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <label className="d-block">
                                                                Gender:
                                                            </label>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="customRadio6"
                                                                    name="customRadio1"
                                                                    className="custom-control-input"
                                                                    checked=""
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    for="customRadio6"
                                                                >
                                                                    {" "}
                                                                    Male{" "}
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="customRadio7"
                                                                    name="customRadio1"
                                                                    className="custom-control-input"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    for="customRadio7"
                                                                >
                                                                    {" "}
                                                                    Female{" "}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <Input
                                                                label="Date Of Birth"
                                                                value={
                                                                    firstName
                                                                }
                                                                placeholder={``}
                                                                onChange={(e) =>
                                                                    setFirstName(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div className="form-group col-sm-6">
                                                            <Input
                                                                type="select"
                                                                label="Marital Status"
                                                                value={
                                                                    maritalStatus
                                                                }
                                                                options={[
                                                                    {
                                                                        name: "Single",
                                                                        value: "single",
                                                                    },
                                                                    {
                                                                        name: "Married",
                                                                        value: "married",
                                                                    },
                                                                    {
                                                                        name: "Divorced",
                                                                        value: "divorced",
                                                                    },
                                                                ]}
                                                                placeholder={``}
                                                                onChange={(e) =>
                                                                    setMaritalStatus(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                            <label>
                                                                Marital Status:
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                id="exampleFormControlSelect1"
                                                            >
                                                                <option selected="">
                                                                    Single
                                                                </option>
                                                                <option>
                                                                    Married
                                                                </option>
                                                                <option>
                                                                    Widowed
                                                                </option>
                                                                <option>
                                                                    Divorced
                                                                </option>
                                                                <option>
                                                                    Separated{" "}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <label>Age:</label>
                                                            <select
                                                                className="form-control"
                                                                id="exampleFormControlSelect2"
                                                            >
                                                                <option>
                                                                    12-18
                                                                </option>
                                                                <option>
                                                                    19-32
                                                                </option>
                                                                <option selected="">
                                                                    33-45
                                                                </option>
                                                                <option>
                                                                    46-62
                                                                </option>
                                                                <option>
                                                                    63 &gt;{" "}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <label>
                                                                Country:
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                id="exampleFormControlSelect3"
                                                            >
                                                                <option>
                                                                    Caneda
                                                                </option>
                                                                <option>
                                                                    Noida
                                                                </option>
                                                                <option selected="">
                                                                    USA
                                                                </option>
                                                                <option>
                                                                    India
                                                                </option>
                                                                <option>
                                                                    Africa
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-sm-6">
                                                            <label>
                                                                State:
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                id="exampleFormControlSelect4"
                                                            >
                                                                <option>
                                                                    California
                                                                </option>
                                                                <option>
                                                                    Florida
                                                                </option>
                                                                <option selected="">
                                                                    Georgia
                                                                </option>
                                                                <option>
                                                                    Connecticut
                                                                </option>
                                                                <option>
                                                                    Louisiana
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-sm-12">
                                                            <label>
                                                                Address:
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                name="address"
                                                                rows="5"
                                                                style={{
                                                                    lineHeight:
                                                                        "22px",
                                                                }}
                                                            >
                                                                37 Cardinal Lane
                                                                Petersburg, VA
                                                                23803 United
                                                                States of
                                                                America Zip
                                                                Code: 85001
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="reset"
                                                        className="btn btn-outline-primary mr-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="chang-pwd"
                                        role="tabpanel"
                                    >
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <div className="header-title">
                                                    <h4 className="card-title">
                                                        Change Password
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <form>
                                                    <div className="form-group">
                                                        <label for="cpass">
                                                            Current Password:
                                                        </label>
                                                        <a
                                                            href="javascripe:void();"
                                                            className="float-right"
                                                        >
                                                            Forgot Password
                                                        </a>
                                                        <input
                                                            type="Password"
                                                            className="form-control"
                                                            id="cpass"
                                                            value=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="npass">
                                                            New Password:
                                                        </label>
                                                        <input
                                                            type="Password"
                                                            className="form-control"
                                                            id="npass"
                                                            value=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="vpass">
                                                            Verify Password:
                                                        </label>
                                                        <input
                                                            type="Password"
                                                            className="form-control"
                                                            id="vpass"
                                                            value=""
                                                        />
                                                    </div>
                                                    <button
                                                        type="reset"
                                                        className="btn btn-outline-primary mr-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="emailandsms"
                                        role="tabpanel"
                                    >
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <div className="header-title">
                                                    <h4 className="card-title">
                                                        Email and SMS
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <form>
                                                    <div className="form-group row align-items-center">
                                                        <label
                                                            className="col-md-3"
                                                            for="emailnotification"
                                                        >
                                                            Email Notification:
                                                        </label>
                                                        <div className="col-md-9 custom-control custom-switch">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="emailnotification"
                                                                checked=""
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                for="emailnotification"
                                                            ></label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row align-items-center">
                                                        <label
                                                            className="col-md-3"
                                                            for="smsnotification"
                                                        >
                                                            SMS Notification:
                                                        </label>
                                                        <div className="col-md-9 custom-control custom-switch">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="smsnotification"
                                                                checked=""
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                for="smsnotification"
                                                            ></label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row align-items-center">
                                                        <label
                                                            className="col-md-3"
                                                            for="npass"
                                                        >
                                                            When To Email
                                                        </label>
                                                        <div className="col-md-9">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="email01"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    for="email01"
                                                                >
                                                                    You have new
                                                                    notifications.
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="email02"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    for="email02"
                                                                >
                                                                    You're sent
                                                                    a direct
                                                                    message
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="email03"
                                                                    checked=""
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    for="email03"
                                                                >
                                                                    Someone adds
                                                                    you as a
                                                                    connection
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row align-items-center">
                                                        <label
                                                            className="col-md-3"
                                                            for="npass"
                                                        >
                                                            When To Escalate
                                                            Emails
                                                        </label>
                                                        <div className="col-md-9">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="email04"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    for="email04"
                                                                >
                                                                    {" "}
                                                                    Upon new
                                                                    order.
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="email05"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    for="email05"
                                                                >
                                                                    {" "}
                                                                    New
                                                                    membership
                                                                    approval
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="email06"
                                                                    checked=""
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    for="email06"
                                                                >
                                                                    {" "}
                                                                    Member
                                                                    registration
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="reset"
                                                        className="btn btn-outline-primary mr-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="manage-contact"
                                        role="tabpanel"
                                    >
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <div className="header-title">
                                                    <h4 className="card-title">
                                                        Manage Contact
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <form>
                                                    <div className="form-group">
                                                        <label for="cno">
                                                            Contact Number:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="cno"
                                                            value="001 2536 123 458"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="email">
                                                            Email:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="email"
                                                            value="Barryjone@demo.com"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="url">
                                                            Url:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="url"
                                                            value="https://getbootstrap.com"
                                                        />
                                                    </div>
                                                    <button
                                                        type="reset"
                                                        className="btn btn-outline-primary mr-2"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserProfile;
