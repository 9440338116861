import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Dialog
  } from "@mui/material";
 import React, { useCallback, useEffect, useState } from "react";
 import NavBar from "../../components/navbar/navbar.component";
 import SideBar from "../../components/sidebar/sidebar.component";
 import { useDispatch, useSelector } from "react-redux"
 import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { getPickUp, confirmPickUp } from "../../state/actions/pickUp.actions";
import { getDrivers, postRider } from "../../state/actions/rider.actions";
 
 const PickUpDetails = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.pickup.status);
    const isConfirm = useSelector((state) => state.pickup.cStatus);
    const isPosting = useSelector((state) => state.pickup.posting);
    const [page, setPage] = useState(0);
    const id = localStorage.getItem("bagId")

    const [pickUp, setPickup] = useState({})
    const [drivers, setDrivers] = useState([]);
 
    useEffect(() => {
       dispatch(getPickUp(id)).then((res) => {
        setPickup(res)
       })
    }, []);
 
    const handlePageChange = useCallback(
       (_, page) => {
          setPage(page);
       },
       [setPage]
    );

    const [isModal, setOpenModal] = useState(false)
    const [bag, selectedBag] = useState({})
    const [comment, setComment] = useState("")
    const [approve, setApproval] = useState(false)
    const [openAssign, setOpenAssign] = useState(false)
    const [rider, setRider] = useState(null)

    const handleModalOpen = (item) => {
      selectedBag(item)
      setOpenModal(true)
    }

    const handleModalClose = () => {
      setOpenModal(false)
      selectedBag({})
    }

    const handleAssignOpen = () => {
      setOpenAssign(true);
      dispatch(getDrivers()).then((res) => {
        setDrivers(res)
      })
    }

    const handleAssignClose = () => {
      setOpenAssign(false);
    }

    const handleInputChange = (e) => {
      setComment(e.target.value)
    }

    const handleConfirmPickUp = (v) => {
      setApproval(v)
      const data = {
        pickupBagId: bag.id,
        comment: comment,
        approved: approve
      }
      console.log('payload', data)
      dispatch(confirmPickUp(data)).then(() => {
        dispatch(getPickUp(id));
        handleModalClose();
      });
    };

    const handleRiderAssign = () => {
      const body = {
        riderId: rider,
        pickId: pickUp.id
      }
      dispatch(postRider(body)).then((res) => {
        dispatch(getPickUp(id))
        handleAssignClose()
      })
    }
 
    let rowsPerPage = 10;
   return (
     <>
       <NavBar />
       <SideBar />
 
       <div className="content-page">
         <div className="container-fluid">
           <div className="row">
             <div className="col-sm-12">
               <div className="card">
                 <div className="card-header d-flex justify-content-between align-items-center">
                   <div className="header-title">
                    <h4 className="card-title">Pick Up - { pickUp.pickupNo } </h4>
                   </div>
                   { pickUp.riderId === null && (
                    <button
                      type="button"
                      className="btn btn-md btn-primary"
                      onClick={handleAssignOpen}
                    >
                      Assign Rider
                    </button>
                    )
                   }
                 </div>
                 <div className="card-body">
                   <div>
                     {isLoading === 'loading' ?
                     <div className="text-center">
                       {/* <div className="spinner-border text-danger" role="status">
                         <span className="sr-only">Loading...</span>
                       </div> */}
                       <p className="small">Getting Pick Up Bags...</p>
                     </div> :
                     pickUp?.pickupItems?.length > 0 ? (
                       <Table aria-labelledby="tableTitle">
                         <TableHead>
                           <TableRow>
                             <TableCell>Waybill No</TableCell>
                             <TableCell>Date</TableCell>
                             <TableCell>Packaging</TableCell>
                             <TableCell>Weight</TableCell>
                             <TableCell>Pieces</TableCell>
                             {/* <TableCell>Status</TableCell> */}
                             <TableCell></TableCell>
                           </TableRow>
                         </TableHead>
                         <TableBody>
                           {pickUp.pickupItems?.length > 0 &&
                             pickUp.pickupItems?.map(
                               (
                                 bag,
                                 index
                               ) => (
                                 <TableRow
                                   hover
                                   tabindex={-1}
                                   key={index}
                                 >
                                   <TableCell
                                     component="th"
                                     scope="row"
                                     sx={{
                                       // width: "5% !important",
                                       padding: "0.5",
                                       margin: "0 !important"
                                     }}
                                   >
                                     {
                                       bag.shipment.waybillNumber
                                     }
                                   </TableCell>
                                   <TableCell
                                     component="th"
                                     scope="row"
                                     sx={{
                                       // width: "5% !important",
                                       padding: "0.5",
                                       margin: "0 !important"
                                     }}
                                   >
                                     {
                                       bag.shipment.createdOn ? new Date(bag.shipment.createdOn).toLocaleDateString() : null
                                     }
                                   </TableCell>
                                   <TableCell
                                     component="th"
                                     scope="row"
                                     sx={{
                                       padding: "0.5 !important",
                                       margin: "0 !important"
                                     }}
                                   >
                                       { bag.shipment.packaging }
                                   </TableCell>
                                   <TableCell
                                     component="th"
                                     scope="row"
                                     sx={{
                                       // width: "5% !important",
                                       padding: "0.5",
                                       margin: "0 !important"
                                     }}
                                   >
                                     {
                                       bag.shipment.weight + " kg"
                                     }
                                   </TableCell>
                                   <TableCell
                                     component="th"
                                     scope="row"
                                     sx={{
                                       // width: "5% !important",
                                       padding: "0.5",
                                       margin: "0 !important"
                                     }}
                                   >
                                     <span className="">
                                       { bag.shipment.unit + ' pcs' }
                                     </span>
                                   </TableCell>
                                   <TableCell
                                     component="th"
                                     scope="row"
                                     sx={{
                                       // width: "5% !important",
                                       padding: "0.5",
                                       margin: "0 !important"
                                     }}
                                   >
                                    <button
                                       type="button"
                                       className="btn btn-sm btn-primary"
                                       onClick={handleModalOpen}
                                       >
                                         Confirm/Reject
                                       </button>
                                   </TableCell>
                                 </TableRow>
                               )
                             )}
                         </TableBody>
                       </Table>
                     ) :
                     <div className="text-center">
                       <p>No Bags available for this pickup</p>
                     </div>
                     }
                   </div>
                   <div>
                     <TablePagination
                     component="div"
                     count={pickUp.pickupItems?.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     backIconButtonProps={{
                       "aria-label": "Previous Page",
                     }}
                     nextIconButtonProps={{
                       "aria-label": "Next Page",
                     }}
                     onPageChange={handlePageChange}
                     />
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
 
         {/* Confirm modal */}
         <div
          className="modal fade"
          id="confirm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalScrollableTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-scrollable"
            role="document"
          >
            <Dialog
            open={isModal}
            keepMounted
            onClose={handleModalClose}
            fullWidth={true}
            maxWidth="sm"
            >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalScrollableTitle"
                >
                  Confirm Pick Up?
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleModalClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="">
                  <div className="form-group">
                    <label htmlFor="comment" className="form-label">Comment</label>
                    <textarea placeholder="Please provide a comment" name="comment" id="comment" rows="4" className="form-control" onChange={handleInputChange}></textarea>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  disabled={isConfirm === "loading"}
                  onClick={() => handleConfirmPickUp(false)}
                >
                { isConfirm === "idle" ? "Reject" : "Please wait"}
                </button>
                
                  <button
                  type="button"
                  className="table-add btn btn-primary"
                  disabled={isConfirm === "loading"}
                  onClick={() => {
                    handleConfirmPickUp(true);
                  }}
                >
                  { isConfirm === "idle" ? "Confirm" : "Please wait"}
                </button>
              </div>
            </div>
            </Dialog>
          </div>
        </div>
        {/*Rider Assign */}
        <div
          className="modal fade"
          id="confirm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalScrollableTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-scrollable"
            role="document"
          >
            <Dialog
            open={openAssign}
            keepMounted
            onClose={handleAssignClose}
            fullWidth={true}
            maxWidth="sm"
            >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalScrollableTitle"
                >
                  Assign Rider
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleAssignClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="">
                <FormControl sx={{ m: 1, width: "25ch" }} size="small">
                    <InputLabel id="demo-select-small-label">Select Rider</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="rider"
                      value={rider}
                      label="Select Rider"
                      onChange={(e) => setRider(e.target.value)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {drivers?.map((d, key) => (
                        <MenuItem value={d.id} key={key}>
                          {d.firstName  + " " + d.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleAssignClose}
                >
                Close
                </button>
                
                  <button
                  type="button"
                  className="table-add btn btn-primary"
                  disabled={isPosting === "loading"}
                  onClick={() => {
                    handleRiderAssign();
                  }}
                >
                  { isPosting === "idle" ? "Assign" : "Please wait"}
                </button>
              </div>
            </div>
            </Dialog>
          </div>
        </div>
     </>
   );
 };
 
 export default PickUpDetails;
 