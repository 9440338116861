import axios from "axios";
import axiosInstance from "../../utils/axios";
import { 
    tripConstants ,
    homeConstants
} from "./constants";

export const getTrips = () => {
    return async (dispatch) => {
        dispatch({
            type: tripConstants.GET_TRIPS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/rides");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: tripConstants.GET_TRIPS_SUCCESS,
                payload: { allTrips: data },
            });
        } else {
        }
    };
};

export const getIncidents = () => {
    return async (dispatch) => {
        dispatch({
            type: tripConstants.GET_INCIDENT_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/incidentReports");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: tripConstants.GET_INCIDENT_SUCCESS,
                payload: { incidents: data },
            });
        } else {
        }
    };
};

export const updateIncident = (incident) => {
    return async (dispatch) => {
        dispatch({
            type: tripConstants.UPDATE_INCIDENT_REQUEST,
        });
        const res = await axiosInstance.put("/Admin/UpdateIncidentReport", incident);

        if (res.status === 200) {
            dispatch({
                type: tripConstants.UPDATE_INCIDENT_SUCCESS
            });
            alert("Incident resolved successfully")
        } else {
        }
    };
};

export const topPartners = () => {
    return async (dispatch) => {
        dispatch({
            type: homeConstants.TOP_PARTNERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/Partner/top");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: homeConstants.TOP_PARTNERS_SUCCESS,
                payload: { topPartners: data },
            });
            return data
        } else {
            dispatch({
                type: homeConstants.TOP_PARTNERS_ERROR,
            });
        }
    };
};

export const topRiders = () => {
    return async (dispatch) => {
        dispatch({
            type: homeConstants.TOP_RIDERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/rider/top");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: homeConstants.TOP_RIDERS_SUCCESS,
                payload: { topRiders: data },
            });
            return data;
        } else {
            dispatch({
                type: homeConstants.TOP_RIDERS_ERROR,
            });
        }
    };
};

export const topCustomers = () => {
    return async (dispatch) => {
        dispatch({
            type: homeConstants.TOP_CUSTOMERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/customer/top");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: homeConstants.TOP_CUSTOMERS_SUCCESS,
                payload: { topCustomers: data },
            });
            return data
        } else {
            dispatch({
                type: homeConstants.TOP_CUSTOMERS_ERROR,
            });
        }
    };
};

export const topVehicles = () => {
    return async (dispatch) => {
        dispatch({
            type: homeConstants.TOP_VEHICLES_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/vehicle/top");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: homeConstants.TOP_VEHICLES_SUCCESS,
                payload: { topVehicles: data },
            });
        } else {
        }
    };
};

export const orderReport = (o) => {
    return async (dispatch) => {
        dispatch({
            type: homeConstants.ORDER_REPORT_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/order/dashboard?start=${o.start}&end=${o.end}&option=${o.option}`);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: homeConstants.ORDER_REPORT_SUCCESS,
                payload: { orderReport: data },
            });
            return data;
        } else {
            dispatch({
                type: homeConstants.ORDER_REPORT_ERROR,
            });
        }
    };
};

export const revenueReport = (r) => {
    return async (dispatch) => {
        dispatch({
            type: homeConstants.REVENUE_REPORT_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/revenue/dashboard?start=${r.start}&end=${r.end}&option=${r.option}`);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: homeConstants.REVENUE_REPORT_SUCCESS,
                payload: { revenueReport: data },
            });
            return data;
        } else {
            dispatch({
                type: homeConstants.REVENUE_REPORT_ERROR,
            });
        }
    };
};

export const orderingTrends = () => {
    return async (dispatch) => {
        dispatch({
            type: homeConstants.ORDERING_TREND_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/orderTypeCount");

        if (res.status === 200) {
            const { data } = res;
            const s = data.map(s => s.key)
            const v = data.map(v => v.value)
            console.log('s', s)
            console.log('v', v)
            dispatch({
                type: homeConstants.ORDERING_TREND_SUCCESS,
                payload: { orderings: data },
            });
        } else {
        }
    };
};

export const shippingTrends = (option) => {
    return async (dispatch) => {
        dispatch({
            type: homeConstants.SHIPPING_TRIP_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/rideTypeCount?Option=${option}`);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: homeConstants.SHIPPING_TRIP_SUCCESS,
                payload: { shippings: data },
            });
        } else {
        }
    };
};
