import React, { useState } from "react";
import { useDispatch } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { changePassword } from "../../state/actions";

const ChangePassword = () => {
    // const [oldPassword, setOldPassword] = useState("");
    // const [newPassword, setNewPassword] = useState("");
    // const [confirmPassword, setConfirmPassword] = useState("");

    const [form, setForm] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const field = e.target.name;
        const nextFormState = {
            ...form,
            [field]: e.target.value,
        };
        setForm(nextFormState);
    };

    // const handleConfirmPassword = (e) => {
    //     const nextState = {
    //         ...form,
    //         confirmPassword: e.target.value,
    //     };
    //     setForm(nextState);
    // };

    if (form.newPassword === form.confirmPassword) {
        console.log("yessssssssssssssssss");
    } else {
        console.log("noooooooooooooooooo");
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        console.log("e", e);
        if (form.newPassword === form.confirmPassword) {
            dispatch(changePassword(form));
        } else {
            console.log("noooooooooooooooooo");
        }
    };

    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12 ">
                            <div className="card">
                                <div className="card-header justify-content-between">
                                    <div className="header-title">
                                        <h5 className="card-title">
                                            Change Password
                                        </h5>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleChangePassword}>
                                        <div
                                            className="tab-content"
                                            id="myTabContent-2"
                                        >
                                            <div
                                                className="tab-pane fade show active"
                                                id="form1"
                                                role="tabpanel"
                                                aria-labelledby="incity-tab"
                                            >
                                                <div className="form-row d-block">
                                                    <div className="form-group ml-2">
                                                        {/* <label for="validationDefault01">Distance Rate</label> */}
                                                        <input
                                                            type="password"
                                                            name="oldPassword"
                                                            value={
                                                                form.oldPassword
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            placeholder="Old Password"
                                                            className="form-control"
                                                            id="validationDefault01"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group ml-2">
                                                        {/* <label for="validationDefault02">Time Rate</label> */}
                                                        <input
                                                            type="password"
                                                            name="newPassword"
                                                            value={
                                                                form.newPassword
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            placeholder="New Password"
                                                            className="form-control"
                                                            id="validationDefault02"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group ml-2">
                                                        {/* <label for="validationDefaultUsername">Other Levy</label> */}
                                                        <input
                                                            type="password"
                                                            name="confirmPassword"
                                                            value={
                                                                form.confirmPassword
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            placeholder="Confirm New Password"
                                                            className="form-control"
                                                            id="validationDefaultUsername"
                                                            aria-describedby="inputGroupPrepend2"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn btn-primary mr-2"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
