import {
   Table,
   TableHead,
   TableBody,
   TableRow,
   TableCell,
   TablePagination,
   Dialog,
   DialogContent,
   DialogContentText,
 } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux"
import { getVerifiedVehicles, approveVehicle } from "../../state/actions/vehicle.actions"
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const UnconfirmedVehicle = () => {

   const dispatch = useDispatch();
   const verifiedVehicles = useSelector((state) => state.vehicles.verifiedVehicles);
   const isLoading= useSelector((state) => state.vehicles.status)
   console.log("verifiedVehicles", verifiedVehicles)

   const [page, setPage] = useState(0);
   const [openViewDetail, setOpenViewDetail] = useState(false);

   const handleCloseViewDetail = () => {
    setOpenViewDetail(false);
   };

   useEffect(() => {
    dispatch(getVerifiedVehicles())
   }, []);

   const handleApproveVehicle = (id) => {
      dispatch(approveVehicle(id)).then(() => {
        dispatch(getVerifiedVehicles());
        setOpenViewDetail(false);
      })
   };

   const handlePageChange = useCallback(
    (_, page) => {
      setPage(page);
    },
    [setPage]
   );

   function vehicleStatus(status) {
      switch (status) {
        case 0:
          return (<span className="badge badge-secondary">Pending</span>);
        case 1: 
          return (<span className="badge badge-success">Approved</span>);
        case 2:
          return (<span className="badge badge-info">Awaiting Inspection</span>);
        case 3:
          return (
            <span className="badge badge-warning">Awaiting Approval</span>
          );
        default:
          return (<span className="badge badge-secondary">Pending</span>);
      }
    }

   const [selectedVehicle, setSelectedVehicle] = useState({});
   const selectVehicle = (vehicle) => {
    setSelectedVehicle(vehicle);
    console.log("selectedVehicle :>> ", vehicle);
    setOpenViewDetail(true);
   };

  let rowsPerPage = 10;

  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
         <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
               <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Unconfirmed Vehicles</h4>
                  </div>
                </div>
                <div className="card-body">
                <div>
                    {isLoading === 'loading' ?
                    <div className="text-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p className="small">Getting Verified Vehicles...</p>
                    </div> :
                    verifiedVehicles.length > 0 ? (
                      <Table aria-labelledby="tableTitle">
                        <TableHead>
                          <TableRow>
                            <TableCell>Registration No.</TableCell>
                            <TableCell>Vehicle Brand</TableCell>
                            <TableCell>Vehicle Model</TableCell>
                            <TableCell>Vehicle Type</TableCell>
                            <TableCell>Vehicle Year</TableCell>
                            <TableCell>Verification Status</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                           {verifiedVehicles.length > 0 &&
                            verifiedVehicles.map(
                              (
                                verifiedVehicle,
                                index
                              ) => (
                                <TableRow
                                  hover
                                  tabindex={-1}
                                  key={index}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                       verifiedVehicle.regNo
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                       verifiedVehicle.vehicleBrand.name
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                       verifiedVehicle.model.name
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                       verifiedVehicle.vehicleType.name
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                       verifiedVehicle.year
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                       vehicleStatus(verifiedVehicle.status)
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                       margin: "0 !important",
                                       padding: "0 !important"
                                    }}
                                  >
                                    <button 
                                    className="btn btn-sm btn-primary w-100"
                                    onClick={() => {
                                       selectVehicle(verifiedVehicle);
                                    }}
                                    >
                                       Approve
                                    </button>
                                  </TableCell>
                                </TableRow>
                              )
                            )
                           }
                        </TableBody>
                      </Table>
                    ) :
                    <div className="text-center">
                      <p>No Vehicle pending approval</p>
                    </div>
                    }
                  </div>
                  <div>
                    <TablePagination
                    component="div"
                    count={verifiedVehicles.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                    }}
                    onPageChange={handlePageChange}
                    />
                  </div>
                </div>
               </div>
            </div>
          </div>
         </div>
      </div>

      <Dialog
        open={openViewDetail}
        onClose={handleCloseViewDetail}
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <DialogTitle>Rider Details</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalCenteredScrollableTitle"
              >
                Stage 3 Verification
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span 
                aria-hidden="true"
                onClick={handleCloseViewDetail}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="alert alert-info" role="alert">
                <div className="mm-alert-text">Approve Vehicle</div>
              </div>

              <div className="media align-items-start">
                <div className="media-body ml-2 row">
                  <div className="col-md-8">
                    <p className="mb-0 mt-1">{ selectedVehicle.regNo }</p>
                    <p className="mb-0 font-weight-bold">
                     <span className="">
                     { 
                        selectedVehicle &&
                        selectedVehicle.vehicleType &&
                        selectedVehicle.vehicleType.name
                     }
                     </span>
                     <span className="ml-2">
                     [
                        { 
                        selectedVehicle &&
                        selectedVehicle.vehicleSize &&
                        selectedVehicle.vehicleSize.name
                       }
                     ]
                     </span>
                     {/* selectedVehicle.vehicleType.name */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="media align-items-start mt-4">
                <div className="media-body">
                  <p className="text mb-0">Brand</p>
                  <span className="font-weight-bold d-block">
                  { 
                        selectedVehicle &&
                        selectedVehicle.vehicleBrand &&
                        selectedVehicle.vehicleBrand.name
                     }
                  {/* selectedVehicle.vehicleBrand.name */}
                  </span>
                </div>
              </div>
              <div className="media align-items-start mt-4">
                <div className="media-body">
                  <p className="text mb-0">Model</p>
                  <span className="font-weight-bold d-block">
                     { 
                        selectedVehicle &&
                        selectedVehicle.model &&
                        selectedVehicle.model.name
                     }
                  {/* selectedVehicle.model.name */}
                  </span>
                </div>
              </div>
              <div className="media align-items-start mt-4">
                <div className="media-body">
                  <p className="text mb-0">Year</p>
                  <span className="font-weight-bold d-block">
                     { selectedVehicle.year }
                  </span>
                </div>
              </div>
                <div className="media align-items-start mt-4">
                  <Table aria-labelledby="tableTitle">
                     <TableBody>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              1
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              Road Worthiness
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <span className="badge badge-info">Pending</span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <button
                              className="btn btn-secondary btn-sm">
                                 Open
                              </button>
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              2
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              Vehicle Registration
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <span className="badge badge-info">Pending</span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <button
                              className="btn btn-secondary btn-sm">
                                 Open
                              </button>
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              3
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              Road Insurance
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <span className="badge badge-info">Pending</span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <button
                              className="btn btn-secondary btn-sm">
                                 Open
                              </button>
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              4
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              Hackney Permit
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <span className="badge badge-info">Pending</span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <button
                              className="btn btn-secondary btn-sm">
                                 Open
                              </button>
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
                </div>
                {/* <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Inspection Note</p>
                    <textarea
                    className="form-control"
                    cols="30"
                    rows="5"
                    name="inspectionNote"
                  //   value={ selectedVehicle.inspectionNote }
                    
                  //   onChange={ handleVehicleInspectionInputChange }
                    ></textarea>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-12 pt-2">
                    { isLoading === 'idle' && <button
                    type="button"
                    className="btn btn-success"
                    onClick={ () => handleApproveVehicle(selectedVehicle.id) }
                    >
                      Approve
                    </button> }
                    { isLoading === 'loading' && <button
                    type="button"
                    className="btn btn-success"
                    disabled
                    >
                      Please wait <i className="fas fa-spinner fa-spin"></i>
                    </button> }
                    <button type="button" className="btn btn-primary mx-3">
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default UnconfirmedVehicle;
