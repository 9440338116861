import React from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";

const VehicleOverview = () => {
    return(
        <>
        <NavBar/>
        <SideBar/>

        <div class="content-page">
<div class="container-fluid">
<div class="row">   
<div class="col-lg-12 mb-3">
    <div class="d-flex align-items-center justify-content-between welcome-content">
    <div class="navbar-breadcrumb">
    <h4 class="mb-0 font-weight-700">Fleet Vehicle Overview</h4>
    </div>
    </div>
</div>
<div class="col-md-12">
<div class="row">
<div class="col-lg-4 col-md-6">
<div class="card card-block card-stretch card-height rule-box">
<div class="card-body align-items-center justify-content-center">
    <div class="top-block-one text-center">
        <div class="text-danger d-block mx-auto rounded">
        <img src="assets/images/icon/web/icons8-semi-truck-cute-outline-96.png" alt="semi truck"/>
        </div>
        <div class="mt-4">
        <h6><b>DELIVERY STATUS</b></h6>
        <div class="form-group mt-2">
        <select class="form-control ">
            <option>TODAY</option>
            <option>LAST WEEK</option>
            <option>LAST MONTH</option>
            <option>LAST YEAR</option>
         </select>
         </div>
        </div>
        <div class="text-dark d-flex justify-content-between" id="fleet">
            <p>Within time limit</p>
            <p class="sub-text">2478</p>
        </div>
        <div class="text-dark d-flex justify-content-between" id="fleet">
            <p>Out of time limit</p>
            <p class="sub-text">234</p>
        </div>
        <div class="text-dark d-flex justify-content-between" id="fleet">
            <p>Not delivered</p>
            <p class="sub-text">56</p>
        </div>
        <div class="card-body">
          <div id="hospital-chart-02"></div>
        </div>
    </div>
</div>
</div>
</div>
<div class="col-lg-8 col-md-6">
    <div class="row">

    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <div class="card card-block card-stretch card-height rule-box">
                <div class="card-body align-items-center justify-content-center">
                    <div class="top-block-one text-center">
                        <div class="text-danger d-block mx-auto rounded">
                        <img src="assets/images/icon/web/icons8-semi-truck-cute-outline-32.png" alt="semi truck"/>
                        </div>
                        <div class="mt-4">
                        <h5><b>TOTAL: 129</b></h5>
                        </div>
                        <div class="text-dark d-flex mt-3 justify-content-between" id="fleet">
                            <i class="fa-solid fa-truck sub-text"></i>
                            <p>On the move</p>
                            <p class="sub-text">94</p>
                        </div>
                        <div class="text-dark d-flex justify-content-between" id="fleet">
                            <i class="fa-solid fa-wrench sub-text"></i>
                            <p>In maintenance</p>
                            <p class="sub-text">15</p>
                        </div>
                        <div class="text-dark d-flex justify-content-between" id="fleet">
                            <i class="fa-solid fa-ban sub-text"></i>
                            <p>Unavailable</p>
                            <p class="sub-text">10</p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
</div>
        </>
    )
}

export default VehicleOverview;