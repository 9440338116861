import {
    Dialog,
    DialogContent,
    DialogContentText,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
  } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersIncomes } from "../../state/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Settlements = () => {
    const dispatch = useDispatch();
    const partnersIncome = useSelector((state) => state.finance.partnersIncomes);
    const isLoading = useSelector((state) => state.finance.status)
    console.log("partnersIncome :>>", partnersIncome)

    useEffect(() => {
        dispatch(getPartnersIncomes());
    }, []);
    const [page, setPage] = useState(0);
    const handlePageChange = useCallback(
      (_, page) => {
        setPage(page);
      },
      [setPage]
    )

    const [openViewDetail, setOpenViewDetail] = useState(false);
    const [openSettlement, setOpenSettlement] = useState(false);
    const handleCloseViewDetail = () => {
      setOpenViewDetail(false);
    }
    const handleOpenSettlement = () => {
      setOpenSettlement(true);
    }
    const handleCloseSettlement = () => {
      setOpenSettlement(false);
    }

    const [selectedPartnerIncome, setSelectedPartnerIncome] = useState({});
    const selectPartnerIncome = (partnerIncome) => {
      setSelectedPartnerIncome(partnerIncome);
      console.log("selectedPartnerIncome :>>", partnerIncome);
      setOpenViewDetail(true);
    }
    const [settlementForm, setSettlementForm] = useState({});
    const handleInputChange = (e) => {
      const field = e.target.name;
      const nextSettlementFormState = {
        ...settlementForm,
        [field] : e.target.value,
      };
      setSettlementForm(nextSettlementFormState);
    };

    const onFileChange = (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      console.log(file.name);
      console.log(file.size);
      console.log(file.type);
      // let imageFile = e.target.files[0];
      // if (imageFile) {
      //   const localImageUrl = URL.createObjectURL(imageFile);
      //   const imageObject = new window.Image();
  
      //   imageObject.onload = () => {
      //     imageFile.width = imageObject.naturalWidth;
      //     imageFile.height = imageObject.naturalHeight;
      //     input.onChange(imageFile);
      //     URL.revokeObjectURL(imageFile);
      //   };
      //   imageObject.src = localImageUrl;
    }

    let rowsPerPage = 10;

    return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Partners Income List</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    {
                    isLoading === 'loading' ?
                    <div className="text-center">
                        <div className="spinner-border text-danger" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="small">
                          Fetching Earning Details...
                        </p>
                    </div> :
                    partnersIncome.length > 0 ? (
                      <Table aria-labelledby="tableTitle">
                        <TableHead>
                          <TableRow>
                            <TableCell>Company Name</TableCell>
                            <TableCell>Income</TableCell>
                            <TableCell>Amount Earned</TableCell>
                            <TableCell>Amount Paid</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          { partnersIncome.length > 0 &&
                              partnersIncome.map((partnerIncome, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    margin: "0 !important",
                                    padding: "0.2 !important"
                                  }}
                                  >
                                    { partnerIncome.partner.companyName }
                                  </TableCell>
                                  <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    margin: "0 !important",
                                    padding: "0.2 !important"
                                  }}
                                  >
                                    &#8358;{ (partnerIncome.totalIncome).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                                  </TableCell>
                                  <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    margin: "0 !important",
                                    padding: "0.2 !important"
                                  }}
                                  >
                                    &#8358;{ (partnerIncome.totalEarned).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                                  </TableCell>
                                  <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    margin: "0 !important",
                                    padding: "0.2 !important"
                                  }}
                                  >
                                    &#8358;{ (partnerIncome.paidAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                                  </TableCell>
                                  <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    margin: "0 !important",
                                    padding: "0.2 !important"
                                  }}
                                  >
                                    &#8358;{ (partnerIncome.outstanding).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                                  </TableCell>
                                  <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    margin: "0 !important",
                                    padding: "0.2 !important"
                                  }}
                                  >
                                    <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {
                                      selectPartnerIncome(partnerIncome)
                                    }}
                                    >
                                      View Details
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))
                          }
                        </TableBody>
                      </Table>
                    ) :
                    null }
                  </div>
                  <div>
                    <TablePagination
                      component="div"
                      rowsPerPage={rowsPerPage}
                      count={partnersIncome.length}
                      page={page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page",
                      }}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                    role="document"
                >
                    <Dialog
                        open={openViewDetail}
                        keepMounted
                        onClose={handleCloseViewDetail}
                        fullWidth={true}
                        maxWidth="sm"
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="row d-block">
                                    <h5
                                        className="modal-title"
                                        id="exampleModalCenteredScrollableTitle"
                                    >
                                        Income Details
                                    </h5>
                                    {/* <ul
                                        className="nav nav-tabs mt-2 mb-0 pb-0"
                                        id="myTab-1"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="order-tab"
                                                data-toggle="tab"
                                                href="#order"
                                                role="tab"
                                                aria-controls="order"
                                                aria-selected="true"
                                            >
                                                Order
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="payment-tab"
                                                data-toggle="tab"
                                                href="#payment"
                                                role="tab"
                                                aria-controls="payment"
                                                aria-selected="false"
                                            >
                                                Payment 
                                            </a>
                                        </li>
                                    </ul> */}
                                </div>
                                <button
                                  type="button"
                                  className="close"
                                  aria-label="Close"
                                >
                                    <span
                                      aria-hidden="true"
                                      onClick={handleCloseViewDetail}
                                    >
                                      ×
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div
                                  className="tab-content"
                                  id="myTabContent-2"
                                >
                                    <div
                                      className="tab-pane fade show active"
                                      id="order"
                                      role="tabpanel"
                                      aria-labelledby="order-tab"
                                    >
                                        <div className="media align-items-start">
                                          {/* <img
                                            src="assets/images/user/1.jpg"
                                            className="img-fluid avatar-rounded avatar-60"
                                            alt="user"
                                          /> */}
                                          <div className="bg-primary-light avatar-rounded p-2">
                                            <svg
                                                className="svg-icon svg-primary text-primary"
                                                width="42"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M13 10V3L4 14h7v7l9-11h-7z"
                                                />
                                            </svg>
                                          </div>
                                          <div className="media-body ml-2">
                                            <div className="d-flex justify-content-between">
                                              <p className="mb-0 mt-1">
                                              {selectedPartnerIncome?.partner?.companyName }
                                              </p>
                                              <button
                                              type="button"
                                              className="btn btn-sm btn-primary"
                                              onClick={handleOpenSettlement}
                                              >
                                                Settle 
                                              </button>
                                            </div>
                                            <span className="font-weight-bold">
                                              {selectedPartnerIncome?.partner?.rcNumber}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6">
                                            <div className="media align-items-start mt-4">
                                              <div className="media-body">
                                                <p className="text mb-0">
                                                    Total Income
                                                </p>
                                                <span className="font-weight-bold d-block">
                                                  &#8358;{ (selectedPartnerIncome.totalIncome)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="media align-items-start mt-4">
                                              <div className="media-body">
                                                <p className="text mb-0">
                                                    Total Earned
                                                </p>
                                                <span className="font-weight-bold d-block">
                                                  &#8358;{ (selectedPartnerIncome.totalEarned)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="col-4">
                                            <div className="media align-items-start mt-4">
                                              <div className="media-body">
                                                <p className="text mb-0">
                                                    Amount Paid
                                                </p>
                                                <span className="font-weight-bold d-block">
                                                </span>
                                              </div>
                                            </div>
                                          </div> */}
                                        </div>
                                        <div className="row">
                                          <div className="col-6">
                                            <div className="media align-items-start mt-4">
                                              <div className="media-body">
                                                <p className="text mb-0">
                                                    Amount Paid
                                                </p>
                                                <span className="font-weight-bold d-block">
                                                    &#8358;{ (selectedPartnerIncome.paidAmount)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="media align-items-start mt-4">
                                              <div className="media-body">
                                                <p className="text mb-0">
                                                    Balance
                                                </p>
                                                <span className="font-weight-bold d-block">
                                                  &#8358;{ (selectedPartnerIncome.outstanding)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="col-4"> 
                                            <div className="media align-items-start mt-4">
                                              <div className="media-body">
                                                <p className="text mb-0">
                                                    Partner Name
                                                </p>
                                                <span className="font-weight-bold d-block">
                                                </span>
                                              </div>
                                            </div>
                                          </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <Dialog
                  open={openSettlement}
                  onClose={handleCloseSettlement}
                  fullWidth={true}
                  maxWidth="sm"
                >
                  <DialogContent>
                      <DialogContentText>
                          <div className="modalContent">
                              <div className="modal-header">
                                  <h5
                                      className="modal-title"
                                      id="exampleModalCenteredScrollableTitle"
                                  >
                                      Settlement
                                  </h5>
                                  <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                  >
                                      <span
                                        aria-hidden="true"
                                        onClick={
                                            handleCloseSettlement
                                        }
                                      >
                                          ×
                                      </span>
                                  </button>
                              </div>
                              <div className="modal-body">
                                <div
                                  className="alert alert-info"
                                  role="alert"
                                >
                                  <div className="mm-alert-text">
                                    Input Amount and Proof of Payment
                                  </div>
                                </div>
                                <form
                                    // onSubmit={
                                    //     handleSubmitRateEditForm
                                    // }
                                >
                                  <div className="form-group">
                                    <label for="colFormLabelSm">
                                      Amount
                                    </label>
                                    <input
                                        type="number"
                                        name="type"
                                        // value={
                                        //     rateEditForm.type
                                        // }
                                        onChange={
                                            handleInputChange
                                        }
                                        className="form-control form-control-sm"
                                        id="colFormLabelSm"
                                        placeholder=""
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="colFormLabelSm">
                                      Attach Proof of Payment
                                    </label>
                                    <input
                                      type="file"
                                      onChange={onFileChange}
                                      className="form-control form-control-sm "
                                      id="colFormLabelSm"
                                      placeholder="Proof of Payment"
                                    />
                                  </div>
                                  <div className="modal-footer px-0">
                                    {isLoading === 'idle' && <button
                                        type="submit"
                                        className="table-add btn btn-primary"
                                    >
                                        Save
                                    </button> }
                                    {isLoading === 'loading' && <button
                                        type="button"
                                        className="table-add btn btn-primary"
                                        disabled
                                    >
                                        Adding changes <i className="fas fa-spinner fa-spin"></i>
                                    </button> }
                                  </div>
                                </form>
                              </div>
                          </div>
                      </DialogContentText>
                  </DialogContent>
                </Dialog>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settlements;
