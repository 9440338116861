import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { addVehicleBrand, addVehicleModel, addVehicleSize, addVehicleType, deleteVehicleBrand, deleteVehicleModel, deleteVehicleSize, editVehicleBrand, editVehicleModel, getVehicleBrand, getVehicleModel, getVehicleSize, getVehicleTypes } from "../../state/actions";
import { deleteVehicle, editVehicle, editVehicleSize } from "../../state/actions";
// import { reject } from "bcrypt/promises";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const typeTemplate = [
  ["Name", "Edit", "Delete"],
  ["Corolla", "", ""],
];
const sizeTemplate = [
  ["Name", "Edit", "Delete"],
  ["Corolla", "", ""],
];
const brandTemplate = [
  ["Name", "Edit", "Delete"],
  ["Corolla", "", ""],
];
const modelTemplate = [
  ["Name", "Edit", "Delete"],
  ["Corolla", "", ""],
];

const VehicleSettings = () => {
  const dispatch = useDispatch();
  
  const fetchData = () => {
    dispatch(getVehicleTypes());
    dispatch(getVehicleSize());
    dispatch(getVehicleBrand())
    dispatch(getVehicleModel())
  }
//   console.log("types", types);

  useEffect(() => {
    fetchData()
  }, []);

  const [type, setTypes] = useState([]);
  const [size, setSizes] = useState([]);
  const [brand, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const isLoading = useSelector((state) => state.settings.status)
  const [rmVehicleTypeId, setRmVehicleTypeId] = useState('')
  const [rmVehicleBrandId, setRmVehicleBrandId] = useState('')
  const [rmVehicleSizeId, setRmVehicleSizeId] = useState('')
  const [rmVehicleModelId, setRmVehicleModelId] = useState('')
  const [editVehicleParam, setEditVehicleParam] = useState()
  const [vehicleSizeParam, setVehicleSizeParam] = useState({
    typeId:'',
    name:''
  })
  const [vehicleModelParam, setVehicleModelParam] = useState({
    brandId:'',
    name:''
  })
  const [editVehicleModelParam, setEditVehicleModelParam] = useState({});
  const [editVehicleSizeParam, setEditVehicleSizeParam] = useState({});
  const [editVehicleBrandParam, setEditVehicleBrandParam] = useState({});
 const [vehicleType, setVehicleType] = useState({
    name:''
  });
  const [vehicleBrandParam, setVehicleBrandParam] = useState({
    name:''
  });
  const types = useSelector((state) => state.settings.vehicleTypes);
  const vehicleSize = useSelector((state) => state.settings.vehicleSize);
  const vehicleBrand = useSelector((state) => state.settings.vehicleBrand);
  const vehicleModel = useSelector((state) => state.settings.vehicleModel);
  
  const AddVehicleSize = ()=> {
    dispatch(addVehicleSize(vehicleSizeParam)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleSize())
    })
  }

  const AddVehicleModel = ()=> {
    dispatch(addVehicleModel(vehicleModelParam)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleModel());
    })
  }

  const EditVehicleModel = ()=> {
    dispatch(editVehicleModel(editVehicleModelParam)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleModel());
    })
  }

  const handleVehicleSizeEdit = ()=> {
    dispatch(editVehicleSize(editVehicleSizeParam)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleSize());
    })
  }

  const handleVehicleBrandEdit = ()=> {
    dispatch(editVehicleBrand(editVehicleBrandParam)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleBrand());
    })
  }

  const AddVehicleBrand = ()=> {
    dispatch(addVehicleBrand(vehicleBrandParam)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleBrand());
    })
  }

  const handleVehicleEditType = ()=> {
    dispatch(editVehicle(editVehicleParam)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleTypes());
    })
  }

  const handleDeleteVehicleBrand = (id)=> {
    dispatch(deleteVehicleBrand(id)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleBrand());
    })
}

//   console.log(rmVehicleTypeId)
const handleDeleteVehicleType = (id)=> {
    dispatch(deleteVehicle(id)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleTypes());
    })
}

const handleDeleteVehicleModel = (id)=> {
  dispatch(deleteVehicleModel(id)).then(()=>{
    // window.location.reload()
    dispatch(getVehicleModel());
  })
}

const handleDeleteVehicleSize = (id)=> {
  dispatch(deleteVehicleSize(id)).then(()=>{
    // window.location.reload()
    dispatch(getVehicleSize());
  })
}

 
  // const [vehicleTypes, setVehicleTypes] = useState([]);
  // useCallback(() => {
  //     setVehicleTypes(types);
  // }, [types]);

  // console.log("vehicleTypes", vehicleTypes);

  const handleVehicleTypeSubmitForm = (e) => {
    e.preventDefault();
    dispatch(addVehicleType(vehicleType)).then(()=>{
      // window.location.reload()
      dispatch(getVehicleTypes());
    });
    
  };

  // const state = useSelector((state) => state);
  // console.log("state", state);
  const readTypeFromExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
      setModels((prevTypes) => [...prevTypes, ...d]);
    });
  };
  const readSizeFromExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
      setModels((prevModels) => [...prevModels, ...d]);
      console.log("models", models);
    });
  };

  const readBrandFromExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
      setModels((prevModels) => [...prevModels, ...d]);
      console.log("models", models);
    });
  };
  const readModelFromExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setModels((prevModels) => [...prevModels, ...d]);
      console.log("models", models);
    });
  };

  return (
    <>
      <NavBar />
      <SideBar />
      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-12 ">
              <div className="card">
                <div className="card-header justify-content-between">
                  <div className="header-title">
                    <h5 className="card-title">Vehicle Settings</h5>
                  </div>
                </div>
                <div className="card-body">
                  <ul
                    className="nav nav-tabs mt-2 mb-3 pb-0"
                    id="myTab-1"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#form1"
                        role="tab"
                        aria-controls="order"
                        aria-selected="true"
                      >
                        Type
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#form2"
                        role="tab"
                        aria-controls="recipient"
                        aria-selected="false"
                      >
                        Size
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#form3"
                        role="tab"
                        aria-controls="recipient"
                        aria-selected="false"
                      >
                        Brand
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#form4"
                        role="tab"
                        aria-controls="recipient"
                        aria-selected="false"
                      >
                        Model
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent-2">
                    <div
                      className="tab-pane fade show active"
                      id="form1"
                      role="tabpanel"
                      aria-labelledby="incity-tab"
                    >
                      <div className="form-row d-block">
                        <div className="card-body">
                          <div id="table" className="table-editable">
                            <span className="float-right mb-3 mr-2">
                              <button
                                type="button"
                                className="btn btn-primary mt-2"
                                data-toggle="modal"
                                data-target="#newCategory1"
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Add New</span>
                                </i>
                              </button>
                            </span>
                            <span className="float-right mb-3 mr-2">
                              <button
                                type="button"
                                className="btn btn-primary mt-2"
                                data-toggle="modal"
                                data-target="#importType"
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Import</span>
                                </i>
                              </button>
                            </span>
                            <table className="table table-bordered table-responsive-md table-striped text-center">
                              <thead>
                                <tr>
                                  <th>Type Name</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {types.length > 0 &&
                                  types.map((t) => {
                                    
                                    return (
                                      <tr>
                                        <td contenteditable="true">{t.name}</td>

                                        <td>
                                          <span className="table-up">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#modelEdit"
                                              onClick={() => setEditVehicleParam(t)}
                                              className="btn bg-primary-light btn-rounded btn-sm my-0"
                                            >
                                              Edit
                                            </button>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="table-remove">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#modelDelete"
                                              className="btn bg-danger-light btn-rounded btn-sm my-0"
                                              onClick={()=>setRmVehicleTypeId(t.id)}
                                            >
                                              Remove
                                            </button>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="form2"
                      role="tabpanel"
                      aria-labelledby="incity-tab"
                    >
                      <div className="form-row d-block">
                        <div className="card-body">
                          <div id="table" className="table-editable">
                            <span className="float-right mb-3 mr-2">
                              <button
                                type="button"
                                className="btn btn-primary mt-2"
                                data-toggle="modal"
                                data-target="#newCategory2"
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Add New Size</span>
                                </i>
                              </button>
                            </span>
                            <span className="float-right mb-3 mr-2">
                              <button
                                type="button"
                                className="btn btn-primary mt-2"
                                data-toggle="modal"
                                data-target="#importSize"
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Import</span>
                                </i>
                              </button>
                            </span>
                            <table className="table table-bordered table-responsive-md table-striped text-center">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehicleSize &&
                                  vehicleSize.map((item) => {
                                    return (
                                      <tr>
                                        <td contenteditable="true">
                                          {item.name}
                                        </td>

                                        <td>
                                          <span className="table-up">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#modelEditvehicleSize"
                                              className="btn bg-primary-light btn-rounded btn-sm my-0"
                                              onClick={()=> setEditVehicleSizeParam(item)}
                                            >
                                              Edit
                                            </button>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="table-remove">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#modelDeleteVehicleSize"
                                              className="btn bg-danger-light btn-rounded btn-sm my-0"
                                              onClick={()=>setRmVehicleSizeId(item?.id)}
                                            >
                                              Remove
                                            </button>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="form3"
                      role="tabpanel"
                      aria-labelledby="incity-tab"
                    >
                      <div className="form-row d-block">
                        <div className="card-body">
                          <div id="table" className="table-editable">
                            <span className="float-right mb-3 mr-2">
                              <button
                                type="button"
                                className="btn btn-primary mt-2"
                                data-toggle="modal"
                                data-target="#newCategory3"
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Add New Brand</span>
                                </i>
                              </button>
                            </span>
                            <span className="float-right mb-3 mr-2">
                              <button
                                type="button"
                                className="btn btn-primary mt-2"
                                data-toggle="modal"
                                data-target="#importBrand"
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Import</span>
                                </i>
                              </button>
                            </span>
                            <table className="table table-bordered table-responsive-md table-striped text-center">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehicleBrand &&
                                  vehicleBrand.map((model) => {
                                    return (
                                      <tr>
                                        <td contenteditable="true">
                                          {model.name}
                                        </td>

                                        <td>
                                          <span className="table-up">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#modelEditvehicleBrand"
                                              className="btn bg-primary-light btn-rounded btn-sm my-0"
                                              onClick={()=> setEditVehicleBrandParam(model)}
                                            >
                                              Edit
                                            </button>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="table-remove">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#modelDeleteVehicleBrand"
                                              className="btn bg-danger-light btn-rounded btn-sm my-0"
                                              onClick={()=>setRmVehicleBrandId(model.id)}
                                            >
                                              Remove
                                            </button>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade active"
                      id="form4"
                      role="tabpanel"
                      aria-labelledby="incity-tab"
                    >
                      <div className="form-row d-block">
                        <div className="card-body">
                          <div id="table" className="table-editable">
                            <span className="float-right mb-3 mr-2">
                              <button
                                type="button"
                                className="btn btn-primary mt-2"
                                data-toggle="modal"
                                data-target="#newCategory4"
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Add New</span>
                                </i>
                              </button>
                            </span>
                            <span className="float-right mb-3 mr-2">
                              <button
                                type="button"
                                className="btn btn-primary mt-2"
                                data-toggle="modal"
                                data-target="#importModel"
                              >
                                <i className="ri-add-fill">
                                  <span className="pl-1">Import</span>
                                </i>
                              </button>
                            </span>
                            <table className="table table-bordered table-responsive-md table-striped text-center">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>

                              <tbody>
                                {vehicleModel &&
                                  vehicleModel.map((model) => {
                                    return (
                                      <tr>
                                        <td contenteditable="true">
                                          {model.name}
                                        </td>

                                        <td>
                                          <span className="table-up">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#modelEditVehicleModel"
                                              className="btn bg-primary-light btn-rounded btn-sm my-0"
                                              onClick={()=> setEditVehicleModelParam(model)}
                                            >
                                              Edit
                                            </button>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="table-remove">
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#modelDeleteVehicleModel"
                                              className="btn bg-danger-light btn-rounded btn-sm my-0"
                                              onClick={()=>setRmVehicleModelId(model?.id)}
                                            >
                                              Remove
                                            </button>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ToastContainer />
                  
                  {/* Add Vehicle Type */}
                  <div
                    className="modal fade"
                    id="newCategory1"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Add New Vehicle Type
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form onSubmit={handleVehicleTypeSubmitForm}>
                            <div className="form-group">
                              <label for="colFormLabelSm">Type</label>
                              <input
                                type="text"
                                
                                onChange={(e) => setVehicleType({name:e.target.value})}
                                className="form-control"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                            <div className="modal-footer px-0">
                              {isLoading === 'idle' &&
                                <button type="submit" className="btn btn-primary">
                                Submit
                              </button>}
                              {isLoading === 'loading' &&
                                <button type="submit" className="btn btn-primary" disabled>
                                  <i className="fas fa-spinner fa-spin"></i>
                                </button>
                              }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Add Vehicle Size */}
                  <div
                    className="modal fade"
                    id="newCategory2"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Add New Size
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label for="colFormLabelSm">Type</label>
                              <select className="form-control"
                              onChange={(e)=>setVehicleSizeParam({
                                ...vehicleSizeParam,
                                typeId:e.target.value,
                              })}
                              >
                              {
                                types?.map((vehicle_types)=>{
                                  return(
                              
                                <option name='typeId' value={vehicle_types?.id}>{vehicle_types?.name}</option>
                                
                              
                                  )
                                })
                              }
                              </select>
                            </div>
                            <div className="form-group">
                              <label for="colFormLabelSm">Size</label>
                              <input
                                type="text"
                                className="form-control form-control"
                                id="colFormLabelSm"
                                placeholder=""
                                onChange={(e)=>setVehicleSizeParam({
                                  ...vehicleSizeParam,
                                  name:e.target.value,
                                })}
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          {/* <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            data-dismiss="modal"
                                                        >
                                                            Close
                                                        </button> */}
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=> AddVehicleSize()}
                          >
                            Submit
                          </button>}
                          {isLoading === 'loading' &&
                            <button type="submit" className="btn btn-primary" disabled>
                            <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Add Vehicle Brand */}
                  <div
                    className="modal fade"
                    id="newCategory3"
                    // tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Add New Brand
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label for="colFormLabelSm">Brand</label>
                              <input
                                type="text"
                                className="form-control"
                                id="colFormLabelSm"
                                placeholder=""
                                onChange={(e) => setVehicleBrandParam({name:e.target.value})}
                              />
                            </div>
                            <div className="modal-footer">
                              {isLoading === 'idle' &&
                                <button
                                type="button"
                                className="table-add btn btn-primary"
                                onClick={()=> AddVehicleBrand()}
                                >
                                Submit
                                </button>
                              }
                              {
                              isLoading === 'loading' &&
                                <button type="submit" className="btn btn-primary" disabled>
                                  <i className="fas fa-spinner fa-spin"></i>
                                </button>
                              }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Add Vehicle Model */}
                  <div
                    className="modal fade"
                    id="newCategory4"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Add New Model
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label for="colFormLabelSm">Brand</label>
                              <select className="form-control"
                              onChange={(e)=>setVehicleModelParam({
                                ...vehicleModelParam,
                                brandId:e.target.value,
                              })}
                              >
                              {
                                  vehicleBrand?.map((item)=> {
                                    return(
                                        <option value={item.id}>{item.name}</option>
                                    )
                                  })
                              }
                            
                              </select>
                            </div>
                            <div className="form-group">
                              <label for="colFormLabel">Model</label>
                              <input
                                type="text"
                                className="form-control"
                                id="colFormLabelSm"
                                placeholder=""
                                onChange={(e)=>setVehicleModelParam({
                                  ...vehicleModelParam,
                                  name:e.target.value,
                                })}
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          {
                          isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=> AddVehicleModel()}
                            >
                              Save
                            </button>
                          }
                          {isLoading === 'loading' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                          {/* <ToastContainer /> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* EDIT */}
                  {/* Edit Vehicle Size */}
                  <div
                    className="modal fade"
                    id="modelEditvehicleSize"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                    >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Edit Vehicle Size
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group mb-0">
                              <label for="colFormLabelLg" className="pb-0">
                                Type
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="editVehicleTypeInput"
                                placeholder=""
                                onChange={(e)=>setEditVehicleSizeParam({
                                    ...editVehicleSizeParam,
                                    name:e.target.value
                                })}
                                defaultValue={editVehicleSizeParam?.name}
                                
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=>handleVehicleSizeEdit()}
                            >
                              Save
                            </button>
                          }
                          {isLoading === 'loading' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Edit Vehicle Brand */}
                  <div
                    className="modal fade"
                    id="modelEditvehicleBrand"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                    >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Edit Vehicle Brand
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group mb-0">
                              <label for="colFormLabelLg" className="pb-0">
                                Type
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="editVehicleTypeInput"
                                placeholder=""
                                onChange={(e)=>setEditVehicleBrandParam({
                                    ...editVehicleBrandParam,
                                    name:e.target.value
                                })}
                                defaultValue={editVehicleBrandParam?.name}
                                
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=>handleVehicleBrandEdit()}
                            >
                            Save
                            </button>
                          }
                          {isLoading === 'loading' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Edit Vehicle Model */}
                  <div
                    className="modal fade"
                    id="modelEditVehicleModel"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                    >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Edit Vehicle Model
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group mb-0">
                              <label for="colFormLabelLg" className="pb-0">
                                Type
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="editVehicleTypeInput"
                                placeholder=""
                                onChange={(e)=>setEditVehicleModelParam({
                                    ...editVehicleModelParam,
                                    name:e.target.value
                                })}
                                defaultValue={editVehicleModelParam?.name}
                                
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=>EditVehicleModel()}
                            >
                            Save
                            </button>
                          }
                          {isLoading === 'loading' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                            <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  { /* Edit Vehicle Type */}
                  <div
                    className="modal fade"
                    id="modelEdit"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                    >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Edit Vehicle Type
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group mb-0">
                              <label for="colFormLabelLg" className="pb-0">
                                Type
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="editVehicleTypeInput"
                                placeholder=""
                                onChange={(e)=>setEditVehicleParam({
                                    ...editVehicleParam,
                                    name:e.target.value
                                })}
                                defaultValue={editVehicleParam?.name}
                                
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=>handleVehicleEditType()}
                            >
                              Save
                            </button>
                          }
                          {isLoading === 'loading' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* EDIT */}

                  {/* DELETE */}
                  {/* Vehicle Size Delete */}
                  <div
                    className="modal fade"
                    id="modelDeleteVehicleSize"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Delete Model
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=>handleDeleteVehicleSize(rmVehicleSizeId)}
                          >
                            Delete
                          </button>
                          }
                          {
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Vehicle Brand Delete */}
                  <div
                    className="modal fade"
                    id="modelDeleteVehicleBrand"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Delete Model
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=>handleDeleteVehicleBrand(rmVehicleBrandId)}
                          >
                            Delete
                          </button>
                          }
                          {isLoading === 'loading' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Vehicle Model Delete */}
                  <div
                    className="modal fade"
                    id="modelDeleteVehicleModel"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Delete Model
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=>handleDeleteVehicleModel(rmVehicleModelId)}
                            >
                              Delete
                            </button>
                          }
                          {isLoading === 'loading' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Vehicle Type Delete */}
                  <div
                    className="modal fade"
                    id="modelDelete"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Delete Model
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          {isLoading === 'idle' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            onClick={()=>handleDeleteVehicleType(rmVehicleTypeId)}
                            >
                              Delete
                            </button>
                          }
                          {isLoading === 'loading' &&
                            <button
                            type="button"
                            className="table-add btn btn-primary"
                            disabled
                            >
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <ToastContainer /> */}

                  {/* DELETE */}

                  {/* IMPORT */}
                  {/* 
                                    <div
                                        className="modal fade"
                                        id="newCategory1"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Add New Type
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="colFormLabelSm">
                                                                Type Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="colFormLabel">
                                                                Base Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label
                                                                for="colFormLabelLg"
                                                                className="pb-0"
                                                            >
                                                                Booking Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="newCategory2"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Add New Size
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="colFormLabelSm">
                                                                Category Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="colFormLabel">
                                                                Base Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label
                                                                for="colFormLabelLg"
                                                                className="pb-0"
                                                            >
                                                                Booking Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                  {/* import */}
                  <div
                    className="modal fade"
                    id="importType"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Import Type
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <p>
                              You are expected to maintain the format in the
                              template, all number or code column in the first
                              sheet have their corresponding value in the next
                              sheet. Kindly confirm all data before upload to
                              avoid mistake. Any incorrect data in the
                              spreadsheet might lead to upload error.
                            </p>
                            {/* <div className="form-group">
                                                                <label for="colFormLabelSm">
                                                                    Category
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="colFormLabel">
                                                                    Base Fare
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div> */}
                          </form>
                        </div>

                        <div className="modal-footer ">
                          <button
                            type="button"
                            className="table-add btn btn-outline-primary mx-auto col-10"
                          >
                            <CSVLink
                              style={{
                                textDecoration: "none !important",
                              }}
                              data={typeTemplate}
                            >
                              Download Template
                            </CSVLink>
                          </button>
                        </div>
                        <div className="modal-footer">
                          <div className="form-group mx-auto col-10">
                            <input
                              type="file"
                              onChange={(e) => {
                                readTypeFromExcel(e.target.files[0]);
                              }}
                              className="form-control form-control-lg px-5"
                              id="colFormLabelMd"
                              placeholder="Upload Create Type Settings Excel file"
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="table-add btn btn-primary mx-auto col-10"
                          >
                            Submit Your Type
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="importSize"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Import Size
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <p>
                              You are expected to maintain the format in the
                              template, all number or code column in the first
                              sheet have their corresponding value in the next
                              sheet. Kindly confirm all data before upload to
                              avoid mistake. Any incorrect data in the
                              spreadsheet might lead to upload error.
                            </p>
                            {/* <div className="form-group">
                                                                <label for="colFormLabelSm">
                                                                    Category
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="colFormLabel">
                                                                    Base Fare
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div> */}
                          </form>
                        </div>

                        <div className="modal-footer ">
                          <button
                            type="button"
                            className="table-add btn btn-outline-primary mx-auto col-10"
                          >
                            <CSVLink
                              style={{
                                textDecoration: "none !important",
                              }}
                              data={sizeTemplate}
                            >
                              Download Template
                            </CSVLink>
                          </button>
                        </div>
                        <div className="modal-footer">
                          <div className="form-group mx-auto col-10">
                            <input
                              type="file"
                              onChange={(e) => {
                                readSizeFromExcel(e.target.files[0]);
                              }}
                              className="form-control form-control-lg px-5"
                              id="colFormLabelMd"
                              placeholder="Upload Create Size Settings Excel file"
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="table-add btn btn-primary mx-auto col-10"
                          >
                            Submit Your Size
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="importBrand"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Import Brand
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <p>
                              You are expected to maintain the format in the
                              template, all number or code column in the first
                              sheet have their corresponding value in the next
                              sheet. Kindly confirm all data before upload to
                              avoid mistake. Any incorrect data in the
                              spreadsheet might lead to upload error.
                            </p>
                            {/* <div className="form-group">
                                                                <label for="colFormLabelSm">
                                                                    Category
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="colFormLabel">
                                                                    Base Fare
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div> */}
                          </form>
                        </div>

                        <div className="modal-footer ">
                          <button
                            type="button"
                            className="table-add btn btn-outline-primary mx-auto col-10"
                          >
                            <CSVLink
                              style={{
                                textDecoration: "none !important",
                              }}
                              data={sizeTemplate}
                            >
                              Download Template
                            </CSVLink>
                          </button>
                        </div>
                        <div className="modal-footer">
                          <div className="form-group mx-auto col-10">
                            <input
                              type="file"
                              onChange={(e) => {
                                readBrandFromExcel(e.target.files[0]);
                              }}
                              className="form-control form-control-lg px-5"
                              id="colFormLabelMd"
                              placeholder="Upload Create Brand Settings Excel file"
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="table-add btn btn-primary mx-auto col-10"
                          >
                            Submit Your Brand
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="importModel"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                          >
                            Import Model
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <p>
                              You are expected to maintain the format in the
                              template, all number or code column in the first
                              sheet have their corresponding value in the next
                              sheet. Kindly confirm all data before upload to
                              avoid mistake. Any incorrect data in the
                              spreadsheet might lead to upload error.
                            </p>
                            {/* <div className="form-group">
                                                                <label for="colFormLabelSm">
                                                                    Category
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="colFormLabel">
                                                                    Base Fare
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div> */}
                          </form>
                        </div>

                        <div className="modal-footer ">
                          <button
                            type="button"
                            className="table-add btn btn-outline-primary mx-auto col-10"
                          >
                            <CSVLink
                              style={{
                                textDecoration: "none !important",
                              }}
                              data={modelTemplate}
                            >
                              Download Template
                            </CSVLink>
                          </button>
                        </div>
                        <div className="modal-footer">
                          <div className="form-group mx-auto col-10">
                            <input
                              type="file"
                              onChange={(e) => {
                                readModelFromExcel(e.target.files[0]);
                              }}
                              className="form-control form-control-lg px-5"
                              id="colFormLabelMd"
                              placeholder="Upload Create Model Settings Excel file"
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="table-add btn btn-primary mx-auto col-10"
                          >
                            Submit Your Model
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* END OF IMPORT */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleSettings;
