import React, { useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import NewModal from "../../components/UI/Modal";
import AddVehicleTypeModal from "./AddVehicleType";
// import { reject } from "bcrypt/promises";

const typeTemplate = [
    ["Name", "Edit", "Delete"],
    ["Corolla", "", ""],
];
const sizeTemplate = [
    ["Name", "Edit", "Delete"],
    ["Corolla", "", ""],
];
const brandTemplate = [
    ["Name", "Edit", "Delete"],
    ["Corolla", "", ""],
];
const modelTemplate = [
    ["Name", "Edit", "Delete"],
    ["Corolla", "", ""],
];

const VehicleSettings = () => {
    const [type, setTypes] = useState([]);
    const [size, setSizes] = useState([]);
    const [brand, setBrands] = useState([]);
    const [models, setModels] = useState([]);

    const [showAddVehicleTypeModal, setShowAddVehicleTypeModal] =
        useState(false);

    const handleCloseVehicleType = () => {
        setShowAddVehicleTypeModal(false);
    };
    const [vehicleType, setVehicleType] = useState("");

    const handleVehicleTypeSubmitForm = (e) => {
        // e.preventDefault();
        e.preventDefault();
        console.log("vehicleType");
    };

    // const state = useSelector((state) => state);
    // console.log("state", state);
    const readTypeFromExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            console.log(d);
            setModels((prevTypes) => [...prevTypes, ...d]);
        });
    };
    const readSizeFromExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            console.log(d);
            setModels((prevModels) => [...prevModels, ...d]);
            console.log("models", models);
        });
    };

    const readBrandFromExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            console.log(d);
            setModels((prevModels) => [...prevModels, ...d]);
            console.log("models", models);
        });
    };
    const readModelFromExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            console.log(d);
            setModels((prevModels) => [...prevModels, ...d]);
            console.log("models", models);
        });
    };

    return (
        <>
            <NavBar />
            <SideBar />
            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12 ">
                            <div className="card">
                                <div className="card-header justify-content-between">
                                    <div className="header-title">
                                        <h5 className="card-title">
                                            Vehicle Settings
                                        </h5>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <ul
                                        className="nav nav-tabs mt-2 mb-3 pb-0"
                                        id="myTab-1"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="home-tab"
                                                data-toggle="tab"
                                                href="#form1"
                                                role="tab"
                                                aria-controls="order"
                                                aria-selected="true"
                                            >
                                                Type
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="profile-tab"
                                                data-toggle="tab"
                                                href="#form2"
                                                role="tab"
                                                aria-controls="recipient"
                                                aria-selected="false"
                                            >
                                                Size
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="profile-tab"
                                                data-toggle="tab"
                                                href="#form3"
                                                role="tab"
                                                aria-controls="recipient"
                                                aria-selected="false"
                                            >
                                                Brand
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="profile-tab"
                                                data-toggle="tab"
                                                href="#form4"
                                                role="tab"
                                                aria-controls="recipient"
                                                aria-selected="false"
                                            >
                                                Model
                                            </a>
                                        </li>
                                    </ul>
                                    <div
                                        className="tab-content"
                                        id="myTabContent-2"
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="form1"
                                            role="tabpanel"
                                            aria-labelledby="incity-tab"
                                        >
                                            <div className="form-row d-block">
                                                <div className="card-body">
                                                    <div
                                                        id="table"
                                                        className="table-editable"
                                                    >
                                                        <span className="float-right mb-3 mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-2"
                                                                // data-toggle="modal"
                                                                // data-target="#newCategory1"
                                                                onClick={() =>
                                                                    setShowAddVehicleTypeModal(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <i className="ri-add-fill">
                                                                    <span className="pl-1">
                                                                        Add New
                                                                    </span>
                                                                </i>
                                                            </button>
                                                        </span>
                                                        <span className="float-right mb-3 mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-2"
                                                                data-toggle="modal"
                                                                data-target="#importType"
                                                            >
                                                                <i className="ri-add-fill">
                                                                    <span className="pl-1">
                                                                        Import
                                                                    </span>
                                                                </i>
                                                            </button>
                                                        </span>
                                                        <table className="table table-bordered table-responsive-md table-striped text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Name
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {models &&
                                                                    models.map(
                                                                        (
                                                                            model
                                                                        ) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td contenteditable="true">
                                                                                        {
                                                                                            model.Name
                                                                                        }
                                                                                    </td>

                                                                                    <td>
                                                                                        <span className="table-up">
                                                                                            <button
                                                                                                type="button"
                                                                                                data-toggle="modal"
                                                                                                data-target="#modelEdit"
                                                                                                className="btn bg-primary-light btn-rounded btn-sm my-0"
                                                                                            >
                                                                                                Edit
                                                                                            </button>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="table-remove">
                                                                                            <button
                                                                                                type="button"
                                                                                                data-toggle="modal"
                                                                                                data-target="#modelDelete"
                                                                                                className="btn bg-danger-light btn-rounded btn-sm my-0"
                                                                                            >
                                                                                                Remove
                                                                                            </button>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="form2"
                                            role="tabpanel"
                                            aria-labelledby="incity-tab"
                                        >
                                            <div className="form-row d-block">
                                                <div className="card-body">
                                                    <div
                                                        id="table"
                                                        className="table-editable"
                                                    >
                                                        <span className="float-right mb-3 mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-2"
                                                                data-toggle="modal"
                                                                data-target="#newCategory2"
                                                            >
                                                                <i className="ri-add-fill">
                                                                    <span className="pl-1">
                                                                        Add New
                                                                    </span>
                                                                </i>
                                                            </button>
                                                        </span>
                                                        <span className="float-right mb-3 mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-2"
                                                                data-toggle="modal"
                                                                data-target="#importSize"
                                                            >
                                                                <i className="ri-add-fill">
                                                                    <span className="pl-1">
                                                                        Import
                                                                    </span>
                                                                </i>
                                                            </button>
                                                        </span>
                                                        <table className="table table-bordered table-responsive-md table-striped text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Name
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {models &&
                                                                    models.map(
                                                                        (
                                                                            model
                                                                        ) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td contenteditable="true">
                                                                                        {
                                                                                            model.Name
                                                                                        }
                                                                                    </td>

                                                                                    <td>
                                                                                        <span className="table-up">
                                                                                            <button
                                                                                                type="button"
                                                                                                data-toggle="modal"
                                                                                                data-target="#modelEdit"
                                                                                                className="btn bg-primary-light btn-rounded btn-sm my-0"
                                                                                            >
                                                                                                Edit
                                                                                            </button>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="table-remove">
                                                                                            <button
                                                                                                type="button"
                                                                                                data-toggle="modal"
                                                                                                data-target="#modelDelete"
                                                                                                className="btn bg-danger-light btn-rounded btn-sm my-0"
                                                                                            >
                                                                                                Remove
                                                                                            </button>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="form3"
                                            role="tabpanel"
                                            aria-labelledby="incity-tab"
                                        >
                                            <div className="form-row d-block">
                                                <div className="card-body">
                                                    <div
                                                        id="table"
                                                        className="table-editable"
                                                    >
                                                        <span className="float-right mb-3 mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-2"
                                                                data-toggle="modal"
                                                                data-target="#newCategory3"
                                                            >
                                                                <i className="ri-add-fill">
                                                                    <span className="pl-1">
                                                                        Add New
                                                                    </span>
                                                                </i>
                                                            </button>
                                                        </span>
                                                        <span className="float-right mb-3 mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-2"
                                                                data-toggle="modal"
                                                                data-target="#importBrand"
                                                            >
                                                                <i className="ri-add-fill">
                                                                    <span className="pl-1">
                                                                        Import
                                                                    </span>
                                                                </i>
                                                            </button>
                                                        </span>
                                                        <table className="table table-bordered table-responsive-md table-striped text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Name
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {models &&
                                                                    models.map(
                                                                        (
                                                                            model
                                                                        ) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td contenteditable="true">
                                                                                        {
                                                                                            model.Name
                                                                                        }
                                                                                    </td>

                                                                                    <td>
                                                                                        <span className="table-up">
                                                                                            <button
                                                                                                type="button"
                                                                                                data-toggle="modal"
                                                                                                data-target="#modelEdit"
                                                                                                className="btn bg-primary-light btn-rounded btn-sm my-0"
                                                                                            >
                                                                                                Edit
                                                                                            </button>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="table-remove">
                                                                                            <button
                                                                                                type="button"
                                                                                                data-toggle="modal"
                                                                                                data-target="#modelDelete"
                                                                                                className="btn bg-danger-light btn-rounded btn-sm my-0"
                                                                                            >
                                                                                                Remove
                                                                                            </button>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade active"
                                            id="form4"
                                            role="tabpanel"
                                            aria-labelledby="incity-tab"
                                        >
                                            <div className="form-row d-block">
                                                <div className="card-body">
                                                    <div
                                                        id="table"
                                                        className="table-editable"
                                                    >
                                                        <span className="float-right mb-3 mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-2"
                                                                data-toggle="modal"
                                                                data-target="#newCategory4"
                                                            >
                                                                <i className="ri-add-fill">
                                                                    <span className="pl-1">
                                                                        Add New
                                                                    </span>
                                                                </i>
                                                            </button>
                                                        </span>
                                                        <span className="float-right mb-3 mr-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-2"
                                                                data-toggle="modal"
                                                                data-target="#importModel"
                                                            >
                                                                <i className="ri-add-fill">
                                                                    <span className="pl-1">
                                                                        Import
                                                                    </span>
                                                                </i>
                                                            </button>
                                                        </span>
                                                        <table className="table table-bordered table-responsive-md table-striped text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Name
                                                                    </th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {models &&
                                                                    models.map(
                                                                        (
                                                                            model
                                                                        ) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td contenteditable="true">
                                                                                        {
                                                                                            model.Name
                                                                                        }
                                                                                    </td>

                                                                                    <td>
                                                                                        <span className="table-up">
                                                                                            <button
                                                                                                type="button"
                                                                                                data-toggle="modal"
                                                                                                data-target="#modelEdit"
                                                                                                className="btn bg-primary-light btn-rounded btn-sm my-0"
                                                                                            >
                                                                                                Edit
                                                                                            </button>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="table-remove">
                                                                                            <button
                                                                                                type="button"
                                                                                                data-toggle="modal"
                                                                                                data-target="#modelDelete"
                                                                                                className="btn bg-danger-light btn-rounded btn-sm my-0"
                                                                                            >
                                                                                                Remove
                                                                                            </button>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ADD */}

                                    <AddVehicleTypeModal
                                        show={showAddVehicleTypeModal}
                                        handleClose={() =>
                                            setShowAddVehicleTypeModal(false)
                                        }
                                        onSubmit={handleCloseVehicleType}
                                        modalTitle={"Add New Type"}
                                        // categoryName={categoryName}
                                        // setCategoryName={setCategoryName}
                                        // parentCategoryId={parentCategoryId}
                                        // setParentCategoryId={
                                        //     setParentCategoryId
                                        // }
                                        // categoryList={categoryList}
                                        // handleCategoryImage={
                                        //     handleCategoryImage
                                        // }
                                    />

                                    {/* END OF ADD */}

                                    {/* EDIT */}
                                    <div
                                        className="modal fade"
                                        id="typeEdit"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Edit Type
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="colFormLabelSm">
                                                                Category Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="colFormLabel">
                                                                Base Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label
                                                                for="colFormLabelLg"
                                                                className="pb-0"
                                                            >
                                                                Booking Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="sizeEdit"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Edit Size
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="colFormLabelSm">
                                                                Category Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="colFormLabel">
                                                                Base Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label
                                                                for="colFormLabelLg"
                                                                className="pb-0"
                                                            >
                                                                Booking Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="brandEdit"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Edit Brand
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="colFormLabelSm">
                                                                Brand Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="colFormLabel">
                                                                Base Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label
                                                                for="colFormLabelLg"
                                                                className="pb-0"
                                                            >
                                                                Booking Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="modelEdit"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Edit Model
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="colFormLabelSm">
                                                                Model Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="colFormLabel">
                                                                Base Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label
                                                                for="colFormLabelLg"
                                                                className="pb-0"
                                                            >
                                                                Booking Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* EDIT */}

                                    {/* DELETE */}

                                    <div
                                        className="modal fade"
                                        id="typeDelete"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Delete Type
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>

                                                <div className="modal-footer d-flex justify-content-around">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="sizeDelete"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Delete Size
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>

                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="brandDelete"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Delete Brand
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>

                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="modelDelete"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Delete Model
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>

                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* DELETE */}

                                    {/* IMPORT */}
                                    {/* 
                                    <div
                                        className="modal fade"
                                        id="newCategory1"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Add New Type
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="colFormLabelSm">
                                                                Type Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="colFormLabel">
                                                                Base Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label
                                                                for="colFormLabelLg"
                                                                className="pb-0"
                                                            >
                                                                Booking Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="newCategory2"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Add New Size
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <div className="form-group">
                                                            <label for="colFormLabelSm">
                                                                Category Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="colFormLabel">
                                                                Base Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                            <label
                                                                for="colFormLabelLg"
                                                                className="pb-0"
                                                            >
                                                                Booking Fare
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="colFormLabelSm"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* import */}
                                    <div
                                        className="modal fade"
                                        id="importType"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Import Type
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <p>
                                                            You are expected to
                                                            maintain the format
                                                            in the template, all
                                                            number or code
                                                            column in the first
                                                            sheet have their
                                                            corresponding value
                                                            in the next sheet.
                                                            Kindly confirm all
                                                            data before upload
                                                            to avoid mistake.
                                                            Any incorrect data
                                                            in the spreadsheet
                                                            might lead to upload
                                                            error.
                                                        </p>
                                                        {/* <div className="form-group">
                                                                <label for="colFormLabelSm">
                                                                    Category
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="colFormLabel">
                                                                    Base Fare
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div> */}
                                                    </form>
                                                </div>

                                                <div className="modal-footer ">
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-outline-primary mx-auto col-10"
                                                    >
                                                        <CSVLink
                                                            style={{
                                                                textDecoration:
                                                                    "none !important",
                                                            }}
                                                            data={typeTemplate}
                                                        >
                                                            Download Template
                                                        </CSVLink>
                                                    </button>
                                                </div>
                                                <div className="modal-footer">
                                                    <div className="form-group mx-auto col-10">
                                                        <input
                                                            type="file"
                                                            onChange={(e) => {
                                                                readTypeFromExcel(
                                                                    e.target
                                                                        .files[0]
                                                                );
                                                            }}
                                                            className="form-control form-control-lg px-5"
                                                            id="colFormLabelMd"
                                                            placeholder="Upload Create Type Settings Excel file"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary mx-auto col-10"
                                                    >
                                                        Submit Your Type
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="importSize"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Import Size
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <p>
                                                            You are expected to
                                                            maintain the format
                                                            in the template, all
                                                            number or code
                                                            column in the first
                                                            sheet have their
                                                            corresponding value
                                                            in the next sheet.
                                                            Kindly confirm all
                                                            data before upload
                                                            to avoid mistake.
                                                            Any incorrect data
                                                            in the spreadsheet
                                                            might lead to upload
                                                            error.
                                                        </p>
                                                        {/* <div className="form-group">
                                                                <label for="colFormLabelSm">
                                                                    Category
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="colFormLabel">
                                                                    Base Fare
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div> */}
                                                    </form>
                                                </div>

                                                <div className="modal-footer ">
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-outline-primary mx-auto col-10"
                                                    >
                                                        <CSVLink
                                                            style={{
                                                                textDecoration:
                                                                    "none !important",
                                                            }}
                                                            data={sizeTemplate}
                                                        >
                                                            Download Template
                                                        </CSVLink>
                                                    </button>
                                                </div>
                                                <div className="modal-footer">
                                                    <div className="form-group mx-auto col-10">
                                                        <input
                                                            type="file"
                                                            onChange={(e) => {
                                                                readSizeFromExcel(
                                                                    e.target
                                                                        .files[0]
                                                                );
                                                            }}
                                                            className="form-control form-control-lg px-5"
                                                            id="colFormLabelMd"
                                                            placeholder="Upload Create Size Settings Excel file"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary mx-auto col-10"
                                                    >
                                                        Submit Your Size
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="importBrand"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Import Brand
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <p>
                                                            You are expected to
                                                            maintain the format
                                                            in the template, all
                                                            number or code
                                                            column in the first
                                                            sheet have their
                                                            corresponding value
                                                            in the next sheet.
                                                            Kindly confirm all
                                                            data before upload
                                                            to avoid mistake.
                                                            Any incorrect data
                                                            in the spreadsheet
                                                            might lead to upload
                                                            error.
                                                        </p>
                                                        {/* <div className="form-group">
                                                                <label for="colFormLabelSm">
                                                                    Category
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="colFormLabel">
                                                                    Base Fare
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div> */}
                                                    </form>
                                                </div>

                                                <div className="modal-footer ">
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-outline-primary mx-auto col-10"
                                                    >
                                                        <CSVLink
                                                            style={{
                                                                textDecoration:
                                                                    "none !important",
                                                            }}
                                                            data={sizeTemplate}
                                                        >
                                                            Download Template
                                                        </CSVLink>
                                                    </button>
                                                </div>
                                                <div className="modal-footer">
                                                    <div className="form-group mx-auto col-10">
                                                        <input
                                                            type="file"
                                                            onChange={(e) => {
                                                                readBrandFromExcel(
                                                                    e.target
                                                                        .files[0]
                                                                );
                                                            }}
                                                            className="form-control form-control-lg px-5"
                                                            id="colFormLabelMd"
                                                            placeholder="Upload Create Brand Settings Excel file"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary mx-auto col-10"
                                                    >
                                                        Submit Your Brand
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="importModel"
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalScrollableTitle"
                                        aria-hidden="true"
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalScrollableTitle"
                                                    >
                                                        Import Model
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form>
                                                        <p>
                                                            You are expected to
                                                            maintain the format
                                                            in the template, all
                                                            number or code
                                                            column in the first
                                                            sheet have their
                                                            corresponding value
                                                            in the next sheet.
                                                            Kindly confirm all
                                                            data before upload
                                                            to avoid mistake.
                                                            Any incorrect data
                                                            in the spreadsheet
                                                            might lead to upload
                                                            error.
                                                        </p>
                                                        {/* <div className="form-group">
                                                                <label for="colFormLabelSm">
                                                                    Category
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label for="colFormLabel">
                                                                    Base Fare
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="colFormLabelSm"
                                                                    placeholder=""
                                                                />
                                                            </div> */}
                                                    </form>
                                                </div>

                                                <div className="modal-footer ">
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-outline-primary mx-auto col-10"
                                                    >
                                                        <CSVLink
                                                            style={{
                                                                textDecoration:
                                                                    "none !important",
                                                            }}
                                                            data={modelTemplate}
                                                        >
                                                            Download Template
                                                        </CSVLink>
                                                    </button>
                                                </div>
                                                <div className="modal-footer">
                                                    <div className="form-group mx-auto col-10">
                                                        <input
                                                            type="file"
                                                            onChange={(e) => {
                                                                readModelFromExcel(
                                                                    e.target
                                                                        .files[0]
                                                                );
                                                            }}
                                                            className="form-control form-control-lg px-5"
                                                            id="colFormLabelMd"
                                                            placeholder="Upload Create Model Settings Excel file"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="table-add btn btn-primary mx-auto col-10"
                                                    >
                                                        Submit Your Model
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* END OF IMPORT */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleSettings;
