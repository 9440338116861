import React, { useEffect } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux";
import { getTopPartners } from "../../state/actions";

const PartnerOverview = () => {
    const dispatch = useDispatch();
    const topPartners = useSelector((state) => state.partner.topPartners);
    const isLoading = useSelector((state) => state.partner.status);
    // console.log("topPartners :>.", topPartners);
    const sortedPartners = [...topPartners.sort((a, b) => b.totalIncome - a.totalIncome).slice(0, 9)];
    console.log("sortedPartners :>>", sortedPartners);

    // topPartners.sort();

    useEffect(() => {
        dispatch(getTopPartners());
    }, []);
    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            Partners Overview
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="col-lg-12">
                                        {/* <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="card card-block card-stretch card-height">
                          <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="mm-cart-image text-danger">
                                <i
                                  className="fa-solid fa-handshake svg-icon svg-success"
                                  id="svgFont"
                                ></i>
                              </div>
                              <div className="mm-cart-text text-right">
                                <h2 className="font-weight-700">57</h2>
                                <p className="mb-0 text-danger">
                                  No. of Comfirmed Partners
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card card-block card-stretch card-height">
                          <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="mm-cart-image text-success">
                                <svg
                                  className="svg-icon svg-success mr-4"
                                  width="50"
                                  height="52"
                                  id="h-02"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                  />
                                </svg>
                              </div>
                              <div className="mm-cart-text text-right">
                                <h2 className="font-weight-700">16</h2>
                                <p className="mb-0 text-success">
                                  No. of Unconfirmed Partners
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-6">
                                                <div className="card card-block card-stretch card-height">
                                                    <div className="card-header d-flex justify-content-between">
                                                        <div className="header-title">
                                                            <h4 className="card-title">
                                                                Top Partners
                                                            </h4>
                                                        </div>           
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className="list-unstyled">
                                                            {isLoading === 'loading' ?
                                                            <div className="text-center">
                                                                <div className="spinner-border text-danger">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                                <p className="small">Fetching data...</p>
                                                            </div> :
                                                            sortedPartners.length >
                                                                0 &&
                                                                sortedPartners.map(
                                                                    (
                                                                        topPartner,
                                                                        index
                                                                    ) => (
                                                                        <li
                                                                            className="media mb-3 align-items-center"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <div className="bg-primary-light rounded p-2">
                                                                                <svg
                                                                                    className="svg-icon svg-primary text-primary"
                                                                                    width="42"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor"
                                                                                >
                                                                                    <path
                                                                                        stroke-linecap="round"
                                                                                        stroke-linejoin="round"
                                                                                        stroke-width="2"
                                                                                        d="M13 10V3L4 14h7v7l9-11h-7z"
                                                                                    />
                                                                                </svg>
                                                                            </div>
                                                                            <div className="media-support-info ml-3">
                                                                                <h5 className="open-sans-font size-18">
                                                                                    {
                                                                                        topPartner
                                                                                            .partner
                                                                                            .companyName
                                                                                    }
                                                                                </h5>
                                                                                <p className="mb-0 font-size-14 text-primary size-15 open-sans-font">
                                                                                    {
                                                                                        topPartner
                                                                                            .partner
                                                                                            .address
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="card-header-toolbar d-flex align-items-center">
                                                                                <h4 className="text-danger size-20">
                                                                                    <b>
                                                                                        &#8358;
                                                                                        {Math.floor(
                                                                                            topPartner.totalIncome
                                                                                        )
                                                                                            .toFixed(2)
                                                                                            .toString()
                                                                                            .replace(
                                                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                                                ","
                                                                                            )}
                                                                                    </b>
                                                                                </h4>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartnerOverview;
