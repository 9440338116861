import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import Chart from "react-apexcharts";
import { Pie, Bar } from "react-chartjs-2";
import { 
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from "chart.js";
import {
  orderingTrends,
  shippingTrends,
  topRiders,
  topPartners,
  topCustomers,
  orderReport,
  revenueReport,
} from "../../state/actions";
import { set } from "date-fns";

const ShippingReportChart = ({ data }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
      },
      xaxis: {
        categories: [],
      },
      legend: {
        show: true,
      },
      colors: ["#4788ff", "#c05c54"],
    },
    series: [],
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const seriesData = data.map((item) => item.value);
      const categoriesData = data.map((item) => item.key);

      setChartData((prevState) => ({
        ...prevState,
        series: [{ data: seriesData }],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: categoriesData,
          },
        },
      }));
    }
  }, [data]);

  return (
    <div className="bar">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height="350"
      />
    </div>
  );
};

const OrderTrendChart = ({ data }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-pie",
      },
      dataLabels: {
        enabled: true,
        textAnchor: "middle",
        // formatter: function (val, opts) {
        //   return `${val.toLocaleString("ng-EG")}`
        // }
      },
      legend: {
        show: false,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        fontSize: "12px",
        fontFamily: "Helvetica, Arial",
        fontWeight: 400,
      },
    },
    series: [],
    labels: [],
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const seriesData = data.map((item) => item.value);
      const labelsData = data.map((item) => item.key);
      console.log("labels", labelsData);

      setChartData((prevState) => ({
        ...prevState,
        series: seriesData,
        labels: labelsData,
      }));
    }
  }, [data]);

  return (
    <div className="pie">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width="380"
        labels={chartData.labels}
      />
    </div>
  );
};

const OrderReportChart = ({ data, width, height }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const labels = data.map(item => item.key);
  const values = data.map(item => item.value);

  const getRandomColor = () => {
    return '#' + (Math.random().toString(16) + '000000').substring(2, 8);
  };

  const backgroundColors = Array.from({ length: data.length }, () => getRandomColor());

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: ["#b23934"],
        borderColor: ["#b23934"],
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.8,
        categoryPercentage: 0.9
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <div style={{ width: width, height: height }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
}

const RevenueReportChart = ({ data, width, height }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const labels = data.map((item) => item.key);
  const values = data.map((item) => item.value);

  const getRandomColor = () => {
    return "#" + (Math.random().toString(16) + "000000").substring(2, 8);
  };

  const backgroundColors = Array.from({ length: data.length }, () =>
    getRandomColor()
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
        borderColor: backgroundColors,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: "bottom",
      labels: {
        fontSize: 12,
        fontColor: "#333",
        fontFamily: "Arial",
      },
    },
  };

  return (
    <div style={{ width: width, height: height }}>
      <Pie data={chartData} options={chartOptions} />
    </div>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const trips = useSelector((state) => state.trips.orderings);
  const topP = useSelector((state) => state.trips.topPartners);
  const topR = useSelector((state) => state.trips.topRiders);
  const topV = useSelector((state) => state.trips.topVehicles);

  const isLoading = useSelector((state) => state.trips.status);

  const [partners, setPartners] = useState([]);
  const [riders, setRiders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [orderStart, setOrderStart] = useState("2024-01-01");
  const [orderEnd, setOrderEnd] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [orderOptions, setOrderOptions] = useState([
    { text: "Awaiting Pickup", value: 0 },
    { text: "Picked", value: 1 },
    { text: "Pending delivery", value: 2 },
    { text: "Delivered", value: 3 },
    { text: "New", value: 4 },
  ]);
  const [orderOption, setOrderOption] = useState(3);
  const [revenueStart, setRevenueStart] = useState("2024-01-01");
  const [revenueEnd, setRevenueEnd] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [revenueOptions, setRevenueOptions] = useState([
    { text: "Payment Type", value: 0 },
    { text: "Merchant", value: 1 },
  ]);
  const [revenueOption, setRevenueOption] = useState(0);

  const o = {
    start: orderStart ? orderStart : "",
    end: orderEnd ? orderEnd : "",
    option: orderOption ? orderOption : 0,
  };
  const r = {
    start: revenueStart ? revenueStart : "",
    end: revenueEnd ? revenueEnd : "",
    option: revenueOption ? revenueOption : 0,
  };

  useEffect(() => {
    dispatch(orderingTrends());
    dispatch(topPartners()).then((res) => {
      setPartners(res);
    });
    dispatch(topRiders()).then((res) => {
      setRiders(res);
    });
    dispatch(topCustomers()).then((res) => {
      setCustomers(res);
    });
    dispatch(orderReport(o)).then((res) => {
      setOrders(res);
      console.log("orderReport", res);
    });
    dispatch(revenueReport(r)).then((res) => {
      setRevenue(res);
      console.log("revenueReport", res);
    });
  }, [dispatch]);
  var wS = trips.map((s) => s.key);
  var wS2 = trips.map((s) => s.value);
  var wS21 = wS2.map((s) => Object.keys(s));
  console.log(wS21);
  console.log("s1", wS);
  console.log("s2", wS2);
  const [shippingOptions, setShippingOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: wS,
    },
    colors: ["#4788ff", "#4fd69c", "#b23934"],
  });
  const [shippingSeries, setShippingSeries] = useState(wS2);

  const handleSearchOrderReport = () => {
    dispatch(orderReport(o)).then((res) => {
      setOrders(res);
    });
  };

  const handleSearchRevenueReport = () => {
    dispatch(revenueReport(r)).then((res) => {
      setRevenue(res);
    });
  };

  function formatIncome(income) {
    if (income >= 100000) {
      return (income / 1000).toFixed(2) + "K";
    } else if (income >= 1000000) {
      return (income / 1000000).toFixed(2) + "M";
    } else {
      return `${income.toLocaleString("ng-EG")}`;
    }
  }

  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="d-flex align-items-center justify-content-between welcome-content">
                <div className="navbar-breadcrumb">
                  <h4 className="mb-0 font-weight-700 montserrat-font size-30">
                    Dashboard
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="card card-block card-stretch card-height">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title size-20 roboto-font">
                      Top Riders
                    </h4>
                  </div>
                  {/* <div className="card-header-toolbar d-flex align-items-center">
                    <a href="#" className="btn btn-outline-primary view-more">
                      View More
                    </a>
                  </div> */}
                </div>
                <div className="card-body">
                  <ul className="performer-lists list-inline p-0 m-0">
                    {isLoading === "loading" ? (
                      <div class="text-center">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : riders?.length > 0 ? (
                      riders?.map((r, Key) => {
                        return (
                          <li className="d-flex mb-3 align-items-center">
                            <div className="top-block-one d-flex align-items-center justify-content-between">
                              <div className="bg-primary icon new-mm-icon-box mr-0 rounded">
                                <img
                                  src="assets/images/no-picture.png"
                                  className="img-fluid avatar-50 rounded-small"
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div className="media-support-info ml-3">
                              <h5 className="open-sans-font size-18">
                                {r.rider.firstName} {r.rider.lastName}
                              </h5>
                              <p className="mb-0 font-size-14 text-primary size-15 open-sans-font">
                                {r.rider?.partner?.companyName}
                              </p>
                            </div>
                            <div className="card-header-toolbar d-flex align-items-center">
                              <h4 className="text-danger size-20">
                                <b>
                                  {/* {`${p.totalIncome.toLocaleString("ng-EG")}`} */}
                                  {formatIncome(r.totalEarned)}
                                </b>
                              </h4>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="text-center">
                        <p className="">No data currently available</p>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card card-block card-stretch card-height rule-box">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title size-20 roboto-font">
                      Top Partners
                    </h4>
                  </div>
                  {/* <div className="card-header-toolbar d-flex align-items-center">
                    <a href="#" className="btn btn-outline-primary view-more">
                      View More
                    </a>
                  </div> */}
                </div>
                <div className="card-body">
                  <ul className="performer-lists m-0 p-0">
                    {isLoading === "loading" ? (
                      <div class="text-center">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : partners?.length > 0 ? (
                      partners?.map((p, Key) => {
                        return (
                          <li className="d-flex mb-3 align-items-center">
                            <div className="top-block-one d-flex align-items-center justify-content-between">
                              <div className="bg-primary icon new-mm-icon-box mr-0 rounded">
                                <img
                                  src="assets/images/no-image.png"
                                  className="img-fluid avatar-50 rounded-small"
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div className="media-support-info ml-3">
                              <h5 className="open-sans-font size-18">
                                {p.partner.companyName}
                              </h5>
                              <p className="mb-0 font-size-14 text-primary size-15 open-sans-font">
                                {p.partner.address}
                              </p>
                            </div>
                            <div className="card-header-toolbar d-flex align-items-center">
                              <h4 className="text-danger size-20">
                                <b>
                                  {/* {`${p.totalIncome.toLocaleString("ng-EG")}`} */}
                                  {formatIncome(p.totalIncome)}
                                </b>
                              </h4>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="text-center">
                        <p className="">No data currently available</p>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card card-block card-stretch card-height rule-box">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title size-20 roboto-font">
                      Top Customers
                    </h4>
                  </div>
                  {/* <div className="card-header-toolbar d-flex align-items-center">
                    <a href="#" className="btn btn-outline-primary view-more">
                      View More
                    </a>
                  </div> */}
                </div>
                <div className="card-body">
                  <ul className="performer-lists m-0 p-0">
                    {isLoading === "loading" ? (
                      <div class="text-center">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : customers?.length > 0 ? (
                      customers?.map((c, Key) => {
                        return (
                          <li className="d-flex mb-3 align-items-center">
                            <div className="top-block-one d-flex align-items-center justify-content-between">
                              <div className="bg-primary icon new-mm-icon-box mr-0 rounded">
                                <img
                                  src="assets/images/no-picture.png"
                                  className="img-fluid avatar-50 rounded-small"
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div className="media-body ml-3">
                              <div className="d-flex justify-content-between">
                                <h5 className="open-sans-font size-18">
                                  {c.customer.firstName} {c.customer.lastName}
                                </h5>
                                <span className="text-danger font-size-12">
                                  {c.totalOrder > 1
                                    ? c.totalOrder + " Orders"
                                    : c.totalOrder + " Order"}
                                </span>
                              </div>
                              <span className="text-primary size-15">
                                {c.customer.address}
                              </span>
                            </div>
                            {/* <div className="media-support-info ml-3">
                              <h5 className="open-sans-font size-18">
                                {c.customer.firstName} {" "} {c.customer.lastName}
                              </h5>
                              <p className="mb-0 font-size-14 text-primary size-15 open-sans-font">
                                {c.customer.address}
                              </p>
                            </div>
                            <div className="card-header-toolbar d-flex align-items-center">
                              <h4 className="text-danger size-20">
                                <b>
                                  { c.totalOrder }
                                </b>
                              </h4>
                            </div> */}
                          </li>
                        );
                      })
                    ) : (
                      <div className="text-center">
                        <p className="">No data currently available</p>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {/* <h6
                style={{ fontSize: "18px", fontWeight: 500, color: "#CCCCCC" }}
                className="mb-2"
              >
                Scheduled Delivery
              </h6> */}
              <div className="row">
                <div className="col-lg-7 col-md-6">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-header">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="header-title">
                          <h4 className="card-title size-20 roboto-font">
                            Order Report
                          </h4>
                        </div>
                        <div className="card-header-toolbar d-flex align-items-center">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle dropdown-bg btn btn-outline-primary "
                              id="dropdownMenuButton2"
                              data-toggle="dropdown"
                            >
                              {orderOption === 0
                                ? "Pending Pickup"
                                : orderOption === 1
                                ? "Picked"
                                : orderOption === 2
                                ? "Pending Delivery"
                                : orderOption === 3
                                ? "Delivered"
                                : orderOption === 4
                                ? "New"
                                : "All"}
                              <i className="ri-arrow-down-s-line ml-1"></i>
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton2"
                            >
                              {orderOptions.map((opt, Key) => {
                                return (
                                  <a
                                    className="dropdown-item"
                                    onClick={() => setOrderOption(opt.value)}
                                  >
                                    {opt.text}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-10">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  style={{ fontSize: "14px" }}
                                  className="form-label"
                                >
                                  Start Date:
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={orderStart}
                                  onChange={(e) =>
                                    setOrderStart(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  style={{ fontSize: "14px" }}
                                  className="form-label"
                                >
                                  End Date:
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={orderEnd}
                                  onChange={(e) => setOrderEnd(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="card-header-toolbar d-flex align-items-center">
                            <div className="dropdown mt-4">
                              <span
                                className="dropdown-toggle dropdown-bg btn btn-outline-primary d-flex align-items-center"
                                id="dropdownMenuButton2"
                                data-toggle="dropdown"
                              >
                                <i className="">
                                  <svg
                                    fill="#000000"
                                    width="20px"
                                    viewBox="0 0 512 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <title>ionicons-v5-f</title>
                                    <circle cx="256" cy="256" r="48" />
                                    <circle cx="256" cy="416" r="48" />
                                    <circle cx="256" cy="96" r="48" />
                                  </svg>
                                </i>
                              </span>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <a
                                  className="dropdown-item"
                                  onClick={handleSearchOrderReport}
                                >
                                  Search
                                </a>
                                <a className="dropdown-item" href="#">
                                  Export
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      {isLoading === "loading" ? (
                        <div class="text-center">
                          <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : orders?.length > 0 ? (
                        <div id="chart-01">
                          <OrderReportChart data={orders} width={"100%"} height={"400px"} />
                        </div>
                      ) : (
                        <div className="text-center">
                          <p className="">
                            No data currently available for this option
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="card card-block card-stretch card-height rule-box">
                    <div className="card-header">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="header-title">
                          <h4 className="card-title size-20 roboto-font">
                            Revenue Report
                          </h4>
                        </div>
                        <div className="card-header-toolbar d-flex align-items-center">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle dropdown-bg btn btn-outline-primary "
                              id="dropdownMenuButton2"
                              data-toggle="dropdown"
                            >
                              {revenueOption === 0
                                ? "Payment Type"
                                : revenueOption === 1
                                ? "Merchant"
                                : "All"}
                              <i className="ri-arrow-down-s-line ml-1"></i>
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton2"
                            >
                              {revenueOptions.map((opt, Key) => {
                                return (
                                  <a
                                    className="dropdown-item"
                                    onClick={() => setRevenueOption(opt.value)}
                                  >
                                    {opt.text}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-10">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  style={{ fontSize: "14px" }}
                                  className="form-label"
                                >
                                  Start Date:
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={revenueStart}
                                  onChange={(e) =>
                                    setRevenueStart(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  style={{ fontSize: "14px" }}
                                  className="form-label"
                                >
                                  End Date:
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={revenueEnd}
                                  onChange={(e) =>
                                    setRevenueEnd(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="card-header-toolbar d-flex align-items-center">
                            <div className="dropdown mt-4">
                              <span
                                className="dropdown-toggle dropdown-bg btn btn-outline-primary d-flex align-items-center"
                                id="dropdownMenuButton2"
                                data-toggle="dropdown"
                              >
                                <i className="">
                                  <svg
                                    fill="#000000"
                                    width="20px"
                                    viewBox="0 0 512 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <title>ionicons-v5-f</title>
                                    <circle cx="256" cy="256" r="48" />
                                    <circle cx="256" cy="416" r="48" />
                                    <circle cx="256" cy="96" r="48" />
                                  </svg>
                                </i>
                              </span>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <a
                                  className="dropdown-item"
                                  onClick={handleSearchRevenueReport}
                                >
                                  Search
                                </a>
                                <a className="dropdown-item" href="#">
                                  Export
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      {isLoading === "loading" ? (
                        <div class="text-center">
                          <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : revenue.length > 0 ? (
                        <div id="chart-01">
                          <RevenueReportChart
                            data={revenue}
                            width={"350px"}
                            height={"350px"}
                          />
                        </div>
                      ) : (
                        <div className="text-center">
                          <p className="">
                            No data currently available for this option
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
