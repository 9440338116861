import React from "react";
// import Input from "../../components/UI/Input";
import Modal from "../../components/UI/Modal";
import { Row, Col } from "react-bootstrap";

const AddVehicleTypeModal = (props) => {
    const {
        show,
        handleClose,
        modalTitle,
        // categoryName,
        // setCategoryName,
        parentCategoryId,
        setParentCategoryId,
        categoryList,
        handleCategoryImage,
        onSubmit,
    } = props;

    return (
        <Modal
            show={show}
            handleClose={handleClose}
            onSubmit={onSubmit}
            modalTitle={modalTitle}
        >
            <Row>
                <Col>
                    <div className="form-group">
                        <label for="colFormLabelSm">Brand</label>
                        <input
                            type="text"
                            className="form-control"
                            id="colFormLabelSm"
                            placeholder=""
                        />
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default AddVehicleTypeModal;
