import {
    Box,
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import {
    getApprovedPartners
} from "../../state/actions";

const ApprovedPartner = () => {
    const dispatch = useDispatch();
    

    const approvedPartners = useSelector(
        (state) => state.partner.approvedPartners
    );
    const isLoading = useSelector((state) => state.partner.status)
    console.log("approvedPartners :>> ", approvedPartners);const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getApprovedPartners())
    }, []);

    const [page, setPage] = useState(0);
    const [openViewDetail, setOpenViewDetail] = useState(false);

    const handleCloseViewDetail = () => {
        setOpenViewDetail(false);
    };

    const handleChangePage = useCallback(
        (_, page) => {
            setPage(page);
        },
        [setPage]
    );
    const [selectedPartner, setSelectedPartner] = useState({});

    const selectPartner = (partner) => {
        setSelectedPartner(partner);
        console.log("selectedPartner :>> ", partner);
        setOpenViewDetail(true);
    };

    let rowsPerPage = 10;
    function getStatus(status) {
        switch (status) {
            case 0:
                return <span className="badge badge-danger">Pending</span>;
            case 1:
                return <span className="badge badge-success">Approved</span>;
            case 2:
                return (
                    <span className="badge badge-warning">
                        Awaiting Approval
                    </span>
                );

            default:
                return <span className="badge badge-danger">Pending</span>;
        }
    }
    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            Approved Partners
                                        </h4>
                                    </div>
                                </div>
                                <Box
                                    // width="110%"
                                    sx={{
                                        backgroundColor: "#fff",
                                        // marginLeft: theme.spacing(3),
                                    }}
                                >
                                    <div>
                                        {isLoading === 'loading' ?
                                        <div className="text-center">
                                            <div className="spinner-border text-danger">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <p className="small">Getting Approved Partners...</p>
                                        </div> :
                                        approvedPartners.length > 0 ? (
                                            <Table aria-labelledby="tableTitle">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            Address
                                                        </TableCell>
                                                        <TableCell>
                                                            RC Number
                                                        </TableCell>
                                                        <TableCell>
                                                            Phone Number
                                                        </TableCell>
                                                        <TableCell>
                                                            Status
                                                        </TableCell>
                                                        <TableCell>
                                                            View Detail
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {approvedPartners.length >
                                                        0 &&
                                                        approvedPartners.map(
                                                            (
                                                                approvedPartner,
                                                                index
                                                            ) => (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    key={index}
                                                                    onClick={() => {
                                                                        // selectRow(
                                                                        //     trackOrder
                                                                        // );
                                                                    }}
                                                                    sx={{
                                                                        height: "30 !important",
                                                                        // maxWidth: "50rem",
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        // component="th"
                                                                        scope="row"
                                                                    >
                                                                        {
                                                                            approvedPartner.companyName
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        // sx={{
                                                                        //     width: "5% !important",
                                                                        //     padding:
                                                                        //         "0 5",
                                                                        //     margin: "0 !important",
                                                                        // }}
                                                                    >
                                                                        {
                                                                            approvedPartner.address
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            approvedPartner.rcNumber
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            approvedPartner.telephone
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {getStatus(
                                                                            approvedPartner.status
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0 !important",
                                                                        }}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-primary"
                                                                            onClick={() => {
                                                                                selectPartner(
                                                                                    approvedPartner
                                                                                );
                                                                            }}
                                                                        >
                                                                            View
                                                                            Details
                                                                        </button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                </TableBody>
                                            </Table>
                                        ) :
                                        null}
                                    </div>
                                    <div>
                                        <TablePagination
                                            component="div"
                                            count={approvedPartners.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page",
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page",
                                            }}
                                            onPageChange={handleChangePage}
                                            labelRowsPerPage=""
                                        />
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Modal */}

            {/* dialog */}

            <Dialog 
            open={openViewDetail} 
            onClose={handleCloseViewDetail}
            fullWidth={true}
            maxWidth="sm"
            >
                {/* <DialogTitle>Subscribe</DialogTitle> */}
                <DialogContent>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title"
                                id="exampleModalCenteredScrollableTitle"
                            >
                                Partner details
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    onClick={handleCloseViewDetail}
                                >
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="media align-items-start">
                                {/* <img
                                    src="assets/images/user/1.jpg"
                                    className="img-fluid avatar-rounded avatar-60"
                                    alt="user"
                                /> */}
                                <div className="bg-primary-light avatar-rounded p-2">
                                    <svg
                                        className="svg-icon svg-primary text-primary"
                                        width="42"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M13 10V3L4 14h7v7l9-11h-7z"
                                        />
                                    </svg>
                                </div>
                                <div className="media-body ml-2 row">
                                    <div className="col-md-12">
                                        <p className="mb-0 mt-1">
                                            {selectedPartner.companyName}
                                        </p>
                                        <span className="font-weight-bold">
                                            {selectedPartner.rcNumber}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="media align-items-start mt-4">
                                        <div className="media-body">
                                            <p className="text mb-0">Contact Name</p>
                                            <span className="font-weight-bold d-block">
                                                {selectedPartner.contactName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="media align-items-start mt-4">
                                        <div className="media-body">
                                            <p className="text mb-0">Contact Phone</p>
                                            <span className="font-weight-bold d-block">
                                                {selectedPartner.telephone}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="media align-items-start mt-4">
                                        <div className="media-body">
                                            <p className="text mb-0">Company Address</p>
                                            <span className="font-weight-bold d-block">
                                                {selectedPartner.address}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="media align-items-start mt-4">
                                        <div className="media-body">
                                            <p className="text mb-0">Earn Percentage</p>
                                            <span className="font-weight-bold d-block">
                                                {selectedPartner.earnPercentage}%
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="media align-items-start mt-4">
                                <div className="media-body">
                                    <p className="text mb-0">Contact Email</p>
                                    <span className="font-weight-bold d-block">
                                        {selectedPartner.email}
                                    </span>
                                </div>
                            </div> */}
                            <div className="media align-items-start mt-4">
                                <Table aria-labelledby="tableTitle">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th">
                                                1
                                            </TableCell>
                                            <TableCell component="th">
                                                TIN
                                            </TableCell>
                                            <TableCell component="th">
                                                <span className="badge badge-success">
                                                    Approved
                                                </span>
                                            </TableCell>
                                            <TableCell component="th">
                                                <a
                                                    href={`${selectedPartner.tinCertificateUrl}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th">
                                                2
                                            </TableCell>
                                            <TableCell component="th">
                                                CAC
                                            </TableCell>
                                            <TableCell component="th">
                                                <span className="badge badge-success">
                                                    Approved
                                                </span>
                                            </TableCell>
                                            <TableCell component="th">
                                                <a
                                                    href={`${selectedPartner.cacDocumentUrl}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th">
                                                3
                                            </TableCell>
                                            <TableCell component="th">
                                                TAX
                                            </TableCell>
                                            <TableCell component="th">
                                                <span className="badge badge-success">
                                                    Approved
                                                </span>
                                            </TableCell>
                                            <TableCell component="th">
                                                <a
                                                    href={`${selectedPartner.taxFormUrl}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ApprovedPartner;
