export const orderConstants = {
    GET_ORDERS_REQUEST: "GET_ORDERS_REQUEST",
    GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
    GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",

    GET_ORDER_REPORT_BY_MONTH_REQUEST: "GET_ORDER_REPORT_BY_MONTH_REQUEST",
    GET_ORDER_REPORT_BY_MONTH_SUCCESS: "GET_ORDER_REPORT_BY_MONTH_SUCCESS",
    GET_ORDER_REPORT_BY_MONTH_FAILURE: "GET_ORDER_REPORT_BY_MONTH_FAILURE",

    GET_ORDER_REPORT_BY_YEAR_REQUEST: "GET_ORDER_REPORT_BY_YEAR_REQUEST",
    GET_ORDER_REPORT_BY_YEAR_SUCCESS: "GET_ORDER_REPORT_BY_YEAR_SUCCESS",
    GET_ORDER_REPORT_BY_YEAR_FAILURE: "GET_ORDER_REPORT_BY_YEAR_FAILURE",

    GET_ORDER_REPORT_BY_STATUS_REQUEST: "GET_ORDER_REPORT_BY_STATUS_REQUEST",
    GET_ORDER_REPORT_BY_STATUS_SUCCESS: "GET_ORDER_REPORT_BY_STATUS_SUCCESS",
    GET_ORDER_REPORT_BY_STATUS_FAILURE: "GET_ORDER_REPORT_BY_STATUS_FAILURE",

    CANCEL_ORDER_REQUEST: "CANCEL_ORDER_REQUEST",
    CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",

    RESET_ORDER_REQUEST: "RESET_ORDER_REQUEST",
    RESET_ORDER_SUCCESS: "RESET_ORDER_SUCCESS",
    RESET_ORDER_FAILURE: "RESET_ORDER_FAILURE",

    GET_COD_REPORT_REQUEST: "GET_COD_REPORT_REQUEST",
    GET_COD_REPORT_SUCCESS: "GET_COD_REPORT_SUCCESS",
    GET_COD_REPORT_FAILURE: "GET_COD_REPORT_FAILURE",

    EXPORT_COD_REQUEST: "EXPORT_COD_REQUEST",
    EXPORT_COD_SUCCESS: "EXPORT_COD_SUCCESS",
    EXPORT_COD_FAILURE: "EXPORT_COD_FAILURE",

    GET_TEMPLATE_REQUEST: "GET_TEMPLATE_REQUEST",
    GET_TEMPLATE_SUCCESS: "GET_TEMPLATE_SUCCESS",
    GET_TEMPLATE_FAILURE: "GET_TEMPLATE_FAILURE",

    IMPORT_SHIPMENTS_REQUEST: "IMPORT_SHIPMENTS_REQUEST",
    IMPORT_SHIPMENTS_SUCCESS: "IMPORT_SHIPMENTS_SUCCESS",
    IMPORT_SHIPMENTS_FAILURE: "IMPORT_SHIPMENTS_FAILURE",

    GET_SHIPMENTS_REQUEST: "GET_SHIPMENTS_REQUEST",
    GET_SHIPMENTS_SUCCESS: "GET_SHIPMENTS_SUCCESS",
    GET_SHIPMENTS_FAILURE: "GET_SHIPMENTS_FAILURE",

    GET_RCS_REQUEST: "GET_RCS_REQUEST",
    GET_RCS_SUCCESS: "GET_RCS_SUCCESS",
    GET_RCS_FAILURE: "GET_RCS_FAILURE",

    GENERATE_RCS_REQUEST: "GENERATE_RCS_REQUEST",
    GENERATE_RCS_SUCCESS: "GENERATE_RCS_SUCCESS",
    GENERATE_RCS_FAILURE: "GENERATE_RCS_FAILURE",

    GET_RCS_DETAIL_REQUEST: "GET_RCS_DETAIL_REQUEST",
    GET_RCS_DETAIL_SUCCESS: "GET_RCS_DETAIL_SUCCESS",
    GET_RCS_DETAIL_FAILURE: "GET_RCS_DETAIL_FAILURE",

    CONFIRM_HANDOVER_REQUEST: "CONFIRM_HANDOVER_REQUEST",
    CONFIRM_HANDOVER_SUCCESS: "CONFIRM_HANDOVER_SUCCESS",
    CONFIRM_HANDOVER_FAILURE: "CONFIRM_HANDOVER_FAILURE",

    GET_CITIES_REQUEST: "GET_CITIES_REQUEST",
    GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
    GET_CITIES_FAILURE: "GET_CITIES_FAILURE",

    GET_DRIVERS_REQUEST: "GET_DRIVERS_REQUEST",
    GET_DRIVERS_SUCCESS: "GET_DRIVERS_SUCCESS",
    GET_DRIVERS_FAILURE: 'GET_DRIVERS_FAILURE'
};

export const mapConstants = {
    SUGGEST_ADDR_REQUEST: "SUGGEST_ADDR_REQUEST",
    SUGGEST_ADDR_SUCCESS: "SUGGEST_ADDR_SUCCESS",
    SUGGEST_ADDR_ERROR: "SUGGEST_ADDR_ERROR",

    ADDR_DETAIL_REQUEST: "ADDR_DETAIL_REQUEST",
    ADDR_DETAIL_SUCCESS:"ADDR_DETAIL_SUCCESS",
    ADDR_DETAIL_ERROR: "ADDR_DETAIL_ERROR"
}

export const homeConstants = {
    TOP_PARTNERS_REQUEST: "TOP_PARTNERS_REQUEST",
    TOP_PARTNERS_SUCCESS: "TOP_PARTNERS_SUCCESS",
    TOP_PARTNERS_ERROR: "TOP_PARTNERS_ERROR",

    TOP_RIDERS_REQUEST: "TOP_RIDERS_REQUEST",
    TOP_RIDERS_SUCCESS: "TOP_RIDERS_SUCCESS",
    TOP_RIDERS_ERROR: "TOP_RIDERS_ERROR",

    TOP_CUSTOMERS_REQUEST: "TOP_CUSTOMERS_REQUEST",
    TOP_CUSTOMERS_SUCCESS: "TOP_CUSTOMERS_SUCCESS",
    TOP_CUSTOMERS_ERROR: "TOP_CUSTOMERS_ERROR",
    
    TOP_VEHICLES_REQUEST: "TOP_VEHICLES_REQUEST",
    TOP_VEHICLES_SUCCESS: "TOP_VEHICLES_SUCCESS",
    TOP_VEHICLES_ERROR: "TOP_VEHICLES_ERROR",

    ORDERING_TREND_REQUEST: "ORDERING_TREND_REQUEST",
    ORDERING_TREND_SUCCESS: "ORDERING_TREND_SUCCESS",
    ORDERING_TREND_ERROR: "ORDERING_TREND_ERROR",

    SHIPPING_TRIP_REQUEST: "SHIPPING_TRIP_REQUEST",
    SHIPPING_TRIP_SUCCESS: "SHIPPING_TRIP_SUCCESS",
    SHIPPING_TRIP_ERROR: "SHIPPING_TRIP_ERROR",

    ORDER_REPORT_REQUEST: "ORDER_REPORT_REQUEST",
    ORDER_REPORT_SUCCESS: "ORDER_REPORT_SUCCESS",
    ORDER_REPORT_ERROR: "ORDER_REPORT_ERROR",

    REVENUE_REPORT_REQUEST: "REVENUE_REPORT_REQUEST",
    REVENUE_REPORT_SUCCESS: "REVENUE_REPORT_SUCCESS",
    REVENUE_REPORT_ERROR: "REVENUE_REPORT_ERROR"
}

export const categoriesConstants = {
    GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",

    POST_CATEGORIES_REQUEST: "POST_CATEGORIES_REQUEST",
    POST_CATEGORIES_SUCCESS: "POST_CATEGORIES_SUCCESS",
    POST_CATEGORIES_FAILURE: "POST_CATEGORIES_FAILURE",

    UPDATE_CATEGORIES_REQUEST: "UPDATE_CATEGORIES_REQUEST",
    UPDATE_CATEGORIES_SUCCESS: "UPDATE_CATEGORIES_SUCCESS",
    UPDATE_CATEGORIES_FAILURE: "UPDATE_CATEGORIES_FAILURE",

    DELETE_CATEGORIES_REQUEST: "DELETE_CATEGORIES_REQUEST",
    DELETE_CATEGORIES_SUCCESS: "DELETE_CATGORIES_SUCCESS",
    DELETE_CATEGORIES_FAILURE: "DELETE_CATEGORIES_FAILURE",
};

export const ratingQuestions = {
    GET_RATING_QUESTIONS_REQUEST: "GET_RATING_QUESTIONS_REQUEST",
    GET_RATING_QUESTIONS_SUCCESS: "GET_RATING_QUESTIONS_SUCCESS",
    GET_RATING_QUESTIONS_FAILURE: "GET_RATING_QUESTIONS_FAILURE",

    ADD_RATING_QUESTIONS_REQUEST: "ADD_RATING_QUESTIONS_REQUEST",
    ADD_RATING_QUESTIONS_SUCCESS: "ADD_RATING_QUESTIONS_SUCCESS",
    ADD_RATING_QUESTIONS_FAILURE: "ADD_RATING_QUESTIONS_FAILURE",

    EDIT_RATING_QUESTIONS_REQUEST: "EDIT_RATING_QUESTIONS_REQUEST",
    EDIT_RATING_QUESTIONS_SUCCESS: "EDIT_RATING_QUESTIONS_SUCCESS",
    EDIT_RATING_QUESTIONS_FAILURE: "EDIT_RATING_QUESTIONS_FAILURE",

    DELETE_RATING_QUESTIONS_REQUEST: "DELETE_RATING_QUESTIONS_REQUEST",
    DELETE_RATING_QUESTIONS_SUCCESS: "DELETE_RATING_QUESTIONS_SUCCESS",
    DELETE_RATING_QUESTIONS_FAILURE: "DELETE_RATING_QUESTIONS_FAILURE"
}

export const orderIncomesConstants = {
    GET_ORDER_INCOMES_REQUEST: "GET_ORDER_INCOMES_REQUEST",
    GET_ORDER_INCOMES_SUCCESS: "GET_ORDER_INCOMES_SUCCESS",
    GET_ORDER_INCOMES_FAILURE: "GET_ORDER_INCOMES_FAILURE",
    GET_SCHORDER_INCOMES_REQUEST: "GET_SCHORDER_INCOMES_REQUEST",
    GET_SCHORDER_INCOMES_SUCCESS: "GET_SCHORDER_INCOMES_SUCCESS",
    GET_SCHORDER_INCOMES_FAILURE: "GET_SCHORDER_INCOMES_FAILURE",
    GET_EXPORT_INCOME_RESQUEST: "GET_EXPORT_INCOMES_REQUEST",
    GET_EXPORT_INCOMES_SUCCESS: "GET_EXPORT_INCOMES_SUCCESS",
    GET_EXPORT_INCOMES_FAILURE: "GET_EXPORT_INCOMES_FAILURE",
}

export const partnersIncomesConstants = {
    GET_PARTNERS_INCOMES_REQUEST: "GET_PARTNERS_INCOMES_REQUEST",
    GET_PARTNERS_INCOMES_SUCCESS: "GET_PARTNERS_INCOMES_SUCCESS",
    GET_PARTNERS_INCOMES_FAILURE: "GET_PARTNERS_INCOMES_FAILURE",
};

export const walletConstants = {
    GET_WALLET_REQUEST: "GET_WALLET_REQUEST",
    EXPORT_WALLET_REQUEST: "EXPORT_WALLET_REQUEST",
    GET_WALLET_SUCCESS: "GET_WALLET_SUCCESS",
    GET_WALLET_FAILURE: "GET_WALLET_FAILURE",
    EXPORT_WALLET_SUCCESS: "EXPORT_WALLET_SUCCESS",
    EXPORT_WALLET_FAILURE: "EXPORT_WALLET_FAILURE"
};

export const settlementConstant = {
    ADD_PARTNER_SETTLEMENT_REQUEST: "ADD_PARTNER_SETTLEMENT_REQUEST",
    ADD_PARTNER_SETTLEMENT_SUCCESS: "ADD_PARTNER_SETTLEMENT_SUCCESS",
    ADD_PARTNER_SETTLEMENT_FAILURE: "ADD_PARTNER_SETTLEMENT_FAILURE"
}

export const newPartnersConstants = {
    GET_NEW_PARTNERS_REQUEST: "GET_NEW_PARTNERS_REQUEST",
    GET_NEW_PARTNERS_SUCCESS: "GET_NEW_PARTNERS_SUCCESS",
    GET_NEW_PARTNERS_FAILURE: "GET_NEW_PARTNERS_FAILURE",
};
export const verifiedPartnersConstants = {
    GET_VERIFIED_PARTNERS_REQUEST: "GET_VERIFIED_PARTNERS_REQUEST",
    GET_VERIFIED_PARTNERS_SUCCESS: "GET_VERIFIED_PARTNERS_SUCCESS",
    GET_VERIFIED_PARTNERS_FAILURE: "GET_VERIFIED_PARTNERS_FAILURE",
};
export const approvedPartnersConstants = {
    GET_APPROVED_PARTNERS_REQUEST: "GET_APPROVED_PARTNERS_REQUEST",
    GET_APPROVED_PARTNERS_SUCCESS: "GET_APPROVED_PARTNERS_SUCCESS",
    GET_APPROVED_PARTNERS_FAILURE: "GET_APPROVED_PARTNERS_FAILURE",
};
export const topPartnersConstants = {
    GET_TOP_PARTNERS_REQUEST: "GET_TOP_PARTNERS_REQUEST",
    GET_TOP_PARTNERS_SUCCESS: "GET_TOP_PARTNERS_SUCCESS",
    GET_TOP_PARTNERS_FAILURE: "GET_TOP_PARTNERS_FAILURE"
}
export const newVehiclesConstants = {
    GET_NEW_VEHICLES_REQUEST: "GET_NEW_VEHICLES_REQUEST",
    GET_NEW_VEHICLES_SUCCESS: "GET_NEW_VEHICLES_SUCCESS",
    GET_NEW_VEHICLES_FAILURE: "GET_NEW_VEHICLES_FAILURE",
};
export const verifiedVehiclesConstants = {
    GET_VERIFIED_VEHICLES_REQUEST: "GET_VERIFIED_VEHICLES_REQUEST",
    GET_VERIFIED_VEHICLES_SUCCESS: "GET_VERIFIED_VEHICLES_SUCCESS",
    GET_VERIFIED_VEHICLES_FAILURE: "GET_VERIFIED_VEHICLES_FAILURE",
};
export const approvedVehiclesConstants = {
    GET_APPROVED_VEHICLES_REQUEST: "GET_APPROVED_VEHICLES_REQUEST",
    GET_APPROVED_VEHICLES_SUCCESS: "GET_APPROVED_VEHICLES_SUCCESS",
    GET_APPROVED_VEHICLES_FAILURE: "GET_APPROVED_VEHICLES_FAILURE",
};
export const addInspectionConstants = {
    POST_ADD_INSPECTION_REQUEST: "POST_ADD_INSPECTION_REQUEST",
    POST_ADD_INSPECTION_SUCCESS: "POST_ADD_INSPECTION_SUCCESS",
    POST_ADD_INSPECTION_FAILURE: "POST_ADD_INSPECTION_FAILURE",
};

export const newRidersConstants = {
    GET_NEW_RIDERS_REQUEST: "GET_NEW_RIDERS_REQUEST",
    GET_NEW_RIDERS_SUCCESS: "GET_NEW_RIDERS_SUCCESS",
    GET_NEW_RIDERS_FAILURE: "GET_NEW_RIDERS_FAILURE",
};

export const verifyRiderConstants = {
    VERIFY_RIDER_REQUEST: "VERIFY_RIDER_REQUEST",
    VERIFY_RIDER_SUCCESS: "VERIFY_RIDER_REQUEST",
    VERIFY_RIDER_FAILURE: "VERIFY_RIDER_FAILURE",
};

export const verifiedRidersConstants = {
    GET_VERIFIED_RIDERS_REQUEST: "GET_VERIFIED_RIDERS_REQUEST",
    GET_VERIFIED_RIDERS_SUCCESS: "GET_VERIFIED_RIDERS_SUCCESS",
    GET_VERIFIED_RIDERS_FAILURE: "GET_VERIFIED_RIDERS_FAILURE",
};

export const approvedRidersConstants = {
    GET_APPROVED_RIDERS_REQUEST: "GET_APPROVED_RIDERS_REQUEST",
    GET_APPROVED_RIDERS_SUCCESS: "GET_APPROVED_RIDERS_SUCCESS",
    GET_APPROVED_RIDERS_FAILURE: "GET_APPROVED_RIDERS_FAILURE",
};

export const customerConstants = {
    GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
    GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
    GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_FAILURE",
};

export const approveRiderConstants = {
    APPROVE_RIDER_REQUEST: "APPROVE_RIDER_REQUEST",
    APPROVE_RIDER_SUCCESS: "APPROVE_RIDER_REQUEST",
    APPROVE_RIDER_FAILURE: "APPROVE_RIDER_FAILURE",
};

export const importsConstants = {
    IMPORT_RIDER_REQUEST: "IMPORT_RIDER_REQUEST",
    IMPORT_RIDER_SUCCESS: "IMPORT_RIDER_SUCCESS",
    IMPORT_RIDER_ERROR: "IMPORT_RIDER_ERROR",

    GET_RIDER_TEMPLATE_REQUEST: "GET_RIDER_TEMPLATE_REQUEST",
    GET_RIDER_TEMPLATE_SUCCESS: "GET_RIDER_TEMPLATE_SUCCESS",
    GET_RIDER_TEMPLATE_ERROR: "GET_RIDER_TEMPLATE_SUCCESS",

    IMPORT_VEHICLE_REQUEST: "IMPORT_VEHICLE_REQUEST",
    IMPORT_VEHICLE_SUCCESS: "IMPORT_VEHICLE_SUCCESS",
    IMPORT_VEHICLE_ERROR: "IMPORT_VEHICLE_ERROR",

    GET_VEHICLE_TEMPLATE_REQUEST: "GET_VEHICLE_TEMPLATE_REQUEST",
    GET_VEHICLE_TEMPLATE_SUCCESS: "GET_VEHICLE_TEMPLATE_SUCCESS",
    GET_VEHICLE_TEMPLATE_ERROR: "GET_VEHICLE_TEMPLATE_SUCCESS"
}

export const tripConstants = {
    GET_TRIPS_REQUEST: "GET_TRIPS_REQUEST",
    GET_TRIPS_SUCCESS: "GET_TRIPS_SUCCESS",
    GET_TRIPS_FAILURE: "GET_TRIPS_FAILURE",

    GET_INCIDENT_REQUEST: "GET_INCIDENT_REQUEST",
    GET_INCIDENT_SUCCESS: "GET_INCIDENT_SUCCESS",
    GET_INCIDENT_ERROR: "GET_INCIDENT_ERROR",

    UPDATE_INCIDENT_REQUEST: "UPDATE_INCIDENT_REQUEST",
    UPDATE_INCIDENT_SUCCESS: "UPDATE_INCIDENT_SUCCESS",
    UPDATE_INCIDENT_ERROR: "UPDATE_INCIDENT_ERROR"
};

export const changePasswordConstants = {
    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
};

export const registerAsAdminConstants = {
    REGISTER_AS_ADMIN_REQUEST: "REGISTER_AS_ADMIN_REQUEST",
    REGISTER_AS_ADMIN_SUCCESS: "REGISTER_AS_ADMIN_SUCCESS",
    REGISTER_AS_ADMIN_FAILURE: "REGISTER_AS_ADMIN_FAILURE",
};

export const signinAsAdminConstants = {
    SIGNIN_AS_ADMIN_REQUEST: "SIGNIN_AS_ADMIN_REQUEST",
    SIGNIN_AS_ADMIN_SUCCESS: "SIGNIN_AS_ADMIN_SUCCESS",
    SIGNIN_AS_ADMIN_FAILURE: "SIGNIN_AS_ADMIN_FAILURE",
};

export const ratesConstants = {
    GET_RATES_REQUEST: "GET_RATES_REQUEST",
    GET_RATES_SUCCESS: "GET_RATES_SUCCESS",
    GET_RATES_FAILURE: "GET_RATES_FAILURE",

    ADD_RATES_REQUEST: "GET_RATES_REQUEST",
    ADD_RATES_SUCCESS: "ADD_RATES_SUCCESS",
    ADD_RATES_FAILURE: "ADD_RATES_FAILURE",

    UPDATE_RATE_REQUEST: "UPDATE_RATE_REQUEST",
    UPDATE_RATE_SUCCESS: "UPDATE_RATE_SUCCESS",
    UPDATE_RATE_FAILURE: "UPDATE_RATE_FAILURE",

    DELETE_RATE_REQUEST: "DELETE_RATE_REQUEST",
    DELETE_RATE_SUCCESS: "DELETE_RATE_SUCCESS",
    DELETE_RATE_FAILURE: "DELETE_RATE_FAILURE",
};

export const verifyPartnerConstants = {
    VERIFY_PARTNER_REQUEST: "VERIFY_PARTNER_REQUEST",
    VERIFY_PARTNER_SUCCESS: "VERIFY_PARTNER_REQUEST",
    VERIFY_PARTNER_FAILURE: "VERIFY_PARTNER_FAILURE",
};
export const approvePartnerConstants = {
    APPROVE_PARTNER_REQUEST: "APPROVE_PARTNER_REQUEST",
    APPROVE_PARTNER_SUCCESS: "APPROVE_PARTNER_REQUEST",
    APPROVE_PARTNER_FAILURE: "APPROVE_PARTNER_FAILURE",
};

export const verifyVehicleConstants = {
    VERIFY_VEHICLE_REQUEST: "VERIFY_VEHICLE_REQUEST",
    VERIFY_VEHICLE_SUCCESS: "VERIFY_VEHICLE_REQUEST",
    VERIFY_VEHICLE_FAILURE: "VERIFY_VEHICLE_FAILURE",
};
export const approveVehicleConstants = {
    APPROVE_VEHICLE_REQUEST: "APPROVE_VEHICLE_REQUEST",
    APPROVE_VEHICLE_SUCCESS: "APPROVE_VEHICLE_REQUEST",
    APPROVE_VEHICLE_FAILURE: "APPROVE_VEHICLE_FAILURE",
};

/* constants */
export const addVehicleTypeConstants = {
    ADD_VEHICLE_TYPE_REQUEST: "ADD_VEHICLE_TYPE_REQUEST",
    ADD_VEHICLE_TYPE_SUCCESS: "ADD_VEHICLE_TYPE_SUCCESS",
    ADD_VEHICLE_TYPE_FAILURE: "ADD_VEHICLE_TYPE_FAILURE",
};

export const getVehicleTypeConstants = {
    GET_VEHICLE_TYPES_REQUEST: "GET_VEHICLE_TYPES_REQUEST",
    GET_VEHICLE_TYPES_SUCCESS: "GET_VEHICLE_TYPES_SUCCESS",
    GET_VEHICLE_BRANDS_FAILURE: "GET_VEHICLE_TYPES_FAILURE",
};

export const addVehicleBrandConstants = {
    ADD_VEHICLE_BRAND_REQUEST: "ADD_VEHICLE_BRAND_REQUEST",
    ADD_VEHICLE_BRAND_SUCCESS: "ADD_VEHICLE_BRAND_SUCCESS",
    ADD_VEHICLE_BRAND_FAILURE: "ADD_VEHICLE_BRAND_FAILURE",
};

export const deleteVehicleBrandConstants = {
    DELETE_VEHICLE_BRAND_REQUEST: "DELETE_VEHICLE_BRAND_REQUEST",
    DELETE_VEHICLE_BRAND_SUCCESS: "DELETE_VEHICLE_BRAND_SUCCESS",
    DELETE_VEHICLE_BRAND_FAILURE: "DELETE_VEHICLE_BRAND_FAILURE",
};

export const editVehicleBrandConstants = {
    EDIT_VEHICLE_BRAND_REQUEST: "EDIT_VEHICLE_BRAND_REQUEST",
    EDIT_VEHICLE_BRAND_SUCCESS: "EDIT_VEHICLE_BRAND_SUCCESS",
    EDIT_VEHICLE_BRAND_FAILURE: "EDIT_VEHICLE_BRAND_FAILURE",
};

export const getVehicleBrandConstants = {
    GET_VEHICLE_BRANDS_REQUEST: "GET_VEHICLE_BRANDS_REQUEST",
    GET_VEHICLE_BRANDS_SUCCESS: "GET_VEHICLE_BRANDS_SUCCESS",
    GET_VEHICLE_BRANDS_FAILURE: "GET_VEHICLE_BRANDS_FAILURE",
};

export const addVehicleSizeConstants = {
    ADD_VEHICLE_SIZE_REQUEST: "ADD_VEHICLE_SIZE_REQUEST",
    ADD_VEHICLE_SIZE_SUCCESS: "ADD_VEHICLE_SIZE_SUCCESS",
    ADD_VEHICLE_SIZE_FAILURE: "ADD_VEHICLE_SIZE_FAILURE",
};

export const deleteVehicleSizeConstants = {
    DELETE_VEHICLE_SIZE_REQUEST: "DELETE_VEHICLE_SIZE_REQUEST",
    DELETE_VEHICLE_SIZE_SUCCESS: "DELETE_VEHICLE_SIZE_SUCCESS",
    DELETE_VEHICLE_SIZE_FAILURE: "DELETE_VEHICLE_SIZE_FAILURE",
};

export const getVehicleSizeConstants = {
    GET_VEHICLE_SIZE_REQUEST: "GET_VEHICLE_SIZE_REQUEST",
    GET_VEHICLE_SIZE_SUCCESS: "GET_VEHICLE_SIZE_SUCCESS",
    GET_VEHICLE_MODEL_FAILURE: "GET_VEHICLE_SIZE_FAILURE",
};

export const addVehicleModelConstants = {
    ADD_VEHICLE_MODEL_REQUEST: "ADD_VEHICLE_MODEL_REQUEST",
    ADD_VEHICLE_MODEL_SUCCESS: "ADD_VEHICLE_MODEL_SUCCESS",
    ADD_VEHICLE_MODEL_FAILURE: "ADD_VEHICLE_MODEL_FAILURE",
};

export const editVehicleModelConstants = {
    EDIT_VEHICLE_MODEL_REQUEST: "EDIT_VEHICLE_MODEL_REQUEST",
    EDIT_VEHICLE_MODEL_SUCCESS: "EDIT_VEHICLE_MODEL_SUCCESS",
    EDIT_VEHICLE_MODEL_FAILURE: "EDIT_VEHICLE_MODEL_FAILURE",
};

export const deleteVehicleModelConstants = {
    DELETE_VEHICLE_MODEL_REQUEST: "DELETE_VEHICLE_MODEL_REQUEST",
    DELETE_VEHICLE_MODEL_SUCCESS: "DELETE_VEHICLE_MODEL_SUCCESS",
    DELETE_VEHICLE_MODEL_FAILURE: "DELETE_VEHICLE_MODEL_FAILURE",
};


export const getVehicleModelConstants = {
    GET_VEHICLE_MODEL_REQUEST: "GET_VEHICLE_MODEL_REQUEST",
    GET_VEHICLE_MODEL_SUCCESS: "GET_VEHICLE_MODEL_SUCCESS",
    GET_VEHICLE_MODEL_FAILURE: "GET_VEHICLE_MODEL_FAILURE",
};

export const deleteVehicleType = {
    DELETE_VEHICLE_MODEL_REQUEST: "DELETE_VEHICLE_MODEL_REQUEST",
    DELETE_VEHICLE_MODEL_SUCCESS: "DELETE_VEHICLE_MODEL_SUCCESS",
    DELETE_VEHICLE_MODEL_FAILURE: "DELETE_VEHICLE_MODEL_FAILURE",
};

export const editVehicleType = {
    EDIT_VEHICLE_MODEL_REQUEST: "EDIT_VEHICLE_MODEL_REQUEST",
    EDIT_VEHICLE_MODEL_SUCCESS: "EDIT_VEHICLE_MODEL_SUCCESS",
    EDIT_VEHICLE_MODEL_FAILURE: "EDIT_VEHICLE_MODEL_FAILURE",
};

export const editVehicleSizeConstant = {
    EDIT_VEHICLE_SIZE_REQUEST: "EDIT_VEHICLE_SIZE_REQUEST",
    EDIT_VEHICLE_SIZE_SUCCESS: "EDIT_VEHICLE_SIZE_SUCCESS",
    EDIT_VEHICLE_SIZE_FAILURE: "EDIT_VEHICLE_SIZE_FAILURE",
};

export const addVehicleTypeConstant = {
    ADD_VEHICLE_MODEL_REQUEST: "ADD_VEHICLE_MODEL_REQUEST",
    ADD_VEHICLE_MODEL_SUCCESS: "ADD_VEHICLE_MODEL_SUCCESS",
    ADD_VEHICLE_MODEL_FAILURE: "ADD_VEHICLE_MODEL_FAILURE",
};

export const getPickUpsConstant = {
    GET_PICK_UPS_REQUEST: "GET_PICK_UPS_REQUEST",
    GET_PICK_UPS_SUCCESS: "GET_PICK_UPS_SUCCESS",
    GET_PICK_UPS_FAILURE: "GET_PICK_UPS_FAILURE",
};

export const getPickUpConstant = {
    GET_PICK_UP_REQUEST: "GET_PICK_UP_REQUEST",
    GET_PICK_UP_SUCCESS: "GET_PICK_UP_SUCCESS",
    GET_PICK_UP_FAILURE: "GET_PICK_UP_FAILURE",
};

export const confirmPickUpConstant = {
    CONFIRM_PICK_UP_REQUEST: "CONFIRM_PICK_UP_REQUEST",
    CONFIRM_PICK_UP_SUCCESS: "CONFIRM_PICK_UP_SUCCESS",
    CONFIRM_PICK_UP_FAILURE: "CONFIRM_PICK_UP_FAILURE",
};

export const getHubsConstants = {
    GET_HUBS_REQUEST: "GET_HUBS_REQUEST",
    GET_HUBS_SUCCESS: "GET_HUBS_SUCCESS",
    GET_HUBS_FAILURE: "GET_HUBS_FAILURE"
}

export const driversConstants = {
    GET_DRIVERS_REQUEST: "GET_DRIVERS_REQUEST",
    GET_DRIVERS_SUCCESS: "GET_DRIVERS_SUCCESS",
    GET_DRIVERS_FAILURE: "GET_DRIVERS_FAILURE",

    POST_RIDER_REQUEST: "POST_RIDER_REQUEST",
    POST_RIDER_SUCCESS: "POST_RIDER_SUCCESS",
    POST_RIDER_FAILURE: "POST_RIDER_FAILURE"
}