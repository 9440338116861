import { Navigate, useLocation } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import { registerAsAdminConstants, signinAsAdminConstants } from "./constants";

export const signupAsAdmin = (newAdmin) => {
    console.log("newAdmin", newAdmin);
    return async (dispatch) => {
        try {
            dispatch({
                type: registerAsAdminConstants.REGISTER_AS_ADMIN_REQUEST,
            });
            const res = await axiosInstance.post("/Account/registerasAdmin", {
                ...newAdmin,
            });
            console.log("res", res);
            if (res.status === 200) {
                dispatch({
                    type: registerAsAdminConstants.REGISTER_AS_ADMIN_SUCCESS,
                });
            }
        } catch (error) {
            console.log("error", error);
            dispatch({
                type: registerAsAdminConstants.REGISTER_AS_ADMIN_FAILURE,
                payload: { message: error.message },
            });
        }
    };
};

export const signinAsAdmin = (admin) => {
    console.log("admin", admin);

    return async (dispatch) => {
        try {
            dispatch({
                type: signinAsAdminConstants.SIGNIN_AS_ADMIN_REQUEST,
            });
            const res = await axiosInstance.post("/Account/token", {
                ...admin,
            });
            console.log("res", res);
            if (res.status === 200) {
                const { data } = res;
                const { token } = data;
                localStorage.setItem("token", token);

                dispatch({
                    type: signinAsAdminConstants.SIGNIN_AS_ADMIN_SUCCESS,
                    payload: { data },
                });
            }
        } catch (error) {
            console.log("error", error);
            dispatch({
                type: signinAsAdminConstants.SIGNIN_AS_ADMIN_FAILURE,
                payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

export const isUserLoggedIn = () => {
    return async (dispatch) => {
        const token = localStorage.getItem("token");
        // const authenticate = localStorage.getItem("authenticate");
        if (!token) {
            return <Navigate to="/signin" />;
        }
    };
};

export const signout = () => {
    localStorage.clear();
    localStorage.removeItem("token");
};
