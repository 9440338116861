import { getPickUpsConstant, getPickUpConstant, getHubsConstants, confirmPickUpConstant, driversConstants } from "../actions/constants";

const initState = {
    pickUps: [],
    hubs: [],
    status: 'idle',
    cStatus: 'idle',
    posting: 'idle',
    error: {},
};

export const pickUpReducer = (state = initState, action) => {
    switch (action.type) {
        case getPickUpsConstant.GET_PICK_UPS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case getPickUpsConstant.GET_PICK_UPS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                pickUps: action.payload.pickUps,
            });

        case getPickUpConstant.GET_PICK_UP_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case getPickUpConstant.GET_PICK_UP_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });

        case getHubsConstants.GET_HUBS_REQUEST:
            return (state = { 
                ...state
                });
        case getHubsConstants.GET_HUBS_SUCCESS:
            return (state = {
                ...state,
                hubs: action.payload.hubs,
            });

        case confirmPickUpConstant.CONFIRM_PICK_UP_REQUEST:
            return (state = { 
                ...state,
                cStatus: 'loading'
                });
        case confirmPickUpConstant.CONFIRM_PICK_UP_SUCCESS:
            return (state = {
                ...state,
                cStatus: 'idle'
            });
        case driversConstants.POST_RIDER_REQUEST:
            return (state = {
                ...state,
                posting: 'loading'
            })
        case driversConstants.POST_RIDER_SUCCESS:
            return (state = {
                ...state,
                posting: 'idle'
            })

        default:
            return { ...state };
    }
};
