import {
    newRidersConstants,
    verifiedRidersConstants,
    approvedRidersConstants,
    customerConstants,
    verifyRiderConstants,
    approveRiderConstants,
    importsConstants
} from "../actions/constants";

const initState = {
    newRiders: [],
    verifiedRiders: [],
    approvedRiders: [],
    customers: [],
    error: {},
};

export const ridersReducer = (state = initState, action) => {
    switch (action.type) {
        case newRidersConstants.GET_NEW_RIDERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case newRidersConstants.GET_NEW_RIDERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                newRiders: action.payload.newRiders,
            });
        case verifiedRidersConstants.GET_VERIFIED_RIDERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case verifiedRidersConstants.GET_VERIFIED_RIDERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                verifiedRiders: action.payload.verifiedRiders,
            });

        case approvedRidersConstants.GET_APPROVED_RIDERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case approvedRidersConstants.GET_APPROVED_RIDERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                approvedRiders: action.payload.approvedRiders,
            });
        case verifyRiderConstants.VERIFY_RIDER_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case verifyRiderConstants.VERIFY_RIDER_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });

        case approveRiderConstants.APPROVE_RIDER_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case approveRiderConstants.APPROVE_RIDER_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });

        case importsConstants.IMPORT_RIDER_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case importsConstants.IMPORT_RIDER_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });
        case importsConstants.IMPORT_RIDER_ERROR:
            return (state = {
                ...state,
                status: 'idle'
            });
        case customerConstants.GET_CUSTOMERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
            });
        case customerConstants.GET_CUSTOMERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                customers: action.payload.customers,
            });
        default:
            return { ...state };
    }
};
