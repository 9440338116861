import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TablePagination,
    TableRow
} from "@mui/material";
import { getIncidents, updateIncident } from "../../state/actions";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const IncidentReport = () => {
    const dispatch = useDispatch()
    const incidents = useSelector((state) => state.trips.incidents);
    const isLoading = useSelector((state) => state.trips.status);
    const isResolving = useSelector((state) => state.trips.resolve);
    useEffect(() => {
        dispatch(getIncidents());
        console.log("incidents :>>", incidents)
    }, [dispatch]);

    const [page, setPage] = useState(0);
    const [selectedIncident, setSelectedIncident] = useState({});
    const [openViewDetail, setOpenViewDetail] = useState(false);
    const handlePageChange = useCallback(
        (_, page) => {
            setPage(page);
        },
        [setPage]
    );

    const selectIncident = (incident) => {
        setSelectedIncident(incident);
        setOpenViewDetail(true);
    }

    const handleCloseViewDetail = () => {
        setOpenViewDetail(false)
    }

    const handleIncidentUpdate = () => {
        const data = {
            ...selectedIncident,
            "status": 1
        }
        dispatch(updateIncident(data)).then(() => {
            dispatch(getIncidents());
            setOpenViewDetail(false);
        });
    }

    let rowsPerPage = 10;
    return(
        <>
            <NavBar/>
            <SideBar/>
            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            Incident Reports
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div>
                                        {isLoading === 'loading' ?
                                        <div className="text-center">
                                            <div className="spinner-border text-danger" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <p className="small">Fetching reports...</p>
                                        </div> :
                                        incidents.length > 0 ? (
                                            <Table aria-labelledby="tableTitle">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Date
                                                        </TableCell>
                                                        <TableCell>
                                                            Rider
                                                        </TableCell>
                                                        <TableCell>
                                                            Description
                                                        </TableCell>
                                                        <TableCell>
                                                            Goods Damaged
                                                        </TableCell>
                                                        <TableCell>
                                                            Vehicle Accident
                                                        </TableCell>
                                                        <TableCell>
                                                            Status
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {incidents.length > 0 &&
                                                        incidents.map(
                                                            (incident, index) => (
                                                                <TableRow
                                                                    hover
                                                                    tabindex={
                                                                        -1
                                                                    }
                                                                    key={index}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0.2",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            new Date(incident.createdAt).toDateString()
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0.2",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                       {
                                                                        incident.driver?.rider?.firstName + " " + incident.driver?.rider?.lastName
                                                                       }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0.2",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            incident.description
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0.2",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        { incident.hasDamaged ? "Yes" : "No"}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0.2",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            incident.vehicleAccident ? "Yes" : "No"
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0.2",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            incident.status === 0 ? "Pending" : "Resolved"
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0.2",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        <button
                                                                            className="btn btn-sm btn-primary"
                                                                            onClick={() => {
                                                                                selectIncident(incident)
                                                                            }}
                                                                        >
                                                                            View
                                                                            Details
                                                                        </button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                </TableBody>
                                            </Table>
                                        ) : (
                                        <div className="text-center mx-auto">
                                            <p className="text-Center">
                                            No incident reported currently
                                            </p>
                                        </div>
                                        )}
                                </div>
                                    <div>
                                        <TablePagination
                                            component="div"
                                            count={incidents.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page",
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page",
                                            }}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="orderDetails1"
                className="modal fade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenteredScrollableTitle"
                aria-hidden="true"
            >
                <Dialog
                    open={openViewDetail}
                    onClose={handleCloseViewDetail}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="row d-block">
                                <h5
                                    className="modal-title"
                                    id="exampleModalCenteredScrollableTitle"
                                >
                                    Incident Report
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    onClick={handleCloseViewDetail}
                                    aria-hidden="true"
                                >
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div
                                className=""
                            >
                                <div className="media-body ml-2">
                                    <div className="d-flex justify-content-between">
                                    <p className="mb-0 mt-1"></p>
                                    {selectedIncident?.status === 0
                                        ? <span className="badge badge-sm badge-info">
                                            Pending
                                        </span>
                                        : <span className="badge badge-sm badge-success">
                                            Resolved
                                        </span>
                                    }
                                    </div>
                                </div>
                                <div className="media align-items-start mt-4">
                                    <div className="media-body">
                                        <p className="text mb-0">
                                            Rider
                                        </p>
                                        <span className="font-weight-bold d-block">
                                            {
                                                selectedIncident?.driver?.rider?.firstName + " " + selectedIncident?.driver?.rider?.lastName
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="media align-items-start mt-4">
                                    <div className="media-body">
                                        <p className="text mb-0">
                                            Incident Description
                                        </p>
                                        <span className="font-weight-bold d-block">
                                            {
                                                selectedIncident?.description
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="media align-items-start mt-4">
                                    <div className="media-body">
                                        <p className="text mb-0">
                                            Goods Damaged
                                        </p>
                                        <span className="font-weight-bold d-block">
                                            {
                                                selectedIncident.hasDamaged ? "Yes" : "No"
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="media align-items-start mt-4">
                                    <div className="media-body">
                                        <p className="text mb-0">
                                            Vehicle Accident
                                        </p>
                                        <span className="font-weight-bold d-block">
                                            {
                                                selectedIncident?.vehicleAccident ? "Yes" : "No"
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row-reverse mt-4">
                                <div className=" pt-2">
                                    { isResolving === 'idle' && <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => handleIncidentUpdate() }
                                    >
                                    Resolve
                                    </button> }
                                    { isResolving === 'loading' && <button
                                    type="button"
                                    className="btn btn-success"
                                    disabled
                                    >
                                    Please wait <i className="fas fa-spinner fa-spin"></i>
                                    </button> }
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    )
}

export default IncidentReport;