import axiosInstance from "../../utils/axios";
import fetchData, { fetchData2 } from "../../utils/fetchData";
import {
    orderIncomesConstants,
    partnersIncomesConstants,
    walletConstants,
    settlementConstant
} from "./constants";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const getIncomes = (o) => {
    const {name, start, end} = o;
    // console.log('start :>> ', start);
    return async (dispatch) => {
        dispatch({
            type: orderIncomesConstants.GET_ORDER_INCOMES_REQUEST,
        });
        // const res = await axiosInstance.get(`/Admin/orders/payments?start=2022-09-01&end=2022-09-30`);
        const res = await axiosInstance.get(`/Admin/orders/payments?name=${name}&start=${start}&end=${end}`);
        console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderIncomesConstants.GET_ORDER_INCOMES_SUCCESS,
                payload: { ordersIncome: data },
            });
        } else {
        }
    };
};
export const getSchIncomes = (o) => {
    const {start, end} = o;
    // console.log('start :>> ', start);
    return async (dispatch) => {
        dispatch({
            type: orderIncomesConstants.GET_SCHORDER_INCOMES_REQUEST,
        });
        // const res = await axiosInstance.get(`/Admin/orders/payments?start=2022-09-01&end=2022-09-30`);
        const res = await axiosInstance.get(`/Admin/orders/schpayments?start=${start}&end=${end}`);
        console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: orderIncomesConstants.GET_SCHORDER_INCOMES_SUCCESS,
                payload: { schordersIncome: data },
            });
        } else {
        }
    };
};
export const downloadIncomes = (o) => {
    const {name, start, end} = o;
    return async (dispatch) => {
        dispatch({ type: orderIncomesConstants.GET_EXPORT_INCOME_RESQUEST });
        const res = await axiosInstance.get(`/Admin/orders/exportpayments?name=${name}&start=${start}&end=${end}`, {responseType: 'blob'});

        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: orderIncomesConstants.GET_EXPORT_INCOMES_SUCCESS,
            });
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Income.xlsx';
            a.click();
        }
    };
};

export const getPartnersIncomes = () => {
    return async (dispatch) => {
        dispatch({
            type: partnersIncomesConstants.GET_PARTNERS_INCOMES_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/partner/top");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: partnersIncomesConstants.GET_PARTNERS_INCOMES_SUCCESS,
                payload: { partnersIncomes: data },
            });
        } else {
        }
    };
};

export const getWallet = (w) => {
    const {name, startDate, endDate} = w;
    return async (dispatch) => {
        dispatch({
            type: walletConstants.GET_WALLET_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/wallet/payments?Name=${name}&start=${startDate}&end=${endDate}`)
        console.log("res ", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: walletConstants.GET_WALLET_SUCCESS,
                payload: { walletPayments: data },
            });
        } else {

        }
    };
};

export const downloadWalletReport = (w) => {
    const {name, startDate, endDate} = w;
    return async (dispatch) => {
        dispatch({ type: walletConstants.EXPORT_WALLET_REQUEST });
        const res = await axiosInstance.get(`/Admin/wallet/payments/export?name=${name}&start=${startDate}&end=${endDate}`, {responseType: 'blob'});

        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: walletConstants.EXPORT_WALLET_SUCCESS,
            });
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'WalletReport.xlsx';
            a.click();
        }
        else{
            dispatch({
                type: walletConstants.EXPORT_WALLET_FAILURE,
            });
        }
    };
};
export const downloadWalletBalanceReport = () => {
    return async (dispatch) => {
        dispatch({ type: walletConstants.EXPORT_WALLET_REQUEST });
        const res = await axiosInstance.get(`/Admin/wallet/balance/export`, {responseType: 'blob'});

        console.log("res", res);
        if (res.status === 200) {
            dispatch({
                type: walletConstants.EXPORT_WALLET_SUCCESS,
            });
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'WalletBalanceReport.xlsx';
            a.click();
        }
        else{
            dispatch({
                type: walletConstants.EXPORT_WALLET_FAILURE,
            });
        }
    };
};

export const makeSettlement = () => {
    return async (dispatch) => {
        dispatch({
            type: settlementConstant.ADD_PARTNER_SETTLEMENT_REQUEST,
        });
        const res =  await axiosInstance.post('');
        console.log("res ", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: settlementConstant.ADD_PARTNER_SETTLEMENT_SUCCESS,
            });
            toast.success("Settlement made successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
        }
    }
}
