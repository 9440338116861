import axiosInstance from "./axios";
import wrapPromise from "./wrapPromise";

function fetchData(url) {
    const promise = axiosInstance
        .get(url)
        .then((res) => console.log("res", res))
        .catch((err) => err);

    console.log("promise", promise);
    console.log("wrap Promise(promise)", wrapPromise(promise));
    return wrapPromise(promise);
}

export default fetchData;

export const fetchData2 = (url) => {
    const promise = axiosInstance.get(url).then((res) => res);
    console.log("promise", promise);
    // return promise;
    return wrapPromise(promise);
};
