import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import {
  getApprovedRiders,
  getNewRiders,
  verifyRider
} from "../../state/actions/rider.actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const UnverifiedRider = () => {
  const dispatch = useDispatch();
  const newRiders = useSelector((state) => state.riders.newRiders);
  const isLoading = useSelector((state) => state.riders.status)
  console.log("newRiders", newRiders);

  const [page, setPage] = useState(0);
  const [openViewDetail, setOpenViewDetail] = useState(false);

  const handleCloseViewDetail = () => {
    setOpenViewDetail(false);
  };

  useEffect(() => {
    dispatch(getNewRiders())
  }, [dispatch]);

   const handleRiderVerification = (id) => {
    dispatch(verifyRider(id)).then(() => {
      dispatch(getNewRiders());
      setOpenViewDetail(false);
    })
   }

  const handlePageChange = useCallback(
    (_, page) => {
      setPage(page);
    },
    [setPage]
  );


  const [selectedRider, setSelectedRider] = useState({});
  const selectRider = (rider) => {
    setSelectedRider(rider);
    console.log("selectedRider :>> ", rider);
    setOpenViewDetail(true);
  };

  let rowsPerPage = 10;

  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">New Riders</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    {isLoading === 'loading' ?
                    <div className="text-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p className="small">Getting Riders...</p>
                    </div> :
                    newRiders.length > 0 ? (
                      <Table ariaa-labelledby="tableTitle">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                            {/* <TableCell>Email</TableCell> */}
                            <TableCell>Telephone</TableCell>
                            <TableCell>Verification Status</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {newRiders.length > 0 &&
                            newRiders.map((newRider, index) => (
                              <TableRow hover tabindex={-1} key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 {newRider.firstName} {newRider.lastName}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 { newRider.address }
                                </TableCell>
                                {/* <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 { newRider.email }
                                </TableCell> */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 { newRider.telephone}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                 {/* <riderStatus status={newRider.status} /> */}
                                 { (newRider.status) === 0 && <span className="badge badge-info">New</span> }
                                </TableCell>
                                <TableCell 
                                component="th" 
                                scope="row"
                                sx={{
                                  padding: "0 !important"
                                }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {
                                      selectRider(newRider);
                                    }}
                                  >
                                    View Details
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    ) :
                    <div className="text-center">
                      <p>No New Rider Available</p>
                    </div>
                    }
                  </div>
                  <div>
                    <TablePagination
                      component="div"
                      count={newRiders.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page",
                      }}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Rider Details Pop Up */}

      <Dialog
        open={openViewDetail}
        onClose={handleCloseViewDetail}
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <DialogTitle>Rider Details</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <div className="modalContent">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalCenteredScrollableTitle"
                >
                  Stage 1 Verfication
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                  aria-hidden="true"
                  onClick={handleCloseViewDetail}
                  >×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="alert alert-info" role="alert">
                  <div className="mm-alert-text">
                    Verify personal information and engagement with Rider
                  </div>
                </div>

                <div className="media align-items-start">
                  <img
                    src="assets/images/user/1.jpg"
                    className="img-fluid avatar-rounded avatar-60"
                    alt="user"
                  />
                  <div className="media-body ml-2 row">
                    <div className="col-md-8">
                      <p className="mb-0 mt-1">
                        { selectedRider.firstName } { selectedRider.lastName}
                      </p>
                      <span className="font-weight-bold">
                        {selectedRider.telephone }
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Contact Email</p>
                    <span className="font-weight-bold d-block">
                     { selectedRider.email }
                    </span>
                  </div>
                </div> */}
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Contact Address</p>
                    <span className="font-weight-bold d-block">
                     { selectedRider.address }
                    </span>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <Table aria-labelledby="tableTitle">
                     <TableBody>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              1
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              Driver's License
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <span className="badge badge-info">Pending</span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <a
                              href={`${selectedRider.driverLicenseUrl}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-secondary btn-sm">
                                 Open
                              </a>
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              2
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              LASSRA Card
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <span className="badge badge-info">Pending</span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <a
                              href={`${selectedRider.lassraCardUrl}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-secondary btn-sm">
                                 Open
                              </a>
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell
                           component="th"
                           >
                              3
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              Passport
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <span className="badge badge-info">Pending</span>
                           </TableCell>
                           <TableCell
                           component="th"
                           >
                              <a
                              href={`${selectedRider.passportUrl}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-secondary btn-sm">
                                 Open
                              </a>
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-2">
                    {isLoading === 'idle' && <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => handleRiderVerification(selectedRider.id) }
                    >
                      Verify
                    </button>}
                    {isLoading === 'loading' && <button
                    type="button"
                    className="btn btn-success"
                    disabled
                    >
                      Please wait <i className="fas fa-spinner fa-spin"></i>
                    </button>}
                    <button type="button" className="btn btn-primary mx-3">
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default UnverifiedRider;
