import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TablePagination,
    TableRow
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import {
    getNewPartners,
    verifyPartner,
} from "../../state/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const NewPartner = () => {
    const dispatch = useDispatch();
    const newPartners = useSelector((state) => state.partner.newPartners);
    const isLoading = useSelector((state) => state.partner.status)
    const [page, setPage] = useState(0);
    const [openViewDetail, setOpenViewDetail] = useState(false);

    const handleCloseViewDetail = () => {
        setOpenViewDetail(false);
    };
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        dispatch(getNewPartners())
    }, []);

    const handleChangePage = useCallback(
        (_, page) => {
            setPage(page);
        },
        [setPage]
    );
    const [selectedPartner, setSelectedPartner] = useState({});

    const selectPartner = (partner) => {
        setSelectedPartner(partner);
        console.log("selectedPartner :>> ", partner);
        setOpenViewDetail(true);
    };

    const handlePartnerVerification = (id) => {
        dispatch(verifyPartner(id)).then(() => {
            dispatch(getNewPartners());
            setOpenViewDetail(false);
        });
        // setOpenViewDetail(false);
    };

    let rowsPerPage = 10;
    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            New Partners
                                        </h4>
                                    </div>
                                </div>
                                <Box
                                    // width="110%"
                                    sx={{
                                        backgroundColor: "#fff",
                                        // marginLeft: theme.spacing(3),
                                    }}
                                >
                                    <div>
                                        {isLoading === 'loading' ? 
                                          <div className="text-center">
                                            <div className="spinner-border text-danger" role="status">
                                              <span className="sr-only">Loading...</span>
                                            </div>
                                            <p className="small">Getting New Partners...</p>
                                          </div> :
                                          newPartners.length > 0 ? (
                                            <Table aria-labelledby="tableTitle">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Company Name</TableCell>
                                                        <TableCell>Address</TableCell>
                                                        <TableCell>RC Number</TableCell>
                                                        <TableCell>Telephone</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {newPartners.length > 0 &&
                                                        newPartners.map(
                                                            (
                                                                newPartner,
                                                                index
                                                            ) => (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    key={index}
                                                                    onClick={() => {
                                                                        // selectRow(
                                                                        //     trackOrder
                                                                        // );
                                                                    }}
                                                                    sx={{
                                                                        height: "30 !important",
                                                                        // maxWidth: "50rem",
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            newPartner.companyName
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        // sx={{
                                                                        //     width: "5% !important",
                                                                        //     padding:
                                                                        //         "0 5",
                                                                        //     margin: "0 !important",
                                                                        // }}
                                                                    >
                                                                        {
                                                                            newPartner.address
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            newPartner.rcNumber
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            newPartner.telephone
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    ></TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding:
                                                                                "0 !important",
                                                                        }}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-primary"
                                                                            onClick={() => {
                                                                                selectPartner(
                                                                                    newPartner
                                                                                );
                                                                            }}
                                                                        >
                                                                            View
                                                                            Details
                                                                        </button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                </TableBody>
                                            </Table>
                                          ): 
                                          <div className="text-center d-flex justify-content-center align-items-center">
                                            <p>No New Partner available currently</p>
                                          </div>
                                        }
                                    </div>
                                    <div>
                                        <TablePagination
                                            component="div"
                                            count={newPartners.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page",
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page",
                                            }}
                                            onPageChange={handleChangePage}
                                            // classes={{
                                            //     select: classes.dNone,
                                            //     selectIcon: classes.dNone,
                                            //     actions:
                                            //         targets.length > 0
                                            //             ? classes.dBlock
                                            //             : classes.dNone,
                                            //     caption:
                                            //         targets.length > 0
                                            //             ? classes.dBlock
                                            //             : classes.dNone,
                                            // }}
                                            labelRowsPerPage=""
                                        />
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* dialog */}

            <Dialog
                open={openViewDetail}
                onClose={handleCloseViewDetail}
                fullWidth={true}
                maxWidth="sm"
            >
                {/* <DialogTitle>Subscribe</DialogTitle> */}
                <DialogContent>
                    <DialogContentText>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5
                                    className="modal-title"
                                    id="exampleModalCenteredScrollableTitle"
                                >
                                    Partner details
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        onClick={handleCloseViewDetail}
                                    >
                                        ×
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="media align-items-start">
                                    {/* <img
                                        src="assets/images/user/1.jpg"
                                        className="img-fluid avatar-rounded avatar-60"
                                        alt="user"
                                    /> */}
                                    <div className="bg-primary-light avatar-rounded p-2">
                                        <svg
                                            className="svg-icon svg-primary text-primary"
                                            width="42"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 10V3L4 14h7v7l9-11h-7z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="media-body ml-2 row">
                                        <div className="col-md-12">
                                            <p className="mb-0 mt-1">
                                                {selectedPartner.companyName}
                                            </p>
                                            <span className="font-weight-bold">
                                                {selectedPartner.rcNumber}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="media align-items-start mt-2">
                                    <div className="media-body">
                                        <p className="text mb-0">
                                            Contact Name
                                        </p>
                                        <span className="font-weight-bold d-block">
                                            {selectedPartner.contactName}
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="media align-items-start mt-4">
                                    <div className="media-body">
                                        <p className="text mb-0">
                                            Contact Email
                                        </p>
                                        <span className="font-weight-bold d-block">
                                            {selectedPartner.email}
                                        </span>
                                    </div>
                                </div> */}
                                <div className="media align-items-start mt-4">
                                    <div className="media-body">
                                        <p className="text mb-0">
                                            Contact Phone
                                        </p>
                                        <span className="font-weight-bold d-block">
                                            {selectedPartner.telephone}
                                        </span>
                                    </div>
                                </div>
                                <div className="media align-items-start mt-4">
                                    <div className="media-body">
                                        <p className="text mb-0">
                                            Company Address
                                        </p>
                                        <span className="font-weight-bold d-block">
                                            {selectedPartner.address}
                                        </span>
                                    </div>
                                </div>
                                <div className="media align-items-start mt-4">
                                    <Table aria-labelledby="tableTitle">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th">
                                                    1
                                                </TableCell>
                                                <TableCell component="th">
                                                    TIN
                                                </TableCell>
                                                <TableCell component="th">
                                                    <span className="badge badge-info">
                                                        Pending
                                                    </span>
                                                </TableCell>
                                                <TableCell component="th">
                                                    <a
                                                        href={`${selectedPartner.tinCertificateUrl}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="btn btn-secondary btn-sm"
                                                    >
                                                        Open
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th">
                                                    2
                                                </TableCell>
                                                <TableCell component="th">
                                                    CAC
                                                </TableCell>
                                                <TableCell component="th">
                                                    <span className="badge badge-info">
                                                        Pending
                                                    </span>
                                                </TableCell>
                                                <TableCell component="th">
                                                    <a
                                                        href={`${selectedPartner.cacDocumentUrl}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="btn btn-secondary btn-sm"
                                                    >
                                                        Open
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th">
                                                    3
                                                </TableCell>
                                                <TableCell component="th">
                                                    TAX
                                                </TableCell>
                                                <TableCell component="th">
                                                    <span className="badge badge-info">
                                                        Pending
                                                    </span>
                                                </TableCell>
                                                <TableCell component="th">
                                                    <a
                                                        href={`${selectedPartner.taxFormUrl}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="btn btn-secondary btn-sm"
                                                    >
                                                        Open
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 pt-2">
                                        {isLoading === 'idle' &&
                                            <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() =>
                                                handlePartnerVerification(
                                                    selectedPartner.id
                                                )
                                            }
                                        >
                                            Verify
                                        </button>}
                                        {isLoading === 'loading' &&
                                            <button
                                            type="button"
                                            className="btn btn-success"
                                            disabled
                                        >
                                            Verifying <i className="fas fa-spinner fa-spin"></i>
                                        </button>}
                                        <button
                                            type="button"
                                            className="btn btn-primary mx-3"
                                        >
                                            Reject
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
            </Dialog>
        </>
    );
};

export default NewPartner;
