import {
  Box,
  Dialog,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TableContainer,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { styled, alpha } from "@mui/material/styles";
import { getTrips } from "../../state/actions";
import { visuallyHidden } from "@mui/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Close } from "@mui/icons-material";
import moment from "moment";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function getNestedProperty(obj, path) {
  const pathArray = path.split(".");
  return pathArray.reduce(
    (acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
    obj
  );
}

function descendingComparator(a, b, orderBy) {
  let valueA = getNestedProperty(a, orderBy);
  let valueB = getNestedProperty(b, orderBy);

  if (typeof valueA === "string" && typeof valueB === "string") {
    valueA = valueA.toUpperCase();
    valueB = valueB.toUpperCase();
  }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "order.orderNo",
    numeric: false,
    disablePadding: true,
    label: "ORDER NO",
  },
  {
    id: "startAddress",
    numeric: false,
    disablePadding: false,
    label: "PICKUP LOCATION",
  },
  {
    id: "endAddress",
    numeric: false,
    disablePadding: false,
    label: "DESTINATION",
  },
  { id: "status", numeric: false, disablePadding: false, label: "STATUS" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const TripOverview = () => {
  const dispatch = useDispatch();
  const trips = useSelector((state) => state.trips.allTrips);
  const isLoading = useSelector((state) => state.trips.status);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState(null);
  const [filterMode, setFilterMode] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openViewDetail, setOpenViewDetail] = useState(false);
  const [destination, setDestination] = useState({});
  useEffect(() => {
    dispatch(getTrips());
  }, [dispatch]);

  const handleCloseViewDetail = () => {
    setOpenViewDetail(false);
  };

  const filteredTrips = useCallback(() => {
    if (!searchParam && statusFilter === null) {
      return trips;
    }

    return trips.filter((item) => {
      // Convert the search parameter to lowercase for case-insensitive search
      const searchTerm = searchParam.toLowerCase();

      const containsSearchTerm = Object.values(item).some((value) => {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some((nestedValue) =>
            String(nestedValue).toLowerCase().includes(searchTerm)
          );
        }
        return String(value).toLowerCase().includes(searchTerm);
      });

      // Status Filter
      const statusMatches =
        statusFilter === null || item.status === statusFilter;

      return containsSearchTerm && statusMatches;
    });
  }, [trips, searchParam, statusFilter]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - trips.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = (e) => {
    const { value } = e.target;
    setSearchParam(value);
  };

  const closeFilter = () => {
    setFilterMode(null);
    setStatusFilter(null);
  };

  const [selectedTrip, setSelectedTrip] = useState({});

  const selectTrip = (trip) => {
    setSelectedTrip(trip);
    console.log("selectedTrip :>>", trip);
    const { destinationId, order } = trip;
    const { destinationPoints } = order;
    const dest = destinationPoints.find(
      (destPt) => destinationId === destPt.id
    );
    setDestination(dest);
    setOpenViewDetail(true);
  };

  function tripStatus(status) {
    switch (status) {
      case 0:
        return <span className="badge badge-info">Started</span>;
      case 9:
        return <span className="badge badge-warning">Paused</span>;
      case 10:
        return <span className="badge badge-success">Completed</span>;
      default:
        return <span className="badge badge-info">Started</span>;
    }
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("orderNo");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredTrips(), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredTrips, order, orderBy, page, rowsPerPage]
  );

  const duration = selectedTrip.actualDuration / 60;

  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Trips Overview</h4>
                  </div>
                  <div>
                    <FilterListIcon
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                    />
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => setFilterMode(1)} disableRipple>
                        Filter By Status
                      </MenuItem>
                    </StyledMenu>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    {isLoading === "loading" ? (
                      <div className="text-center">
                        <div
                          className="spinner-border text-danger"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <p className="small">Fetching Trips...</p>
                      </div>
                    ) : trips.length > 0 ? (
                      <>
                        <div className="d-flex flex-row-reverse">
                          <div className="col-4">
                            {filterMode === 1 && (
                              <>
                                <Close
                                  style={{ float: "right" }}
                                  onClick={() => closeFilter()}
                                />
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Status
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={statusFilter}
                                    label="Age"
                                    onChange={(e) =>
                                      setStatusFilter(e.target.value)
                                    }
                                  >
                                    <MenuItem value={null}>All</MenuItem>
                                    <MenuItem value={0}>Started</MenuItem>
                                    <MenuItem value={9}>Paused</MenuItem>
                                    <MenuItem value={10}>Completed</MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}
                          </div>
                        </div>
                        <Box>
                          <div className="form-group">
                            <label className="form-label">Search</label>
                            <input
                              type="text"
                              onChange={search}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </Box>
                        <TableContainer>
                          <Table aria-labelledby="tableTitle">
                            <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              rowCount={rowsPerPage.length}
                            />
                            <TableBody>
                              {visibleRows?.map((trip, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                  >
                                    {trip?.order?.orderNo}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                  >
                                    {trip.startAddress}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                  >
                                    {trip.endAddress}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                  >
                                    {tripStatus(trip.status)}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0.2",
                                      margin: "0 !important",
                                    }}
                                  >
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        selectTrip(trip);
                                      }}
                                    >
                                      View
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))}
                              {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                  <TableCell colSpan={6} />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : null}
                  </div>
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      component="div"
                      count={filteredTrips().length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="orderDetails1"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenteredScrollableTitle"
        aria-hidden="true"
      >
        <Dialog
          open={openViewDetail}
          onClose={handleCloseViewDetail}
          fullWidth={true}
          maxWidth="sm"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="row d-block">
                <h5
                  className="modal-title"
                  id="exampleModalCenteredScrollableTitle"
                >
                  Trip details
                </h5>
                <ul
                  className="nav nav-tabs mt-2 mb-0"
                  id="myTab-1"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="home-tab"
                      data-toggle="tab"
                      href="#order"
                      role="tab"
                      aria-controls="order"
                      aria-selected="true"
                    >
                      Order
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#recipient"
                      role="tab"
                      aria-controls="recipient"
                      aria-selected="false"
                    >
                      Destination
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#rider"
                      role="tab"
                      aria-controls="rider"
                      aria-selected="false"
                    >
                      Rider
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="trip-tab"
                      data-toggle="tab"
                      href="#tripDetails"
                      role="tab"
                      aria-controls="trip"
                      aria-selected="false"
                    >
                      Trip
                    </a>
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span onClick={handleCloseViewDetail} aria-hidden="true">
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="myTabContent-2">
                <div
                  className="tab-pane fade show"
                  id="order"
                  role="tabpanel"
                  aria-labelledby="order-tab"
                >
                  <div className="media align-items-start">
                    <img
                      src="assets/images/user/delivery-box.png"
                      className="img-fluid avatar-rounded avatar-65"
                      alt="user"
                    />
                    <div className="media-body ml-2">
                      <div className="d-flex justify-content-between">
                        <p className="mb-0 mt-1">
                          {selectedTrip &&
                            selectedTrip.order &&
                            selectedTrip.order.orderNo}
                        </p>
                      </div>
                      <span className="font-weight-bold">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.vehicleType &&
                          selectedTrip.order.vehicleType.name}
                        &nbsp; (
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.vehicleSize &&
                          selectedTrip.order.vehicleSize.name}
                        )
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Pickup Location</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.pickup}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Pickup Date</p>
                      <span className="font-weight-bold d-block">
                        {/* {
                                                    format(new Date(selectedTrip?.order?.orderDate), 'yyyy-MM-dd HH:mm:ss')
                                                } */}
                        {moment(selectedTrip?.order?.orderDate).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </span>
                    </div>
                  </div>

                  {/* <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <div className="row mb-3">
                        <div className="col-md-3 bordered">
                          <a href="#" target="_blank">
                            <img
                              src="assets/images/Orders/gold-watches.jpg"
                              alt="watch"
                              width="80"
                              height="80"
                            />
                          </a>
                        </div>
                        <div className="col-md-3">
                          <a href="#">
                            <img
                              src="assets/images/Orders/necklace.jpg"
                              alt="watch"
                              width="80"
                              height="80"
                            />
                          </a>
                        </div>
                        <div className="col-md-3">
                          <a href="#">
                            <img
                              src="assets/images/Orders/shoes.jpg"
                              alt="watch"
                              width="80"
                              height="80"
                            />
                          </a>
                        </div>
                        <div className="col-md-3">
                          <a href="#">
                            <img
                              src="assets/images/Orders/necklace2.jpg"
                              alt="watch"
                              width="80"
                              height="80"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Estimated Value</p>
                      <span className="font-weight-bold d-block">
                       
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Estimated Distance</p>
                      <span className="font-weight-bold d-block">
                        
                      </span>
                    </div>
                  </div> */}
                  <hr className="font-weight-bold" />
                  <h6>Customer Information</h6>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Customer Name</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.customer &&
                          selectedTrip.order.customer.firstName}
                        &nbsp;
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.customer &&
                          selectedTrip.order.customer.lastName}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Customer Location</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.customer &&
                          selectedTrip.order.customer.address}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Customer Phone Number</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.customer &&
                          selectedTrip.order.customer.phoneNumber}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="recipient"
                  role="tabpanel"
                  aria-labelledby="recipient-tab"
                >
                  <div className="media align-items-start mt-2">
                    <div className="media-body">
                      <p className="text mb-0">Recipient Name</p>
                      <span className="font-weight-bold d-block">
                        {destination.recipientName}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Recipient Phone Number</p>
                      <span className="font-weight-bold d-block">
                        {destination.recipientPhone}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Destination</p>
                      <span className="font-weight-bold d-block">
                        {destination.destination}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Description</p>
                      <span className="font-weight-bold d-block">
                        {destination.description}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Estimated Distance</p>
                      <span className="font-weight-bold d-block">
                        {destination.estimatedDistance / 1000}km
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Estimated Time</p>
                      <span className="font-weight-bold d-block">
                        {Math.floor(destination.estimatedTime / 60)}mins
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show"
                  id="rider"
                  role="tabpanel"
                  aria-labelledby="rider-tab"
                >
                  <div className="media align-items-start mt-2">
                    <div className="media-body">
                      <p className="text mb-0">Name</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.orderRider &&
                          selectedTrip.order.orderRider.driver &&
                          selectedTrip.order.orderRider.driver.rider &&
                          selectedTrip.order.orderRider.driver.rider.firstName}
                        &nbsp;
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.orderRider &&
                          selectedTrip.order.orderRider.driver &&
                          selectedTrip.order.orderRider.driver.rider &&
                          selectedTrip.order.orderRider.driver.rider.lastName}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Phone Number</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.orderRider &&
                          selectedTrip.order.orderRider.driver &&
                          selectedTrip.order.orderRider.driver.rider &&
                          selectedTrip.order.orderRider.driver.rider.telephone}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Vehicle Name</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.orderRider &&
                          selectedTrip.order.orderRider.driver &&
                          selectedTrip.order.orderRider.driver.vehicle &&
                          selectedTrip.order.orderRider.driver.vehicle
                            .vehicleBrand &&
                          selectedTrip.order.orderRider.driver.vehicle
                            .vehicleBrand.name}
                        &nbsp;
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.orderRider &&
                          selectedTrip.order.orderRider.driver &&
                          selectedTrip.order.orderRider.driver.vehicle &&
                          selectedTrip.order.orderRider.driver.vehicle.model &&
                          selectedTrip.order.orderRider.driver.vehicle.model
                            .name}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Vehicle Number</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.orderRider &&
                          selectedTrip.order.orderRider.driver &&
                          selectedTrip.order.orderRider.driver.vehicle &&
                          selectedTrip.order.orderRider.driver.vehicle.regNo}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Company Name</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip &&
                          selectedTrip.order &&
                          selectedTrip.order.orderRider &&
                          selectedTrip.order.orderRider.driver &&
                          selectedTrip.order.orderRider.driver.rider &&
                          selectedTrip.order.orderRider.driver.rider.partner &&
                          selectedTrip.order.orderRider.driver.rider.partner
                            .companyName}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="tripDetails"
                  role="tabpanel"
                  aria-labelledby="trip-tab"
                >
                  <div className="media align-items-start mt-2">
                    <div className="media-body p-0">
                      <div className="d-flex justify-content-between">
                        <p className="text mb-0">Start Address</p>
                        {/* <span className="btn-group btn-group-toggle btn-group-edges">
                                                    {trackTrip(
                                                        selectedTrip.status
                                                    )}
                                                </span> */}
                      </div>
                      <span className="font-weight-bold d-block">
                        {selectedTrip.startAddress}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Destination</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip.endAddress}
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Distance</p>
                      <span className="font-weight-bold d-block">
                        {selectedTrip.actualDistance / 1000}km
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Time</p>
                      <span className="font-weight-bold d-block">
                        {Math.ceil(duration)}mins
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Trip Status</p>
                      <span className="font-weight-bold d-block">
                        {tripStatus(selectedTrip.status)}
                      </span>
                    </div>
                  </div>
                  {/* <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Current Location</p>
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Time</th>
                              <th scope="col">Location</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">08:15 AM</td>
                              <td>T.O.S Benson Rd, Ikorodu</td>
                            </tr>
                            <tr>
                              <td scope="row">08:35 AM</td>
                              <td>Haruna, Ikorodu</td>
                            </tr>
                            <tr>
                              <td scope="row">09:10 AM</td>
                              <td>Asolo, Ikrodu</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>  */}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default TripOverview;
