import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
    // const lightModeNavBrand=()=>{
    //     document.getElementById('')
    // }
    // const darkModeNavBrand=()=>{

    // }
    return (
        <>
            <div className="mm-top-navbar">
                <div className="mm-navbar-custom">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="mm-navbar-logo d-flex align-items-center justify-content-between">
                            <i className="ri-menu-line wrapper-menu"></i>
                            <Link to="/" className="header-logo">
                                <img
                                    id="light-mode-brand"
                                    src="assets/images/Redspeed-black.png"
                                    className="img-fluid rounded-normal"
                                    alt="logo"
                                />
                                {/* <img id="dark-mode-brand" src="assets/images/Redspeed-black.png" className="img-fluid rounded-normal" alt="logo"/> */}
                            </Link>
                        </div>
                        <div className="m-auto"></div>
                        <div className="ms-auto d-flex align-items-center">
                            {/* <div className="change-mode">
                                <div className="custom-control custom-switch custom-switch-icon custom-control-inline">
                                    <div className="custom-switch-inner">
                                        <p className="mb-0"> </p>
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="dark-mode"
                                            data-active="true"
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="dark-mode"
                                            data-mode="toggle"
                                        >
                                            <span className="switch-icon-left">
                                                <svg
                                                    className="svg-icon"
                                                    id="h-moon"
                                                    height="20"
                                                    width="20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                                                    />
                                                </svg>
                                            </span>
                                            <span className="switch-icon-right">
                                                <svg
                                                    className="svg-icon"
                                                    id="h-sun"
                                                    height="20"
                                                    width="20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                                                    />
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-label="Toggle navigation"
                            >
                                <i className="ri-menu-3-line"></i>
                            </button>
                            <div
                                className="collapse navbar-collapse ms-auto"
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav ms-auto navbar-list align-items-center">
                                    <li className="nav-item nav-icon search-content">
                                        <a
                                            href="#"
                                            className="search-toggle rounded"
                                            id="dropdownSearch"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <svg
                                                className="svg-icon text-primary"
                                                id="h-suns"
                                                height="20"
                                                width="20"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                />
                                            </svg>
                                        </a>
                                        <div
                                            className="mm-search-bar mm-sub-dropdown dropdown-menu"
                                            aria-labelledby="dropdownSearch"
                                        >
                                            <form
                                                action="#"
                                                className="searchbox p-2"
                                            >
                                                <div className="form-group mb-0 position-relative">
                                                    <input
                                                        type="text"
                                                        className="text search-input font-size-12"
                                                        placeholder="type here to search..."
                                                    />
                                                    <a
                                                        href="#"
                                                        className="search-link"
                                                    >
                                                        <i className="fa fa-search"></i>
                                                    </a>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                    <li className="nav-item nav-icon dropdown">
                                        <a
                                            href="#"
                                            className="search-toggle dropdown-toggle"
                                            id="dropdownMenuButton2"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <svg
                                                className="svg-icon  text-primary feather feather-mail"
                                                id="mm-mail-2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                                <polyline points="22,6 12,13 2,6"></polyline>
                                            </svg>
                                            <span className="bg-primary"></span>
                                        </a>
                                        <div
                                            className="mm-sub-dropdown dropdown-menu"
                                            aria-labelledby="dropdownMenuButton2"
                                        >
                                            <div className="card shadow-none m-0 border-0">
                                                <div className="card-body p-0 ">
                                                    <div className="cust-title p-3">
                                                        <h5 className="mb-0">
                                                            All Messages
                                                        </h5>
                                                    </div>
                                                    <div className="p-3">
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/1.jpg"
                                                                        alt="01"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0">
                                                                        Barry
                                                                        Emma
                                                                        Watson{" "}
                                                                        <small className="badge badge-success float-right">
                                                                            New
                                                                        </small>
                                                                    </h6>
                                                                    <small className="float-left font-size-12">
                                                                        12:00 PM
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/2.jpg"
                                                                        alt="02"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0 ">
                                                                        Lorem
                                                                        Ipsum
                                                                        Watson
                                                                    </h6>
                                                                    <small className="float-left font-size-12">
                                                                        20 Apr
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/3.jpg"
                                                                        alt="03"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0 ">
                                                                        Why do
                                                                        we use
                                                                        it?{" "}
                                                                        <small className="badge badge-success float-right">
                                                                            New
                                                                        </small>
                                                                    </h6>
                                                                    <small className="float-left font-size-12">
                                                                        1:24 PM
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/4.jpg"
                                                                        alt="04"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0">
                                                                        Variations
                                                                        Passages{" "}
                                                                        <small className="badge badge-success float-right">
                                                                            New
                                                                        </small>
                                                                    </h6>
                                                                    <small className="float-left font-size-12">
                                                                        5:45 PM
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/5.jpg"
                                                                        alt="05"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0 ">
                                                                        Lorem
                                                                        Ipsum
                                                                        generators
                                                                    </h6>
                                                                    <small className="float-left font-size-12">
                                                                        1 day
                                                                        ago
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <a
                                                        className="d-flex justify-content-center p-2 card-footer"
                                                        href="#"
                                                        role="button"
                                                    >
                                                        View All
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item nav-icon dropdown">
                                        <a
                                            href="#"
                                            className="search-toggle dropdown-toggle"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <svg
                                                className="svg-icon text-primary feather feather-bell"
                                                id="mm-bell-2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                            </svg>
                                            <span className="bg-primary "></span>
                                        </a>
                                        <div
                                            className="mm-sub-dropdown dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <div className="card shadow-none m-0 border-0">
                                                <div className="card-body p-0 ">
                                                    <div className="cust-title p-3">
                                                        <h5 className="mb-0">
                                                            All Notifications
                                                        </h5>
                                                    </div>
                                                    <div className="p-3">
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/1.jpg"
                                                                        alt="01"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0">
                                                                        Emma
                                                                        Watson
                                                                        Barry{" "}
                                                                        <small className="badge badge-success float-right">
                                                                            New
                                                                        </small>
                                                                    </h6>
                                                                    <p className="mb-0">
                                                                        95 MB
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/2.jpg"
                                                                        alt="02"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0 ">
                                                                        New
                                                                        customer
                                                                        is join
                                                                    </h6>
                                                                    <p className="mb-0">
                                                                        Cyst
                                                                        Barry
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/3.jpg"
                                                                        alt="03"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0 ">
                                                                        Two
                                                                        customer
                                                                        is left
                                                                    </h6>
                                                                    <p className="mb-0">
                                                                        Cyst
                                                                        Barry
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="mm-sub-card"
                                                        >
                                                            <div className="media align-items-center">
                                                                <div className="">
                                                                    <img
                                                                        className="avatar-40 rounded-small"
                                                                        src="assets/images/user/4.jpg"
                                                                        alt="04"
                                                                    />
                                                                </div>
                                                                <div className="media-body ml-3">
                                                                    <h6 className="mb-0 ">
                                                                        New Mail
                                                                        from
                                                                        Fenny{" "}
                                                                        <small className="badge badge-success float-right">
                                                                            New
                                                                        </small>
                                                                    </h6>
                                                                    <p className="mb-0">
                                                                        Cyst
                                                                        Barry
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <a
                                                        className="d-flex justify-content-center p-2 card-footer"
                                                        href="#"
                                                        role="button"
                                                    >
                                                        View All
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item nav-icon dropdown full-screen">
                                        <a
                                            href="#"
                                            className="nav-item nav-icon dropdown"
                                            id="btnFullscreen"
                                        >
                                            <i className="max">
                                                <svg
                                                    className="svg-icon  text-primary feather feather-maximize"
                                                    id="d-3-max"
                                                    width="20"
                                                    height="20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                                                </svg>
                                            </i>
                                            <i className="min d-none">
                                                <svg
                                                    className="svg-icon  text-primary feather feather-minimize"
                                                    id="d-3-min"
                                                    width="20"
                                                    height="20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path>
                                                </svg>
                                            </i>
                                        </a>
                                    </li>
                                    <li className="nav-item nav-icon dropdown">
                                        {/* <a href="/profile" className="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                        <Link
                                            to="/profile"
                                            className="nav-item nav-icon dropdown-toggle pr-0 search-toggle"
                                        >
                                            <img
                                                src="assets/images/user/1.jpg"
                                                className="img-fluid avatar-rounded"
                                                alt="user"
                                            />
                                        </Link>
                                        {/* Drop Down Menu in Nav bar */}
                                        {/* <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
<li className="dropdown-item d-flex svg-icon">
<svg className="svg-icon mr-0 text-primary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
<a href="https://meetmighty.com/dashboards/simpled/html/app/user-profile.html">My Profile</a>
</li>
<li className="dropdown-item d-flex svg-icon">
<svg className="svg-icon mr-0 text-primary" id="h-02-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
</svg>
<a href="https://meetmighty.com/dashboards/simpled/html/app/user-profile-edit.html">Edit Profile</a>
</li>
<li className="dropdown-item d-flex svg-icon">
<svg className="svg-icon mr-0 text-primary" id="h-03-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
<a href="https://meetmighty.com/dashboards/simpled/html/app/user-account-setting.html">Account Settings</a>
</li>
<li className="dropdown-item d-flex svg-icon">
<svg className="svg-icon mr-0 text-primary" id="h-04-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
</svg>
 <a href="https://meetmighty.com/dashboards/simpled/html/app/user-privacy-setting.html">Privacy Settings</a>
</li>
<li className="dropdown-item  d-flex svg-icon border-top">
<svg className="svg-icon mr-0 text-primary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
</svg>
<a href="auth-sign-in.html">Logout</a>
</li>
</ul> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
};

export default NavBar;
