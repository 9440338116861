import {
  Box,
  Dialog,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux";
import {
  getIncomes,
  getSchIncomes,
  downloadIncomes,
} from "../../state/actions";
import { getCustomers } from "../../state/actions/rider.actions";
import { format } from "date-fns";
import Select from "react-select";
import { styled, alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
moment().format();

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function getNestedProperty(obj, path) {
  const pathArray = path.split('.');
  return pathArray.reduce((acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined), obj);
}

function descendingComparator(a, b, orderBy) {
  let valueA = getNestedProperty(a, orderBy);
  let valueB = getNestedProperty(b, orderBy);

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    valueA = valueA.toUpperCase();
    valueB = valueB.toUpperCase();
  }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "paymentDate",
    numeric: false,
    disablePadding: true,
    label: "PAYMENT DATE",
  },
  {
    id: "order.orderNo",
    numeric: false,
    disablePadding: false,
    label: "WAYBILL NO",
  },
  {
    id: "order.customer.firstName",
    numeric: false,
    disablePadding: false,
    label: "CUSTOMER",
  },
  {
    id: "order.customer.companyName",
    numeric: false,
    disablePadding: false,
    label: "COMPANY NAME",
  },
  {
    id: "subAmount",
    numeric: false,
    disablePadding: false,
    label: "DELIVERY AMOUNT",
  },
  { id: "tax", numeric: false, disablePadding: false, label: "VAT" },
  {
    id: "rsAmount",
    numeric: false,
    disablePadding: false,
    label: "RS AMOUNT",
  },
  {
    id: "partnerAmount",
    numeric: false,
    disablePadding: false,
    label: "PARTNER AMOUNT",
  },
  { id: "amount", numeric: false, disablePadding: false, label: "TOTAL AMOUNT" },
];

const headSchCells = [
  {
    id: "paymentDate",
    numeric: false,
    disablePadding: true,
    label: "PAYMENT DATE",
  },
  {
    id: "order.orderNo",
    numeric: false,
    disablePadding: false,
    label: "WAYBILL NO",
  },
  {
    id: "order.customer.firstName",
    numeric: false,
    disablePadding: false,
    label: "CUSTOMER",
  },
  {
    id: "order.customer.companyName",
    numeric: false,
    disablePadding: false,
    label: "COMPANY NAME",
  },
  { id: "weight", numeric: false, disablePadding: false, label: "WEIGHT" },
  {
    id: "subAmount",
    numeric: false,
    disablePadding: false,
    label: "DELIVERY AMOUNT",
  },
  {
    id: "onforwarding",
    numeric: false,
    disablePadding: false,
    label: "ONFORWARDING AMOUNT",
  },
  { id: "tax", numeric: false, disablePadding: false, label: "VAT" },
  { id: "total", numeric: false, disablePadding: false, label: "TOTAL AMOUNT" },
  { id: "status", numeric: false, disablePadding: false, label: "STATUS" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ whiteSpace: "nowrap" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedSchTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headSchCells.map((headCell) => (
          <TableCell
            style={{ whiteSpace: "nowrap" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Incomes = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("2022-09-01");
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const orderIncome = useSelector((state) => state.finance.ordersIncome);
  const orderSchIncome = useSelector((state) => state.finance.schordersIncome);
  const isLoading = useSelector((state) => state.finance.status);
  const isDownload = useSelector((state) => state.orders.download);
  console.log("schorderIncome :>>", orderSchIncome);
  console.log("loading :>>", isLoading);
  //   const [loading, setLoading] = useState(true)

  const [firstName, setFirstName] = useState();
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("");
  const [searchSchParam, setSearchSchParam] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [dateSchFilter, setDateSchFilter] = useState(null);
  const [filterMode, setFilterMode] = useState(null);
  const [filterSchMode, setFilterSchMode] = useState(null);

  const o = {
    name: firstName ? firstName.value : "",
    start: startDate,
    end: endDate,
  };
  const oS = {
    start: startDate,
    end: endDate,
  };

  useEffect(() => {
    dispatch(getIncomes(o));
    dispatch(getSchIncomes(oS));
    dispatch(getCustomers()).then((res) => {
      const custs = res.map((c) => ({
        label: c.companyName
          ? c.firstName + " " + c.lastName + " ( " + c.companyName + " )"
          : c.firstName + " " + c.lastName,
        value: c.firstName,
      }));
      const custSorted = custs.sort((a, b) => {
        const nameA = a.value?.toLowerCase();
        const nameB = b.value?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setOptions(custSorted);
    });
  }, []);

  const filteredIncomes = useCallback(() => {
    if (!searchParam && !dateFilter) {
      return orderIncome;
    }

    return orderIncome.filter((income) => {
      // Convert the search parameter to lowercase for case-insensitive search
      const searchTerm = searchParam.toLowerCase();

      const containsSearchTerm = (obj) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {
            if (containsSearchTerm(value)) {
              return true;
            }
          } else if (String(value).toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
        return false;
      };

      // Date filter
      const dateMatches =
        !dateFilter || moment(income.paymentDate).isSame(dateFilter, "day");

      return containsSearchTerm(income) && dateMatches;
    });
  }, [orderIncome, searchParam, dateFilter]);
  const filteredSchIncomes = useCallback(() => {
    if (!searchSchParam && !dateSchFilter) {
      return orderSchIncome;
    }

    return orderSchIncome.filter((income) => {
      // Convert the search parameter to lowercase for case-insensitive search
      const searchTerm = searchSchParam.toLowerCase();

      const containsSearchTerm = (obj) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {
            if (containsSearchTerm(value)) {
              return true;
            }
          } else if (String(value).toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
        return false;
      };

      // Date filter
      const dateMatches =
        !dateSchFilter || moment(income.paymentDate).isSame(dateSchFilter, "day");

      return containsSearchTerm(income) && dateMatches;
    });
  }, [orderSchIncome, searchSchParam, dateSchFilter]);

  const closeFilter = () => {
    setFilterMode(null);
    setFilterSchMode(null);
    setDateFilter(null);
    setDateSchFilter(null)
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderIncome.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNameChange = (data) => {
    console.log("selected", data);
    setFirstName(data);
  };

  const search = (e) => {
    const { value } = e.target;
    setSearchParam(value);
  };

  const searchSch = (e) => {
    const { value } = e.target;
    setSearchSchParam(value);
  };

  const [openViewDetail, setOpenViewDetail] = useState(false);
  const handleCloseViewDetail = () => {
    setOpenViewDetail(false);
  };

  const [selectedOrderIncome, setSelectedOrderIncome] = useState({});
  const selectOrderIncome = (income) => {
    setSelectedOrderIncome(income);
    console.log("selectedOrderIncome :>>", income);
    setOpenViewDetail(true);
  };
  const handleExport = () => {
    dispatch(downloadIncomes(o));
  };

  const handleGetOrdersIncome = (e) => {
    e.preventDefault();
    // const o = {
    //   start: startDate,
    //   end: endDate
    // };
    console.log("o :>>", o);
    dispatch(getIncomes(o));
    dispatch(getSchIncomes(oS));
  };

  const StatusDefinition =(status) =>
        {
            switch(status)
            {
                case 0: return "New"
                case 1: return "Picked"
                case 2: return "Arrived at Origin Hub"
                case 3: return "Arrived at Transit Gateway"
                case 4: return "Await Crating / Packing"
                case 5: return "Reweighed"
                case 6: return "Sorted to Origin city"
                case 7: return "Departed transit Gateway"
                case 8: return "Arrived DBM"
                case 9: return "Assigned Courier"
                case 10: return "Departed DBM"
                case 11: return "In Trasit to Destination Hub"
                case 12: return "Arrived after Courier Dispatched"
                case 13: return "Arrived at Destination Hub"
                case 14: return "Reweighed By Destination Hub"
                case 15: return "Debagged By Destination Hub"
                case 16: return "In Transit"
                case 17: return "Held for Collection"
                case 18: return "Arrived Damaged"
                case 19: return "Damaged Observed"
                case 20: return "Delivered"
                case 21: return "Delay Beyond our Control"
                case 99: return "Delay With Exception"
                case 24: return "Picked By Delivery Courier"
                case 22: return "Partial Delivery"
                case 50: return "Scanned"
            };
        }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("waybillNo");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredIncomes(), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredIncomes, order, orderBy, page, rowsPerPage]
  );

  const visibleSchRows = React.useMemo(
    () =>
      stableSort(filteredSchIncomes(), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredSchIncomes, order, orderBy, page, rowsPerPage]
  );

  const estimatedTime = selectedOrderIncome?.order?.estimatedTime / 60;
  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Income List</h4>
                  </div>
                  {orderIncome.length > 0 ? (
                    <div className="">
                      {isDownload === "idle" ? (
                        <button
                          type="button"
                          className="btn btn-md btn-primary"
                          onClick={() => handleExport()}
                        >
                          Export Report
                        </button>
                      ) : (
                        <button className="btn btn-md btn-primary" disabled>
                          Downloading...
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className=""></div>
                  )}
                </div>
                <div className="card-body">
                  <div>
                    <form onSubmit={handleGetOrdersIncome} className="">
                      <div className="row">
                        <div className="col-lg-11 col-xl-11">
                          <div className="row">
                            <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                              <p
                                style={{ fontSize: "14px" }}
                                className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                              >
                                FirstName:
                              </p>
                              <Select
                                className="col-6 col-sm-7 col-xl-8 px-0"
                                options={options}
                                value={firstName}
                                isSearchable={true}
                                isClearable={true}
                                onChange={handleNameChange}
                              />
                            </div>
                            <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                              <p
                                style={{ fontSize: "14px" }}
                                className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                              >
                                Start Date:
                              </p>
                              <input
                                className="col-6 col-sm-7 col-xl-8 form-control form-control-sm"
                                name="earnPercentage"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                            </div>
                            <div className="col-sm-6 col-lg-4 d-flex align-items-center pl-0 mb-2">
                              <p
                                style={{ fontSize: "14px" }}
                                className="col-6 col-sm-4 col-lg-5 col-xl-4 text mb-0"
                              >
                                End Date:
                              </p>
                              <input
                                className="col-6 col-sm-7 col-xl-8 form-control form-control-sm"
                                name="endDate"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1 col-xl-1 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="onDemand"
                            data-toggle="tab"
                            href="#onDemandTab"
                            role="tab"
                            aria-controls="onDemandTab"
                            aria-selected="true"
                          >
                            On-Demand
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="schedule"
                            data-toggle="tab"
                            href="#scheduledTab"
                            role="tab"
                            aria-controls="scheduledTab"
                            aria-selected="true"
                          >
                            Scheduled
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content" id="myTabContent-2">
                      <div
                        className="tab-pane fade show active"
                        id="onDemandTab"
                        role="tabpanel"
                        aria-labelledby="OnDemand-Tab"
                      >
                        {isLoading === "loading" ? (
                          <div className="text-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                            <p className="small">Fetching Order Payments...</p>
                          </div>
                        ) : orderIncome.length > 0 ? (
                          <>
                            <div className="d-flex flex-row-reverse">
                              <FilterListIcon
                                onClick={() => setFilterMode(0)}
                              />
                              <div className="col-4">
                                {filterMode === 0 && (
                                  <>
                                    <Close
                                      style={{ float: "right" }}
                                      onClick={() => closeFilter()}
                                    />
                                    <div className="form-group">
                                      <label for="dateFilter">
                                        Select Date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        value={dateFilter}
                                        onChange={(e) =>
                                          setDateFilter(e.target.value)
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <Box>
                              <div className="form-group">
                                <label className="form-label">Search</label>
                                <input
                                  type="text"
                                  onChange={search}
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </Box>
                            <TableContainer>
                              <Table
                                aria-labelledby="tableTitle"
                                sx={{ overflowX: "auto" }}
                              >
                                <EnhancedTableHead
                                  order={order}
                                  orderBy={orderBy}
                                  onRequestSort={handleRequestSort}
                                  rowCount={rowsPerPage.length}
                                />
                                <TableBody>
                                  {visibleRows?.map((income, index) => (
                                    <TableRow hover key={index}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {format(
                                          new Date(income.paymentDate),
                                          "yyyy-MM-dd"
                                        )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {income.order.orderNo}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {income.order.customer.firstName}{" "}
                                        {income.order.customer.lastName}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {income.order.customer.companyName}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {income.subAmount
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {income.tax
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {(
                                          income.subAmount -
                                          (
                                            (income.subAmount / 100) *
                                            income.order?.orderRider?.driver
                                              ?.rider?.partner?.earnPercentage
                                          ).toFixed(2)
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {(
                                          (income.subAmount / 100) *
                                          income.order.orderRider?.driver?.rider
                                            ?.partner?.earnPercentage
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>

                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {income.amount
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-primary"
                                          onClick={() => {
                                            selectOrderIncome(income);
                                          }}
                                        >
                                          View
                                        </button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        ) : null}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="scheduledTab"
                        role="tabpanel"
                        aria-labelledby="Scheduled-Tab"
                      >
                        {isLoading === "loading" ? (
                          <div className="text-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                            <p className="small">Fetching Order Payments...</p>
                          </div>
                        ) : orderSchIncome.length > 0 ? (
                          <>
                          <div className="d-flex flex-row-reverse">
                              <FilterListIcon
                                onClick={() => setFilterSchMode(0)}
                              />
                              <div className="col-4">
                                {filterSchMode === 0 && (
                                  <>
                                    <Close
                                      style={{ float: "right" }}
                                      onClick={() => closeFilter()}
                                    />
                                    <div className="form-group">
                                      <label for="dateFilter">
                                        Select Date
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        value={dateSchFilter}
                                        onChange={(e) =>
                                          setDateSchFilter(e.target.value)
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <Box>
                              <div className="form-group">
                                <label className="form-label">Search</label>
                                <input
                                  type="text"
                                  onChange={searchSch}
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </Box>
                            <TableContainer>
                              <Table aria-labelledby="tableTitle">
                                <EnhancedSchTableHead
                                  order={order}
                                  orderBy={orderBy}
                                  onRequestSort={handleRequestSort}
                                  rowCount={rowsPerPage.length}
                                />
                                <TableBody>
                                  {visibleSchRows?.map((income, index) => (
                                    <TableRow hover key={index}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {format(
                                          new Date(income.pickupDate),
                                          "yyyy-MM-dd"
                                        )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {income.waybillNumber}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {income.customer.firstName}{" "}
                                        {income.customer.lastName}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {income.customer.companyName}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {income.weight}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {income.deliveryAmount
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {income.onforwardAmount
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {income.vat
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        &#8358;
                                        {income.totalAmount
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                          margin: "0 !important",
                                          padding: "0.2 !important",
                                        }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {StatusDefinition(income.status)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      component="div"
                      count={filteredIncomes().length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
                <div
                  className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                  role="document"
                >
                  <Dialog
                    open={openViewDetail}
                    keepMounted
                    onClose={handleCloseViewDetail}
                    fullWidth={true}
                    maxWidth="sm"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="row d-block">
                          <h5
                            className="modal-title"
                            id="exampleModalCenteredScrollableTitle"
                          >
                            Payment details
                          </h5>
                          <ul
                            className="nav nav-tabs mt-2 mb-0 pb-0"
                            id="myTab-1"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="order-tab"
                                data-toggle="tab"
                                href="#order"
                                role="tab"
                                aria-controls="order"
                                aria-selected="true"
                              >
                                Order
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="payment-tab"
                                data-toggle="tab"
                                href="#payment"
                                role="tab"
                                aria-controls="payment"
                                aria-selected="false"
                              >
                                Payment
                              </a>
                            </li>
                          </ul>
                        </div>
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                        >
                          <span
                            aria-hidden="true"
                            onClick={handleCloseViewDetail}
                          >
                            ×
                          </span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="tab-content" id="myTabContent-2">
                          <div
                            className="tab-pane fade show"
                            id="order"
                            role="tabpanel"
                            aria-labelledby="order-tab"
                          >
                            <div className="media align-items-start">
                              <img
                                src="assets/images/user/delivery-box.png"
                                className="img-fluid avatar-rounded avatar-65"
                                alt="user"
                              />
                              <div className="media-body ml-2">
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0 mt-1">
                                    {selectedOrderIncome?.order?.orderNo}
                                  </p>
                                </div>
                                <span className="font-weight-bold"></span>
                              </div>
                            </div>
                            <div className="media align-items-start mt-4">
                              <div className="media-body">
                                <p className="text mb-0">Pickup Location</p>
                                <span className="font-weight-bold d-block">
                                  {selectedOrderIncome?.order?.pickup}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Pickup Date</p>
                                    <span className="font-weight-bold d-block">
                                      {/* {format(new Date(selectedOrderIncome?.order?.orderDate), 'yyyy-MM-dd HH:mm ')} */}

                                      {moment(
                                        selectedOrderIncome?.order?.orderDate
                                      ).format("YYYY-MM-DD HH:mm")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Total Distance</p>
                                    <span className="font-weight-bold d-block">
                                      {selectedOrderIncome?.order
                                        ?.estimatedDistance / 1000}
                                      km
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Duration</p>
                                    <span className="font-weight-bold d-block">
                                      {/* { selectedOrderIncome.estimatedTime / 60 } mins */}
                                      {Math.ceil(estimatedTime)}
                                      mins
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Estimated Value</p>
                                    <span className="font-weight-bold d-block">
                                      &#8358;
                                      {selectedOrderIncome.amount
                                        ?.toFixed(2)
                                        ?.toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Customer Name</p>
                                    <span className="font-weight-bold d-block">
                                      {/* { selectedOrderIncome.estimatedTime / 60 } mins */}
                                      {/* { format(new Date(selectedOrderIncome.paymentDate), 'yyyy-MM-dd HH:mm:ss') } */}
                                      {
                                        selectedOrderIncome.order?.customer
                                          ?.firstName
                                      }{" "}
                                      {
                                        selectedOrderIncome.order?.customer
                                          ?.lastName
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Partner Name</p>
                                    <span className="font-weight-bold d-block">
                                      {
                                        selectedOrderIncome.order?.customer
                                          ?.companyName
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade show active"
                            id="payment"
                            role="tabpanel"
                            aria-labelledby="payment-tab"
                          >
                            <div className="media align-items-start mt-4">
                              <div className="media-body">
                                <p className="text mb-0">Total Amount</p>
                                <span className="font-weight-bold d-block">
                                  &#8358;
                                  {selectedOrderIncome.amount
                                    ?.toFixed(2)
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Partner Amount</p>
                                    <span className="font-weight-bold d-block">
                                      &#8358;
                                      {(
                                        (selectedOrderIncome.subAmount / 100) *
                                        selectedOrderIncome.order?.orderRider
                                          ?.driver?.rider?.partner
                                          ?.earnPercentage
                                      )
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">RedSpeed Amount</p>
                                    <span className="font-weight-bold d-block">
                                      &#8358;
                                      {(
                                        selectedOrderIncome.subAmount -
                                        (
                                          (selectedOrderIncome.subAmount /
                                            100) *
                                          selectedOrderIncome.order?.orderRider
                                            ?.driver?.rider?.partner
                                            ?.earnPercentage
                                        ).toFixed(2)
                                      )
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">VAT Amount</p>
                                    <span className="font-weight-bold d-block">
                                      &#8358;
                                      {selectedOrderIncome.tax
                                        ?.toFixed(2)
                                        ?.toString()
                                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Payment Date</p>
                                    <span className="font-weight-bold d-block">
                                      {moment(
                                        selectedOrderIncome.paymentDate
                                      ).format("YYYY-MM-DD HH:mm:ss")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">
                                      Payment Reference
                                    </p>
                                    <span className="font-weight-bold d-block">
                                      {/* { selectedOrderIncome.estimatedTime / 60 } mins */}
                                      {selectedOrderIncome.paymentReference}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="media align-items-start mt-4">
                                  <div className="media-body">
                                    <p className="text mb-0">Payment Type</p>
                                    <span className="font-weight-bold d-block">
                                      {/* { selectedOrderIncome.estimatedTime / 60 } mins */}
                                      {selectedOrderIncome.chargeType}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Incomes;
