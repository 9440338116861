import React from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";

const KeySettings = () => {
    return (
        <>
            <NavBar />
            <SideBar />

            <div class="content-page">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between">
                                    <div class="header-title">
                                        <h4 class="card-title">Key Settings</h4>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div id="table" class="table-editable">
                                        <span class="float-right mb-3 mr-2">
                                            <button
                                                type="button"
                                                class="btn btn-info mt-2"
                                                data-toggle="modal"
                                                data-target="#newKey"
                                            >
                                                <i class="ri-add-fill">
                                                    <span class="pl-1">
                                                        Add New
                                                    </span>
                                                </i>
                                            </button>
                                        </span>
                                        <table class="table table-bordered table-responsive-md table-striped text-center">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td contenteditable="true">
                                                        Big Van
                                                    </td>

                                                    <td>
                                                        <span class="table-up">
                                                            <button
                                                                type="button"
                                                                data-toggle="modal"
                                                                data-target="#newKeyEdit"
                                                                class="btn bg-primary-light btn-rounded btn-sm my-0"
                                                            >
                                                                Edit
                                                            </button>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="table-remove">
                                                            <button
                                                                type="button"
                                                                data-toggle="modal"
                                                                data-target="#keyDelete"
                                                                class="btn bg-danger-light btn-rounded btn-sm my-0"
                                                            >
                                                                Remove
                                                            </button>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td contenteditable="true">
                                                        40FT Container
                                                    </td>

                                                    <td>
                                                        <span class="table-up">
                                                            <button
                                                                type="button"
                                                                class="btn bg-primary-light btn-rounded btn-sm my-0 mx-0"
                                                            >
                                                                Edit
                                                            </button>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="table-remove">
                                                            <button
                                                                type="button"
                                                                class="btn bg-danger-light btn-rounded btn-sm my-0"
                                                            >
                                                                Remove
                                                            </button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="modal fade"
                                id="newKey"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalScrollableTitle"
                                aria-hidden="true"
                            >
                                <div
                                    class="modal-dialog modal-dialog-scrollable"
                                    role="document"
                                >
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5
                                                class="modal-title"
                                                id="exampleModalScrollableTitle"
                                            >
                                                Add New Key
                                            </h5>
                                            <button
                                                type="button"
                                                class="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form>
                                                <div class="form-group">
                                                    <label for="colFormLabelSm">
                                                        List of Access Keys
                                                    </label>
                                                    {/* <input
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    /> */}
                                                    <select
                                                        class="form-select form-control form-control-md"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>
                                                            Open this select
                                                            menu
                                                        </option>
                                                        <option value="1">
                                                            Google
                                                        </option>
                                                        <option value="2">
                                                            Firebase
                                                        </option>
                                                        {/* <option value="3">
                                                            Three
                                                        </option> */}
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label for="colFormLabel">
                                                        Access Key
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    ></textarea>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label
                                                        for="colFormLabelLg"
                                                        class="pb-0"
                                                    >
                                                        Secret Key
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    ></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="button"
                                                class="table-add btn btn-primary"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="modal fade"
                                id="newKeyEdit"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalScrollableTitle"
                                aria-hidden="true"
                            >
                                <div
                                    class="modal-dialog modal-dialog-scrollable"
                                    role="document"
                                >
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5
                                                class="modal-title"
                                                id="exampleModalScrollableTitle"
                                            >
                                                Edit New Key
                                            </h5>
                                            <button
                                                type="button"
                                                class="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form>
                                                <div class="form-group">
                                                    <label for="colFormLabelSm">
                                                        List of Access Keys
                                                    </label>
                                                    {/* <input
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    /> */}
                                                    <select
                                                        class="form-select form-control form-control-md"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>
                                                            Open this select
                                                            menu
                                                        </option>
                                                        <option value="1">
                                                            Google
                                                        </option>
                                                        <option value="2">
                                                            Firebase
                                                        </option>
                                                        {/* <option value="3">
                                                            Three
                                                        </option> */}
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label for="colFormLabel">
                                                        Access Key
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    ></textarea>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label
                                                        for="colFormLabelLg"
                                                        class="pb-0"
                                                    >
                                                        Secret Key
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        class="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    ></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="button"
                                                class="table-add btn btn-primary"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="modal fade"
                                id="keyDelete"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalScrollableTitle"
                                aria-hidden="true"
                            >
                                <div
                                    class="modal-dialog modal-dialog-scrollable"
                                    role="document"
                                >
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5
                                                class="modal-title"
                                                id="exampleModalScrollableTitle"
                                            >
                                                Delete Key
                                            </h5>
                                            <button
                                                type="button"
                                                class="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>

                                        <div class="modal-footer">
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="button"
                                                class="table-add btn btn-primary"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default KeySettings;
