import { combineReducers } from "redux";
import { partnersReducer } from "./partners.reducer";
import { orderReducer } from "./order.reducer";
import { settingsReducer } from "./settings.reducer";
import { vehiclesReducer } from "./vehicle.reducer";
import { ridersReducer } from "./rider.reducer";
import { tripsReducer } from "./trip.reducer";
import { authReducer } from "./account.reducer";
import { financeReducer } from "./finance.reducer";
import { pickUpReducer } from "./pickUp.reducer";
import { mapReducer } from "./map.reducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    orders: orderReducer,
    settings: settingsReducer,
    partner: partnersReducer,
    vehicles: vehiclesReducer,
    riders: ridersReducer,
    trips: tripsReducer,
    finance: financeReducer,
    pickup: pickUpReducer,
    map: mapReducer
});
