import { Navigate } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import {
    approvedPartnersConstants,
    newPartnersConstants,
    verifiedPartnersConstants,
    verifyPartnerConstants,
    approvePartnerConstants,
    topPartnersConstants
} from "./constants";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export const getNewPartners = () => {
    return async (dispatch) => {
        dispatch({
            type: newPartnersConstants.GET_NEW_PARTNERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/partner/new");
        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: newPartnersConstants.GET_NEW_PARTNERS_SUCCESS,
                payload: { newPartners: data },
            });
        } else if (res.status === 401) {
            return <Navigate replace={true} to="/signin" />;
        } else {
        }
    };
};

export const getVerifiedPartners = () => {
    return async (dispatch) => {
        dispatch({
            type: verifiedPartnersConstants.GET_VERIFIED_PARTNERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/partner/verified");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: verifiedPartnersConstants.GET_VERIFIED_PARTNERS_SUCCESS,
                payload: { verifiedPartners: data },
            });
        } else {
        }
    };
};

export const getApprovedPartners = () => {
    return async (dispatch) => {
        dispatch({
            type: approvedPartnersConstants.GET_APPROVED_PARTNERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/partner/approved");
        // console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: approvedPartnersConstants.GET_APPROVED_PARTNERS_SUCCESS,
                payload: { approvedPartners: data },
            });
        } else {
        }
    };
};

export const verifyPartner = (id) => {
    return async (dispatch) => {
        dispatch({ type: verifyPartnerConstants.VERIFY_PARTNER_REQUEST });
        const res = await axiosInstance.get(`/Admin/VerifyPartner/${id}`);
        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({ type: verifyPartnerConstants.VERIFY_PARTNER_SUCCESS });
            toast.success("Partner verified successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getVerifiedPartners();
        } else {
        }
    };
};

export const approvePartner = (p) => {
    return async (dispatch) => {
        dispatch({ type: approvePartnerConstants.APPROVE_PARTNER_REQUEST });
        const res = await axiosInstance.get(`/Admin/ApprovePartner/${p.id}?percentage=${p.percentage}`);
        console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: approvePartnerConstants.APPROVE_PARTNER_SUCCESS,
            });
            toast.success("Partner approved successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getApprovedPartners();
        }
    };
};

export const getTopPartners = () => {
    return async (dispatch) => {
        dispatch({
            type: topPartnersConstants.GET_TOP_PARTNERS_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/partner/top");
        // console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: topPartnersConstants.GET_TOP_PARTNERS_SUCCESS,
                payload: { topPartners: data },
            });
        } else {
        }
    };
};
