import {
    orderIncomesConstants,
    partnersIncomesConstants,
    walletConstants,
    settlementConstant
} from "../actions/constants"

const initState = {
    ordersIncome: [],
    schordersIncome: [],
    partnersIncomes: [],
    walletPayments: [],
    status: 'idle',
    download: 'idle',
    error: {}
};

export const financeReducer = (state = initState, action) => {
    switch (action.type) {
        case orderIncomesConstants.GET_ORDER_INCOMES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case orderIncomesConstants.GET_ORDER_INCOMES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                ordersIncome: action.payload.ordersIncome
            });
        
        case orderIncomesConstants.GET_SCHORDER_INCOMES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case orderIncomesConstants.GET_SCHORDER_INCOMES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                schordersIncome: action.payload.schordersIncome
            });
        
        case partnersIncomesConstants.GET_PARTNERS_INCOMES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case partnersIncomesConstants.GET_PARTNERS_INCOMES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                partnersIncomes: action.payload.partnersIncomes
            });

        case walletConstants.GET_WALLET_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
            });
        case walletConstants.GET_WALLET_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                walletPayments: action.payload.walletPayments
            });
        
        case settlementConstant.ADD_PARTNER_SETTLEMENT_REQUEST:
            return (state = {
                ...state,
                status: 'idle',
            });
        case settlementConstant.ADD_PARTNER_SETTLEMENT_SUCCESS:
            return (state = {
                ...state,
                status: 'loading',
            });
        case walletConstants.EXPORT_WALLET_REQUEST:
            return (state = {
                ...state,
                download: "loading",
            });
        case walletConstants.EXPORT_WALLET_SUCCESS:
            return (state = {
                ...state,
                download: "idle"
            });
        case walletConstants.EXPORT_WALLET_FAILURE:
            return (state = {
                ...state,
                download: "idle"
            });
          
        default:
            return { ...state };
    }
};