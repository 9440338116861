import axiosInstance from "../../utils/axios";
import {
    getPickUpConstant,
    getPickUpsConstant,
    getHubsConstants,
    confirmPickUpConstant,
} from "./constants";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const getPickUps = (id) => {
    return async (dispatch) => {
        dispatch({
            type: getPickUpsConstant.GET_PICK_UPS_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/pickupBags?hubId=${id}`);
        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: getPickUpsConstant.GET_PICK_UPS_SUCCESS,
                payload: { pickUps: data },
            });
            return data
        } else {
        }
    };
};

export const getHubs = () => {
    return async (dispatch) => {
        dispatch({
            type: getHubsConstants.GET_HUBS_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/hubs`);
        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: getHubsConstants.GET_HUBS_SUCCESS,
                payload: { hubs: data },
            });
        } else {
        }
    };
};

export const getPickUp = (id) => {
    return async (dispatch) => {
        dispatch({
            type: getPickUpConstant.GET_PICK_UP_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/pickupBag/${id}`);
        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: getPickUpConstant.GET_PICK_UP_SUCCESS
            });
            return data
        } else {
        }
    };
};

export const confirmPickUp = (data) => {
    return async (dispatch) => {
        dispatch({
            type: confirmPickUpConstant.CONFIRM_PICK_UP_REQUEST,
        });
        const res = await axiosInstance.post(`/Admin/confirmPickupBag`, data);
        // console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: confirmPickUpConstant.CONFIRM_PICK_UP_SUCCESS,
            });
            toast.success("Pick Up Confirmed successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getPickUp();
        }
    };
};


