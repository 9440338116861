// * React imports
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// * Third Party imports
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import Select from "react-select";

// * MUI imports
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from '@mui/material/Checkbox';

// * action file imports
import { getRcs } from "../../state/actions";
import {
  getCustomers
} from "../../state/actions/rider.actions";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DailySales = () => {
  const dispatch = useDispatch();
  const rcs = useSelector((state) => state.orders.rcs);
  const isLoading = useSelector((state) => state.orders.status);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("");
  const [customer, setCustomer] = useState();
  const [options, setOptions] = useState([]);

  const handleNameChange = (data) => {
    console.log('selected', data)
    setCustomer(data)
  }

  function filteredRcs () {
    if (!searchParam) {
      return rcs;
    }
  
    return rcs.filter((j) => {
      return Object.values(j).some((value) => {
        return String(value).toLowerCase().includes(searchParam.toLowerCase());
      });
    });
  }

  const handleSearchValues = (e) => {
    e.preventDefault();
    dispatch(getRcs(customer.value))
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rcs.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = (e) => {
    const { value } = e.target;
    setSearchParam(value);
  };

  useEffect(() => {
    dispatch(getCustomers()).then((res) => {
      console.log('resCust', res)
      const custSorted = res.sort((a, b) => {
        const nameA = a.firstName?.toLowerCase();
        const nameB = b.firstName?.toLowerCase();
        if(nameA < nameB) { return -1; }
        if(nameA > nameB) { return 1; }
        return 0;
      })
      const custs = custSorted.map(c => ({
        label: c.companyName ? c.firstName + " " + c.lastName + " ( " + c.companyName + " )" : c.firstName + " " + c.lastName,
        value: c.id
      }))
      setOptions(custs)
      const currentCustomer = localStorage.getItem("currentCustomer")
      if (currentCustomer) {
        setCustomer(currentCustomer)
        dispatch(getRcs(customer.value))
      } else {
        setCustomer();
      }
      console.log('options', options)
    })
  }, [dispatch]);


  return (
    <>
      <NavBar />
      <SideBar />

      <div class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  <div class="header-title">
                    <h4 class="card-title">Customer RCS</h4>
                  </div>
                </div>
                <div class="card-body">
                  <form onSubmit={handleSearchValues} className="">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="d-flex mb-2">
                          <p style={{ fontSize: "14px" }} className="col-5 col-md-4 col-lg-3 mb-0">Select Customer:</p>
                          <Select
                          className="col-7 col-md-8 col-lg-9 px-0"
                          options={options}
                          value={customer}
                          isSearchable={true}
                          isClearable={true}
                          onChange={handleNameChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 d-flex justify-content-end justify-content-md-start">
                        <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={handleSearchValues}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <div>
                    {isLoading === "loading" ? (
                      <div class="text-center mt-4">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p className="small">Fetching Data...</p>
                      </div>
                    ) : rcs.length > 0 ? (
                      <div className="table-responsive">
                        <Box>
                          <div className="form-group">
                            <label className="form-label">Search</label>
                            <input
                              type="text"
                              onChange={search}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </Box>
                        <Table aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell>RCS No</TableCell>
                              <TableCell>Generated Date</TableCell>
                              <TableCell>Driver</TableCell>
                              <TableCell>Driver Discharged</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(rowsPerPage > 0
                              ? filteredRcs().slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : filteredRcs()
                            )
                              ?.map((r, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell>
                                    { r.no }
                                  </TableCell>
                                  <TableCell>
                                    { new Date(r.date).toLocaleDateString() }
                                  </TableCell>
                                  <TableCell>
                                    { r.driver.firstName } {" "} { r.driver.lastName }
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox
                                    disabled
                                    checked={r.dischargedConfirmedByDriver}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {r?.status === 0 && (
                                    <span style={{ color: "#f9c851" }}>pending</span>
                                    )}
                                    {r?.status === 1 && (
                                    <span style={{ color: "#188ae2" }}>accepted</span>
                                    )}
                                    {r?.status === 2 && (
                                    <span style={{ color: "#ff6666" }}>
                                        awaiting pickup
                                    </span>
                                    )}
                                    {r?.status === 3 && (
                                    <span style={{ color: "#666699" }}>arrived</span>
                                    )}
                                    {r?.status === 4 && (
                                    <span style={{ color: "#f9c851" }}>
                                        awaiting rider code
                                    </span>
                                    )}
                                    {r?.status === 5 && (
                                    <span style={{ color: "#bf4080" }}>
                                        In Transit
                                    </span>
                                    )}
                                    {r?.status === 10 && (
                                    <span style={{ color: "#10c469" }}>
                                        Completed
                                    </span>
                                    )}
                                    {r?.status === 11 && (
                                    <span style={{ color: "#ff5b5b" }}>
                                        cancelled
                                    </span>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Link to={`/rcs/${r.id}`}>
                                    View
                                    </Link>              
                                  </TableCell>
                                </TableRow>
                              ))}
                              {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      <div className="text-center mx-auto mt-4">
                        <p className="text-Center">
                          No RCS is currently available for this customer
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <TablePagination
                     rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      50,
                      { label: "All", value: -1 },
                    ]}
                    component="div"
                    count={rcs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailySales;
