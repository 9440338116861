import {
    Box,
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import {
    approvePartner,
    getVerifiedPartners
} from "../../state/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const VerifiedPartners = () => {
    const dispatch = useDispatch();

    

    const verifiedPartners = useSelector(
        (state) => state.partner.verifiedPartners
    );
    const isLoading = useSelector((state) => state.partner.status)
    console.log("verifiedPartners :>> ", verifiedPartners);const [loading, setLoading] = useState(true);
    useEffect(() => {
        dispatch(getVerifiedPartners())
    }, []);

    const [page, setPage] = useState(0);
    const [openViewDetail, setOpenViewDetail] = useState(false);

    const handleCloseViewDetail = () => {
        setOpenViewDetail(false);
    };

    const handleChangePage = useCallback(
        (_, page) => {
            setPage(page);
        },
        [setPage]
    );
    const [selectedPartner, setSelectedPartner] = useState({});

    const selectPartner = (partner) => {
        setSelectedPartner(partner);
        console.log("selectedPartner :>> ", partner);
        setOpenViewDetail(true);
    };

    const [earnPercentage, setEarnPercentage] = useState(0);
    const handleApprovePartner = (e) => {
        e.preventDefault();
        const p = {
            id: selectedPartner.id,
            percentage: earnPercentage,
        };
        console.log("p :>> ", p);
        dispatch(approvePartner(p)).then(() => {
            dispatch(getVerifiedPartners());
            setOpenViewDetail(false);
        });
    };
    let rowsPerPage = 10;
    function getStatus(status) {
        switch (status) {
            case 0:
                return (<span className="badge badge-danger">Pending</span>);
            case 1:
                return (<span className="badge badge-success">Approved</span>);
            case 2:
                return (
                    <span className="badge badge-warning">
                        Awaiting Approval
                    </span>
                );

            default:
                return (<span className="badge badge-danger">Pending</span>);
        }
    }
    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            Verified Partners
                                        </h4>
                                    </div>
                                </div>
                                <Box
                                    // width="110%"
                                    sx={{
                                        backgroundColor: "#fff",
                                        // marginLeft: theme.spacing(3),
                                    }}
                                >
                                    <div>
                                        {isLoading === 'loading' ?
                                        <div className="text-center">
                                          <div className="spinner-border text-danger" role="status">
                                            <span className="sr-only">Loading...</span>
                                          </div>
                                          <p className="small">Getting Verified Partners...</p>
                                        </div> :
                                        verifiedPartners.length > 0 ? (
                                            <Table aria-labelledby="tableTitle">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            Address
                                                        </TableCell>
                                                        <TableCell>
                                                            RC Number
                                                        </TableCell>
                                                        <TableCell>
                                                            Phone Number
                                                        </TableCell>
                                                        <TableCell>
                                                            Status
                                                        </TableCell>
                                                        <TableCell>
                                                            View Detail
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {verifiedPartners.length >
                                                        0 &&
                                                        verifiedPartners.map(
                                                            (
                                                                verifiedPartner,
                                                                index
                                                            ) => (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    key={index}
                                                                    onClick={() => {
                                                                        // selectRow(
                                                                        //     trackOrder
                                                                        // );
                                                                    }}
                                                                    sx={{
                                                                        height: "30 !important",
                                                                        // maxWidth: "50rem",
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        // component="th"
                                                                        scope="row"
                                                                    >
                                                                        {
                                                                            verifiedPartner.companyName
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        // sx={{
                                                                        //     width: "5% !important",
                                                                        //     padding:
                                                                        //         "0 5",
                                                                        //     margin: "0 !important",
                                                                        // }}
                                                                    >
                                                                        {
                                                                            verifiedPartner.address
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            verifiedPartner.rcNumber
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            // width: "5% !important",
                                                                            padding:
                                                                                "0 5",
                                                                            margin: "0 !important",
                                                                        }}
                                                                    >
                                                                        {
                                                                            verifiedPartner.telephone
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        {verifiedPartner.status ===
                                                                        0 ? (
                                                                            <span className="badge badge-danger">
                                                                                Pending
                                                                            </span>
                                                                        ) : (
                                                                            <span className="badge badge-danger">
                                                                                Awaiting
                                                                                Approval
                                                                            </span>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        sx={{
                                                                            padding: "0 !important"
                                                                        }}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-primary"
                                                                            onClick={() => {
                                                                                selectPartner(
                                                                                    verifiedPartner
                                                                                );
                                                                            }}
                                                                        >
                                                                            View
                                                                            Details
                                                                        </button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                </TableBody>
                                            </Table>
                                        ) : 
                                        <div className="text-center">
                                          <p>No Partner pending approval</p>
                                        </div>
                                        }
                                    </div>
                                    <div>
                                        <TablePagination
                                            component="div"
                                            count={verifiedPartners.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page",
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page",
                                            }}
                                            onPageChange={handleChangePage}
                                            labelRowsPerPage=""
                                        />
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Modal */}

            <Dialog 
            open={openViewDetail} 
            onClose={handleCloseViewDetail}
            fullWidth={true}
            maxWidth="sm"
            >
                {/* <DialogTitle>Subscribe</DialogTitle> */}
                <DialogContent>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title"
                                id="exampleModalCenteredScrollableTitle"
                            >
                                Partner details
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    onClick={handleCloseViewDetail}
                                >
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="media align-items-start">
                                {/* <img
                                    src="assets/images/user/1.jpg"
                                    className="img-fluid avatar-rounded avatar-60"
                                    alt="user"
                                /> */}
                                <div className="bg-primary-light avatar-rounded p-2">
                                    <svg
                                        className="svg-icon svg-primary text-primary"
                                        width="42"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M13 10V3L4 14h7v7l9-11h-7z"
                                        />
                                    </svg>
                                </div>
                                <div className="media-body ml-2 row">
                                    <div className="col-md-12">
                                        <p className="mb-0 mt-1">
                                            {selectedPartner.companyName}
                                        </p>
                                        <span className="font-weight-bold">
                                            {selectedPartner.rcNumber}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="media align-items-start mt-2">
                                <div className="media-body">
                                    <p className="text mb-0">Contact Name</p>
                                    <span className="font-weight-bold d-block">
                                        {selectedPartner.contactName}
                                    </span>
                                </div>
                            </div>
                            <div className="media align-items-start mt-4">
                                <div className="media-body">
                                    <p className="text mb-0">Contact Phone</p>
                                    <span className="font-weight-bold d-block">
                                        {selectedPartner.telephone}
                                    </span>
                                </div>
                            </div>
                            <div className="media align-items-start mt-4">
                                <div className="media-body">
                                    <p className="text mb-0">Company Address</p>
                                    <span className="font-weight-bold d-block">
                                        {selectedPartner.address}
                                    </span>
                                </div>
                            </div>
                            <div className="media align-items-start mt-4">
                                <Table aria-labelledby="tableTitle">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th">
                                                1
                                            </TableCell>
                                            <TableCell component="th">
                                                TIN
                                            </TableCell>
                                            <TableCell component="th">
                                                <span className="badge badge-success">
                                                    Approved
                                                </span>
                                            </TableCell>
                                            <TableCell component="th">
                                                <a
                                                    href={`${selectedPartner.tinCertificateUrl}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th">
                                                2
                                            </TableCell>
                                            <TableCell component="th">
                                                CAC
                                            </TableCell>
                                            <TableCell component="th">
                                                <span className="badge badge-success">
                                                    Approved
                                                </span>
                                            </TableCell>
                                            <TableCell component="th">
                                                <a
                                                    href={`${selectedPartner.cacDocumentUrl}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th">
                                                3
                                            </TableCell>
                                            <TableCell component="th">
                                                TAX
                                            </TableCell>
                                            <TableCell component="th">
                                                <span className="badge badge-success">
                                                    Approved
                                                </span>
                                            </TableCell>
                                            <TableCell component="th">
                                                <a
                                                    href={`${selectedPartner.taxFormUrl}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                            <div className="media align-items-start mt-4">
                                <div className="media-body">
                                  <form onSubmit={handleApprovePartner}>
                                    <p className="text mb-0">Percentage</p>
                                    <input
                                        className="form-control"
                                        name="earnPercentage"
                                        type="number"
                                        // value={ vehicleInspectionForm.inspectionNote }
                                        value={earnPercentage}
                                        onChange={(e) => setEarnPercentage(e.target.value)}
                                        // onChange={ handleVehicleInspectionInputChange }
                                    />
                                    <div className="row">
                                        <div className="col-md-12 pt-2">
                                        {isLoading === 'idle' &&
                                            <button type="submit" className="btn btn-success">
                                            Approve
                                        </button>
                                        }
                                        {isLoading === 'loading' &&
                                            <button type="button" className="btn btn-success" disabled>
                                            Please wait <i className="fas fa-spinner fa-spin"></i> 
                                            </button>
                                        }
                                        <button type="button" className="btn btn-primary mx-3">
                                            Reject
                                        </button>
                                        </div>
                                    </div>
                                  </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
            </Dialog>
            <ToastContainer />
        </>
    );
};

export default VerifiedPartners;
