import {
    tripConstants,
    homeConstants
} from "../actions/constants";

const initState = {
    allTrips: [],
    incidents: [],
    topPartners: [],
    topRiders: [],
    topCustomers: [],
    topVehicles: [],
    orderReport: [],
    revenueReport: [],
    orderings: [],
    shippings: [],
    status: 'idle',
    resolve: 'idle',
    error: {},
};

export const tripsReducer = (state = initState, action) => {
    switch (action.type) {
        case tripConstants.GET_TRIPS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case tripConstants.GET_TRIPS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                allTrips: action.payload.allTrips,
            });

        case tripConstants.GET_INCIDENT_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case tripConstants.GET_INCIDENT_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                incidents: action.payload.incidents,
            });

        case tripConstants.UPDATE_INCIDENT_REQUEST:
            return (state = { 
                ...state,
                resolve: 'loading'
                });
        case tripConstants.UPDATE_INCIDENT_SUCCESS:
            return (state = {
                ...state,
                resolve: 'idle'
            });
        
        case homeConstants.TOP_PARTNERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case homeConstants.TOP_PARTNERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                topPartners: action.payload.topPartners,
            });
        case homeConstants.TOP_PARTNERS_ERROR:
            return (state = {
                ...state,
                status: 'idle'
            });
        case homeConstants.TOP_RIDERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case homeConstants.TOP_RIDERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                topRiders: action.payload.topRiders,
            });
        case homeConstants.TOP_RIDERS_ERROR:
            return (state = {
                ...state,
                status: 'idle'
            });
        case homeConstants.TOP_CUSTOMERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case homeConstants.TOP_CUSTOMERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                topCustomers: action.payload.topCustomers,
            });
        case homeConstants.TOP_CUSTOMERS_ERROR:
            return (state = {
                ...state,
                status: 'idle'
            });
        case homeConstants.TOP_VEHICLES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case homeConstants.TOP_VEHICLES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                topVehicles: action.payload.topVehicles,
            });
        case homeConstants.ORDER_REPORT_REQUEST:
        return (state = { 
            ...state,
            status: 'loading'
            });
        case homeConstants.ORDER_REPORT_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                orderReport: action.payload.orderReport,
            });
        case homeConstants.ORDER_REPORT_ERROR:
            return (state = {
                ...state,
                status: 'idle'
            });
        case homeConstants.REVENUE_REPORT_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case homeConstants.REVENUE_REPORT_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                revenueReport: action.payload.revenueReport,
            });
        case homeConstants.REVENUE_REPORT_ERROR:
            return (state = {
                ...state,
                status: 'idle'
            });
        case homeConstants.ORDERING_TREND_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case homeConstants.ORDERING_TREND_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                orderings: action.payload.orderings,
            });
        case homeConstants.SHIPPING_TRIP_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case homeConstants.SHIPPING_TRIP_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                shippings: action.payload.shippings,
            });

        default:
            return { ...state };
    }
};
