import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { signinAsAdmin } from "../../state/actions/account.actions";
import { authReducer } from "../../state/reducers/account.reducer";
import "./signup.css";
import SignupSigninFooter from "./SignupSigninFooter";

const Signin = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [signinForm, setSigninForm] = useState({
        email: "",
        password: "",
    });
    const handleSigninInputChange = (e) => {
        const field = e.target.name;
        const nextSigninFormState = {
            ...signinForm,
            [field]: e.target.value,
        };
        setSigninForm(nextSigninFormState);
    };
    const handleSubmitSigninForm = (e) => {
        e.preventDefault();
        console.log("signinForm :>> ", signinForm);
        dispatch(signinAsAdmin(signinForm));
    };
    if (auth.authenticated) {
        return <Navigate to="/" />;
    }
    return (
        <div>
            <div className="signup col-12">
                <div className="signin-card col-4 mx-auto border">
                    <div className="signup-header">
                        <div className="signup-logo my-3">
                            <img
                                src="assets/images/Redspeed-black.png"
                                className="img-fluid rounded-normal light-logo"
                                alt="logo"
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-center welcome">Welcome Back</p>
                        <p className="text-center instruction">
                            Access Your Dashboard
                        </p>
                    </div>
                    <form
                        onSubmit={handleSubmitSigninForm}
                        className="mx-4 form"
                    >
                        <div>
                            <div className="form-row d-block">
                                <div className="form-group mb-3">
                                    <label htmlFor="validationDefault02">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={signinForm.email}
                                        onChange={handleSigninInputChange}
                                        placeholder=""
                                        className="form-control"
                                        id="validationDefault02"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="d-flex justify-content-between">
                                        <label htmlFor="validationDefaultUsername">
                                            Password
                                        </label>
                                        <label htmlFor="validationDefaultUsername">
                                            Forgot Password
                                        </label>
                                    </div>

                                    <input
                                        type="password"
                                        name="password"
                                        value={signinForm.password}
                                        onChange={handleSigninInputChange}
                                        placeholder=""
                                        className="form-control"
                                        id="validationDefaultUsername"
                                        aria-describedby="inputGroupPrepend2"
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary my-2 btn-lg col-12"
                                    >
                                        Signin
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="float-right mx-4">
                        <p>
                            Don't have an account?{" "}
                            <span>
                                <Link to="/register">Signup</Link>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className="col-4 mx-auto d-block">
                <div className="form-group d-flex justify-content-between">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg col-5"
                    >
                        Signup
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg col-5"
                    >
                        Signup
                    </button>
                </div>
            </div> */}
            <div className="mx-5">
                <SignupSigninFooter />
            </div>
        </div>
    );
};

export default Signin;
