import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import AppRoutes from "./routes";
import store from "./store";

// ReactDOM.render(
//     <React.StrictMode>
//         <Provider store={store}>
//             <AppRoutes />
//         </Provider>
//     </React.StrictMode>,
//     document.getElementById("root")
// );

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
// root.render(<App />);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppRoutes />
        </Provider>
    </React.StrictMode>
);

{
    /* <script src="%PUBLIC_URL%/assets/js/charts/01.js"></script> */
}
