import {
    Dialog,
    DialogContent,
    DialogContentText,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import {
    addRatingQuestion,
    getRatingQuestions,
    deleteRatingQuestion,
    editRatingQuestion
} from "../../state/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const RatingQuestions = () => {
    const dispatch = useDispatch();
    const allQuestions = useSelector((state) => state.settings.questions);
    console.log("questions :>>", allQuestions)
    const isLoading = useSelector((state) => state.settings.status)
    const [openViewDetail, setOpenViewDetail] = useState(false);
    const [openEditDetail, setOpenEditDetail] = useState(false);
    const [openDeleteDetail, setOpenDeleteDetail] = useState(false);
    const handleClickOpenViewDetail = () => {
        setOpenViewDetail(true);
    };

    const handleCloseViewDetail = () => {
        setOpenViewDetail(false);
    };
    const handleCloseEditDetail = () => {
        setOpenEditDetail(false);
    };
    const handleCloseDeleteDetail = () => {
        setOpenDeleteDetail(false);
    };
    
    useEffect(() => {
        dispatch(getRatingQuestions());
    }, [dispatch]);

    const [ratingQuestionForm, setRatingQuestionForm] = useState({
        name: ""
    });
    const handleQuestionInputChange = (e) => {
        const field = e.target.name;
        const nextRatingQuestionFormState = {
            ...ratingQuestionForm,
            [field]: e.target.value,
        };
        setRatingQuestionForm(nextRatingQuestionFormState);
    };
    const [selectedQuestionEdit, setSelectedQuestionEdit] = useState({});
    const [ratingQuestionFormEdit, setRatingQuestioFormEdit] =
        useState(selectedQuestionEdit);
    const selectQuestionForEdit = (question) => {
        const newQuestion = {
            ...question,
        };
        // setSelectedCategoryEdit(category);
        setRatingQuestioFormEdit(newQuestion);
        console.log("selectedCategoryEdit :>> ", question);
        setOpenEditDetail(true);
    };
    const handleQuestionInputChangeEdit = (e) => {
        const field = e.target.name;
        const nextQuestionEditFormState = {
            ...ratingQuestionFormEdit,
            [field]: e.target.value,
        };
        setRatingQuestioFormEdit(nextQuestionEditFormState);
    };

    const handleSubmitQuestion = (e) => {
        e.preventDefault();
        console.log("ratingQuestionForm :>> ", ratingQuestionForm);
        // setOpenViewDetail(false);
         dispatch(addRatingQuestion(ratingQuestionForm)).then(() => {
            dispatch(getRatingQuestions());
            setOpenViewDetail(false);
         });
        // const nextratingQuestionFormState = {
        //     ...ratingQuestionForm,
        // };
        // setratingQuestionForm(nextratingQuestionFormState);
        setRatingQuestionForm("");
    };
   
    const [selectedForDelete, setSelectedForDelete] = useState({});
    const selectForDelete = (question) => {
        setSelectedForDelete(question);
        console.log("selectedForCategory :>> ", question);
        setOpenDeleteDetail(true);
    };

    const handleDeleteQuestion = (id) => {
        dispatch(deleteRatingQuestion(id)).then(() => {
            dispatch(getRatingQuestions());
            setOpenDeleteDetail(false);
        });
    };

    // let rowsPerPage = 10;

    const handleSubmitRatingQuestionFormEdit = (e) => {
        e.preventDefault();
        console.log("ratingQuestionFormEdit :>> ", ratingQuestionFormEdit);
        // setOpenEditDetail(false)
        dispatch(editRatingQuestion(ratingQuestionFormEdit)).then(() => {
            dispatch(getRatingQuestions());
            setOpenEditDetail(false)
        });
    };

    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            Rating Questions
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <span className="float-right mb-3 mr-2">
                                        <button
                                            type="button"
                                            className="btn btn-primary mt-2"
                                            onClick={handleClickOpenViewDetail}
                                        >
                                            <i className="ri-add-fill">
                                                <span className="pl-1">
                                                    Add New
                                                </span>
                                            </i>
                                        </button>
                                    </span>
                                    {isLoading === 'loading' ?
                                    <div className="text-center">
                                        <div className="spinner-border text-danger">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <p className="small">Getting Questions...</p>
                                    </div> :
                                    allQuestions.length > 0 ? (
                                        <Table aria-labelledby="tableTitle">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="fw-bold">
                                                        S/N
                                                    </TableCell>
                                                    <TableCell className="fw-bold">
                                                        Question
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allQuestions.length > 0 &&
                                                    allQuestions.map(
                                                        (question, index) => (
                                                            <TableRow
                                                                hover
                                                                tabindex={-1}
                                                                key={index}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        index + 1
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        question.name.includes('?') ? question.name : question.name + '?'
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    <button
                                                                        className="btn btn-sm btn-success"
                                                                        onClick={() => {
                                                                            selectQuestionForEdit(
                                                                                question
                                                                            );
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    <button
                                                                        className="btn btn-sm btn-danger"
                                                                        onClick={() => {
                                                                            selectForDelete(
                                                                                question
                                                                            );
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                            </TableBody>
                                        </Table>
                                    ) : null}
                                </div>
                            </div>

                            {/* Add Question */}

                            <Dialog
                                open={openViewDetail}
                                onClose={handleCloseViewDetail}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/* <DialogTitle>Rider Details</DialogTitle> */}
                                <DialogContent>
                                    <DialogContentText>
                                        <div className="modalContent">
                                            <div className="modal-header">
                                                <h5
                                                    className="modal-title"
                                                    id="exampleModalCenteredScrollableTitle"
                                                >
                                                    Add Question
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        onClick={
                                                            handleCloseViewDetail
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form
                                                    onSubmit={
                                                        handleSubmitQuestion
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">
                                                            Question
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            value={
                                                                ratingQuestionForm.name
                                                            }
                                                            onChange={
                                                                handleQuestionInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="modal-footer px-0">
                                                        {isLoading === 'idle' && <button
                                                            type="submit"
                                                            className="table-add btn btn-primary"
                                                        >
                                                            Save
                                                        </button>}
                                                        {isLoading === 'loading' && <button
                                                            type="button"
                                                            className="table-add btn btn-primary"
                                                            disabled
                                                        >
                                                            Saving <i className="fas fa-spinner fa-spin"></i>
                                                        </button>}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                            {/* Edit Question */}

                            <Dialog
                                open={openEditDetail}
                                onClose={handleCloseEditDetail}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/* <DialogTitle>Rider Details</DialogTitle> */}
                                <DialogContent>
                                    <DialogContentText>
                                        <div className="modalContent">
                                            <div className="modal-header">
                                                <h5
                                                    className="modal-title"
                                                    id="exampleModalCenteredScrollableTitle"
                                                >
                                                    Edit Question
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        onClick={
                                                            handleCloseEditDetail
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form
                                                    onSubmit={
                                                        handleSubmitRatingQuestionFormEdit
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">
                                                            Question
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            value={
                                                                ratingQuestionFormEdit.name
                                                            }
                                                            onChange={
                                                                handleQuestionInputChangeEdit
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="modal-footer px-0">
                                                        {isLoading === 'idle' && <button
                                                            type="submit"
                                                            className="table-add btn btn-primary"
                                                        >
                                                            Save
                                                        </button>}
                                                        {isLoading === 'loading' && <button
                                                            type="button"
                                                            className="table-add btn btn-primary"
                                                            disabled
                                                        >
                                                            Please wait <i className="fas fa-spinner fa-spin"></i>
                                                        </button>}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                            {/* Delete Question */}
                            <Dialog
                                open={openDeleteDetail}
                                onClose={handleCloseDeleteDetail}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/* <DialogTitle>Rider Details</DialogTitle> */}
                                <DialogContent>
                                    <DialogContentText>
                                        <div className="modalContent">
                                            <div className="modal-header">
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        onClick={
                                                            handleCloseDeleteDetail
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div
                                                    className="alert alert-danger"
                                                    role="alert"
                                                >
                                                    <div className="mm-alert-text">
                                                        Do you want to delete
                                                        this question?
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Question
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.name
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer px-0">
                                                    {isLoading === 'idle' && <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                        onClick={() =>
                                                            handleDeleteQuestion(
                                                                selectedForDelete.id
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </button>}
                                                    {isLoading === 'loading' && <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                        disabled
                                                    >
                                                        Please wait <i className="fas fa-spinner fa-spin"></i>
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RatingQuestions;
