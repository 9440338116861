import {
  Box,
   Table,
   TableHead,
   TableBody,
   TableRow,
   TableCell,
   TablePagination,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogContentText,
 } from "@mui/material";
 import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux"
import { 
  getApprovedVehicles ,
  getVehicleTemplate,
  importVehicle
} from "../../state/actions";
import {
  getApprovedPartners
} from "../../state/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const ConfirmedVehicle = () => {
   const dispatch = useDispatch();
   const approvedVehicles = useSelector((state) => state.vehicles.approvedVehicles);
   const partners = useSelector((state) => state.partner.approvedPartners);
   const isLoading = useSelector((state) => state.vehicles.status)
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("")
   const [openViewDetail, setOpenViewDetail] = useState(false);
   const [importModal, setImportModal] = useState(false)
   const [partnerId, setPartnerId] = useState(null)
   const [uploadFile, setUploadFile] = useState(null);
   const handleClickOpenViewDetail = () => {
      setOpenViewDetail(true);
   };

   const handleCloseViewDetail = () => {
      setOpenViewDetail(false);
   };

   const openImportModal = () => {
    setImportModal(true)
  }
  const closeImportModal = () => {
    setImportModal(false)
    setPartnerId(null)
    setUploadFile(null)
  }

  const handleChange = (e) => {
    const { files } = e.target;
    setUploadFile(files[0])
    console.log('file', uploadFile)
  }

   useEffect(() => {
      dispatch(getApprovedVehicles())
      dispatch(getApprovedPartners())
   }, []);

   const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - approvedVehicles.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = (e) => {
    const { value } = e.target
    setSearchParam(value)
  }

   const [selectedVehicle, setSelectedVehicle] = useState({});
   const selectVehicle = (vehicle) => {
      setSelectedVehicle(vehicle);
      console.log("selectedVehicle :>> ", vehicle);
      setOpenViewDetail(true);
   };

   const handleVehicleTemplate = () => {
    dispatch(getVehicleTemplate())
  }

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setPartnerId(value)
  }

  const handleImport = () => {
    var id = parseInt(partnerId)
    var file = uploadFile
    dispatch(importVehicle(id, file)).then(() => {
      dispatch(getApprovedVehicles());
      setImportModal(false);
      setPartnerId(null)
    })
  }
  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Confirmed Vehicles</h4>
                  </div>
                  <button
                    type="button"
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      openImportModal();
                    }}
                  >
                    Import
                  </button>
                </div>
                <div className="card-body">
                  <div>
                    {isLoading === 'loading' ?
                    <div className="text-center">
                      <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p className="small">Getting Approved Vehicles...</p>
                    </div> :
                    approvedVehicles.length > 0 ? (
                      <>
                    <Box>
                      <div className="form-group">
                        <label className="form-label">Search</label>
                        <input type="text" onChange={search} className="form-control form-control-sm" />
                      </div>
                    </Box>
                      <Table aria-labelledby="tableTitle">
                        <TableHead>
                          <TableRow>
                            <TableCell>Registration No.</TableCell>
                            <TableCell>Vehicle Brand</TableCell>
                            <TableCell>Vehicle Model</TableCell>
                            <TableCell>Vehicle Type</TableCell>
                            <TableCell>Vehicle Year</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? approvedVehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : approvedVehicles
                            )?.filter((o) =>
                              o.regNo.toLowerCase().includes(searchParam.toLowerCase()) ||
                              o.vehicleBrand?.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
                              o.model?.name?.toLowerCase().includes(searchParam.toLowerCase())
                            )?.map(
                              (
                                approvedVehicle,
                                index
                              ) => (
                                <TableRow
                                  hover
                                  tabindex={-1}
                                  key={index}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                      approvedVehicle.regNo
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                      approvedVehicle.vehicleBrand.name
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                      approvedVehicle.model.name
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                      approvedVehicle.vehicleType.name
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    {
                                      approvedVehicle.year
                                    }
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      // width: "5% !important",
                                      padding: "0.5",
                                      margin: "0 !important"
                                    }}
                                  >
                                    <span className="badge badge-success">
                                      Approved
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      padding: "0 !important",
                                      margin: "0 !important"
                                    }}
                                  >
                                      <button
                                      type="button"
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        selectVehicle(
                                          approvedVehicle
                                        );
                                      }}
                                      >
                                        View Details
                                      </button>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>
                      </>
                    ) :
                    <div className="text-center">
                      <p>No Vehicle Available</p>
                    </div>
                    }
                  </div>
                  <div>
                    <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
                    component="div"
                    count={approvedVehicles.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openViewDetail}
        onClose={handleCloseViewDetail}
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <DialogTitle>Rider Details</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
          <div className="modal-content">
            <div className="modal-header">
            <h5
              className="modal-title"
              id="exampleModalCenteredScrollableTitle"
            >
              Vehicle Details
            </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span 
                aria-hidden="true"
                onClick={handleCloseViewDetail}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">

              <div className="media align-items-start">
                <div className="media-body ml-2 row">
                  <div className="col-md-8">
                    <p className="mb-0 mt-1">{ selectedVehicle.regNo }</p>
                    <p className="mb-0 font-weight-bold">
                     <span className="">
                     { 
                        selectedVehicle &&
                        selectedVehicle.vehicleType &&
                        selectedVehicle.vehicleType.name
                     }
                     </span>
                     <span className="ml-2">
                     [
                        { 
                        selectedVehicle &&
                        selectedVehicle.vehicleSize &&
                        selectedVehicle.vehicleSize.name
                       }
                     ]
                     </span>
                     {/* selectedVehicle.vehicleType.name */}
                    </p>
                  </div>
                  {/* <div className="col-md-3 pt-2">
                    <button type="button" className="btn btn-primary btn-sm">
                      Disable
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Brand</p>
                      <span className="font-weight-bold d-block">
                      { 
                            selectedVehicle &&
                            selectedVehicle.vehicleBrand &&
                            selectedVehicle.vehicleBrand.name
                        }
                      {/* selectedVehicle.vehicleBrand.name */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">Model</p>
                      <span className="font-weight-bold d-block">
                        { 
                            selectedVehicle &&
                            selectedVehicle.model &&
                            selectedVehicle.model.name
                        }
                      {/* selectedVehicle.model.name */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-4">
              <div className="media align-items-start mt-4">
                <div className="media-body">
                  <p className="text mb-0">Year</p>
                  <span className="font-weight-bold d-block">
                     { selectedVehicle.year }
                  </span>
                </div>
              </div>
                </div>
              </div>
              <div className="media align-items-start mt-4">
                <Table aria-labelledby="tableTitle">
                    <TableBody>
                      <TableRow>
                          <TableCell
                          component="th"
                          >
                            1
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            Road Worthiness
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            <span className="badge badge-success">Approved</span>
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            <button
                            className="btn btn-secondary btn-sm">
                                Open
                            </button>
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell
                          component="th"
                          >
                            2
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            Vehicle Registration
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            <span className="badge badge-success">Approved</span>
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            <button
                            className="btn btn-secondary btn-sm">
                                Open
                            </button>
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell
                          component="th"
                          >
                            3
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            Road Insurance
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            <span className="badge badge-success">Approved</span>
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            <button
                            className="btn btn-secondary btn-sm">
                                Open
                            </button>
                          </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell
                          component="th"
                          >
                            4
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            Hackney Permit
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            <span className="badge badge-success">Approved</span>
                          </TableCell>
                          <TableCell
                          component="th"
                          >
                            <button
                            className="btn btn-secondary btn-sm">
                                Open
                            </button>
                          </TableCell>
                      </TableRow>
                    </TableBody>
                </Table>
              </div>
                {/* <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Inspection Note</p>
                    <textarea
                    className="form-control"
                    cols="30"
                    rows="5"
                    name="inspectionNote"
                  //   value={ selectedVehicle.inspectionNote }
                    
                  //   onChange={ handleVehicleInspectionInputChange }
                    ></textarea>
                  </div>
                </div> */}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Import Dialog */}
      <Dialog
        open={importModal}
        onClose={closeImportModal}
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <DialogTitle>Rider Details</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <div className="modal-content">
              <div className="modal-header">
                <p
                  className="modal-title"
                  id="exampleModalCenteredScrollableTitle"
                >
                  Import Vehicles
                </p>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={closeImportModal}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
              ` <p className="">
                  You are expected to maintain the format in the
                  template, all number or code column in the first
                  sheet have their corresponding value in the next
                  sheet. Kindly confirm all data before upload to
                  avoid mistake. Any incorrect data in the
                  spreadsheet might lead to upload error.
                </p>
                <div className="">
                  <button
                    class='btn btn-primary add-stock-btn px-4 mb-3 w-100'
                    onClick={() => handleVehicleTemplate()}
                  >
                      Download Template
                  </button>
                </div>
                <div>
                  <div class="form-group in-border">
                    <label for="partnerId">Select Partner</label>
                    <select
                    class="form-select custom-select shadow-none"
                    id="partnerId"
                    name="partnerId"
                    value={partnerId}
                    onChange={(e) => handleSelectChange(e)}
                    >
                      <option selected>Please Select</option>
                      { partners.map(p => (
                        <option key={p.id} value={p.id}>
                          { p.companyName }
                        </option>
                      ))}
                    </select>
                  </div>
                  {partnerId && <div class="upload-file mb-4">
                    <label for="partnerId">Select File (Excel)</label>
                    <input class="form-control form-control-sm" 
                    name="uploadFile" 
                    id="uploadFile" 
                    type="file" 
                    onChange={handleChange}
                    />
                  </div>}
                  {uploadFile && <div>
                    { isLoading === 'idle' && <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleImport}
                      >
                        Import
                      </button>}
                      { isLoading === 'loading' && <button
                        type="button"
                        className="btn btn-success"
                        disabled
                      >
                        Uploadind File <i className="fas fa-spinner fa-spin"></i>
                      </button>}
                  </div>}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default ConfirmedVehicle;
