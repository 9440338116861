import React from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";

const UnconfirmedPartner = () => {
    return (
        <>
            <NavBar />
            <SideBar />

            <div class="content-page">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between">
                                    <div class="header-title">
                                        <h4 class="card-title">
                                            Unconfirmed Partners
                                        </h4>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="collapse" id="datatable-1">
                                        <div class="card"></div>
                                    </div>
                                    <div class="table-responsive">
                                        <table
                                            id="datatable"
                                            class="table data-table table-striped table-bordered"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>Address</th>
                                                    <th>Destination</th>
                                                    <th>Service</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>B56</td>
                                                    <td>Meuve Enterprise</td>
                                                    <td>Allen Ikeja, Lagos</td>
                                                    <td>
                                                        Ota-ona Ikorodu, Lagos
                                                    </td>
                                                    <td>Agency</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>B78</td>
                                                    <td>Josh & Co.</td>
                                                    <td>Ojo, Ibadan</td>
                                                    <td>Berger, Lagos</td>
                                                    <td>Agency</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>B82</td>
                                                    <td>Mint Cargo Ltd</td>
                                                    <td>Apapa, Lagos</td>
                                                    <td>Eti-osa, Lagos</td>
                                                    <td>Agency</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>D45</td>
                                                    <td>Murphy Nig. Ltd</td>
                                                    <td>Apapa, Lagos</td>
                                                    <td>Ijede, Lagos</td>
                                                    <td>Agency</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>E78</td>
                                                    <td>
                                                        Eddison Courier Services
                                                    </td>
                                                    <td>Apapa, Lagos</td>
                                                    <td>Oyo-Town, Oyo</td>
                                                    <td>Agency</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UnconfirmedPartner;
