import {
    approvedPartnersConstants,
    approvePartnerConstants,
    newPartnersConstants,
    partnersIncomesConstants,
    topPartnersConstants,
    verifiedPartnersConstants,
    verifyPartnerConstants,
} from "../actions/constants";

const initState = {
    partnersIncomes: [],
    newPartners: [],
    verifiedPartners: [],
    approvedPartners: [],
    topPartners: [],
    status: 'idle',
    error: {},
};

// export const partnersIncomesReducer = (state = initState, action) => {
//     switch (action.type) {
//         case partnersIncomesConstants.GET_PARTNERS_INCOMES_REQUEST:
//             return (state = { ...state });
//         case partnersIncomesConstants.GET_PARTNERS_INCOMES_SUCCESS:
//             return (state = {
//                 ...state,
//                 partnersIncomes: action.payload.partnersIncomes,
//             });

//         default:
//             return { ...state };
//     }
// };

export const partnersReducer = (state = initState, action) => {
    switch (action.type) {
        case partnersIncomesConstants.GET_PARTNERS_INCOMES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case partnersIncomesConstants.GET_PARTNERS_INCOMES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                partnersIncomes: action.payload.partnersIncomes,
            });
        case newPartnersConstants.GET_NEW_PARTNERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case newPartnersConstants.GET_NEW_PARTNERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                newPartners: action.payload.newPartners,
            });
        case verifiedPartnersConstants.GET_VERIFIED_PARTNERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case verifiedPartnersConstants.GET_VERIFIED_PARTNERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                verifiedPartners: action.payload.verifiedPartners,
            });
        case approvedPartnersConstants.GET_APPROVED_PARTNERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case approvedPartnersConstants.GET_APPROVED_PARTNERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                approvedPartners: action.payload.approvedPartners,
            });
        case topPartnersConstants.GET_TOP_PARTNERS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
            });
        case topPartnersConstants.GET_TOP_PARTNERS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                topPartners: action.payload.topPartners
            });

        case verifyPartnerConstants.VERIFY_PARTNER_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
            });
        case verifyPartnerConstants.VERIFY_PARTNER_SUCCESS:
            return (state = { 
                ...state,
                status: 'idle'
             });

        case approvePartnerConstants.APPROVE_PARTNER_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case approvePartnerConstants.APPROVE_PARTNER_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
             });

        default:
            return { ...state };
    }
};
