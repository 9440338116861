import {
  Dialog,
  DialogContent,
  DialogContentText,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewVehicles,
  inspectVehicle,
  verifyVehicle
} from "../../state/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const UnverifiedVehicle = () => {
  const dispatch = useDispatch();
  const newVehicles = useSelector((state) => state.vehicles.newVehicles);
  const partners = useSelector((state) => state.partner.approvedPartners);
  const isLoading = useSelector((state) => state.vehicles.status)
  const [page, setPage] = useState(0);
  const [importModal, setImportModal] = useState(false)
  const [partnerId, setPartnerId] = useState(null)
  const [uploadFile, setUploadFile] = useState();
  const [openViewDetail, setOpenViewDetail] = useState(false);
  const [openInspectDetail, setOpenInspectDetail] = useState(false);

  const handleCloseViewDetail = () => {
    setOpenViewDetail(false);
  };

  const handleCloseInspectDetail = () => {
    setOpenInspectDetail(false);
  };

  useEffect(() => {
    dispatch(getNewVehicles())
  }, []);

  const handlePageChange = useCallback(
    (_, page) => {
      setPage(page);
    },
    [setPage]
  );

  function vehicleStatus(status) {
    switch (status) {
      case 0:
        return (<span className="badge badge-secondary">Pending</span>);
      case 1:
        return (<span className="badge badge-success">Approved</span>);
      case 2:
        return (<span className="badge badge-info">Awaiting Inspection</span>);
      case 3:
        return (<span className="badge badge-warning">Awaiting Approval</span>);
      default:
        return (<span className="badge badge-secondary">Pending</span>);
    }
  }

  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [selectedInspectVehicle, setSelectedInspectVehicle] = useState({});

  const selectVehicle = (vehicle) => {
    setSelectedVehicle(vehicle);
    console.log("selectedVehicle :>> ", vehicle);
    setOpenViewDetail(true);
  };

  const handleVehicleVerification = (id) => {
    dispatch(verifyVehicle(id)).then(() => {
      dispatch(getNewVehicles());
      setOpenViewDetail(false);
    });
  };

  const selectInspectVehicle = (vehicle) => {
    setSelectedInspectVehicle(vehicle);
    console.log("selectedInspectVehicle :>> ", vehicle);
    setOpenInspectDetail(true);
  };

  const [inspectionNote, setInspectionNote] = useState("");

  const handleSubmitVehicleInspectionForm = (e) => {
    e.preventDefault();
    const v = {
      id: selectedInspectVehicle.id,
      inspectionNote,
    };
    console.log("v :>> ", v);
    dispatch(inspectVehicle(v)).then(() => {
      dispatch(getNewVehicles());
      setOpenInspectDetail(false);
    });
  };

  

  let rowsPerPage = 10;
  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Unverified Vehicles</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    {isLoading === 'loading' ?
                    <div className="text-center">
                      <div className="spinner-border text-danger">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p className="small">Getting Vehicles...</p>
                    </div> :
                    newVehicles.length > 0 ? (
                      <Table aria-labelledby="tableTitle">
                        <TableHead>
                          <TableRow>
                            <TableCell>Registration No.</TableCell>
                            <TableCell>Vehicle Brand</TableCell>
                            <TableCell>Vehicle Model</TableCell>
                            <TableCell>Vehicle Type</TableCell>
                            <TableCell>Vehicle Year</TableCell>
                            <TableCell>Verification Status</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {newVehicles.length > 0 &&
                            newVehicles.map((newVehicle, index) => (
                              <TableRow hover tabindex={-1} key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {newVehicle.regNo}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {newVehicle.vehicleBrand.name}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {newVehicle.model.name}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {newVehicle.vehicleType.name}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {newVehicle.year}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    // width: "5% !important",
                                    padding: "0.5",
                                    margin: "0 !important",
                                  }}
                                >
                                  {vehicleStatus(newVehicle.status)}
                                  {/* 
                                      (newVehicle.status) === 0 ?
                                      <span class="badge badge-warning">Pending</span> : 
                                      <span class="badge badge-info">Awaiting Inspection</span> 
                                    */}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    margin: "0 !important",
                                    padding: "0 !important",
                                  }}
                                >
                                  {newVehicle &&
                                    newVehicle.status === 0 && (
                                    <button
                                      className="btn btn-sm btn-primary w-100"
                                      onClick={() => {
                                        selectVehicle(newVehicle);
                                      }}
                                    >
                                      Verify
                                    </button>
                                  )}
                                  {newVehicle &&
                                    newVehicle.status &&
                                    newVehicle.status === 2 && (
                                      <button
                                        className="btn btn-sm btn-primary w-100"
                                        onClick={() => {
                                          selectInspectVehicle(newVehicle);
                                        }}
                                      >
                                        Inspect Vehicle
                                      </button>
                                    )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    ) : 
                    <div className="text-center">
                      <p>No New Vehcile Available</p>
                    </div>
                    }
                  </div>
                  <div>
                    <TablePagination
                      component="div"
                      count={newVehicles.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page",
                      }}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Verify Dialog */}
      <Dialog
        open={openViewDetail}
        onClose={handleCloseViewDetail}
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <DialogTitle>Rider Details</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <div className="modal-content">
              <div className="modal-header">
                <p
                  className="modal-title"
                  id="exampleModalCenteredScrollableTitle"
                >
                  Stage 1 Verification
                </p>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={handleCloseViewDetail}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="alert alert-info" role="alert">
                  <div className="mm-alert-text">Verify vehicle documents</div>
                </div>

                <div className="media align-items-start">
                  <div className="media-body ml-2 row">
                    <div className="col-md-8">
                      <p className="mb-0 mt-1">{selectedVehicle.regNo}</p>
                      <p className="mb-0 font-weight-bold justify-content-between">
                        <span className="">
                          {selectedVehicle &&
                            selectedVehicle.vehicleType &&
                            selectedVehicle.vehicleType.name}
                        </span>
                        <span className="ml-2">
                          [
                          {selectedVehicle &&
                            selectedVehicle.vehicleSize &&
                            selectedVehicle.vehicleSize.name}
                          ]
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Brand</p>
                    <span className="font-weight-bold d-block">
                      {selectedVehicle &&
                        selectedVehicle.vehicleBrand &&
                        selectedVehicle.vehicleBrand.name}
                      {/* selectedVehicle.vehicleBrand.name */}
                    </span>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Model</p>
                    <span className="font-weight-bold d-block">
                      {selectedVehicle &&
                        selectedVehicle.model &&
                        selectedVehicle.model.name}
                      {/* selectedVehicle.model.name */}
                    </span>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Year</p>
                    <span className="font-weight-bold d-block">
                      {selectedVehicle.year}
                    </span>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <Table aria-labelledby="tableTitle">
                    <TableBody>
                      
                      <TableRow>
                        <TableCell component="th">
                            1
                        </TableCell>
                        <TableCell component="th">
                            Road Worthiness
                        </TableCell>
                        <TableCell component="th">
                            <span className="badge badge-info">
                                Pending
                            </span>
                        </TableCell>
                        <TableCell component="th">
                            <a
                                href={`${selectedVehicle.roadWorthinessUrl}`}
                                className="btn btn-secondary btn-sm"
                            >
                                Open
                            </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">
                            2
                        </TableCell>
                        <TableCell component="th">
                            Vehicle Registration
                        </TableCell>
                        <TableCell component="th">
                            <span className="badge badge-info">
                                Pending
                            </span>
                        </TableCell>
                        <TableCell component="th">
                            <button className="btn btn-secondary btn-sm">
                                Open
                            </button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th">
                            3
                        </TableCell>
                        <TableCell component="th">
                            Road Insurance
                        </TableCell>
                        <TableCell component="th">
                            <span className="badge badge-info">
                                Pending
                            </span>
                        </TableCell>
                        <TableCell component="th">
                            <button className="btn btn-secondary btn-sm">
                                Open
                            </button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th">
                            4
                        </TableCell>
                        <TableCell component="th">
                            Hackney Permit
                        </TableCell>
                        <TableCell component="th">
                            <span className="badge badge-info">
                                Pending
                            </span>
                        </TableCell>
                        <TableCell component="th">
                            <button className="btn btn-secondary btn-sm">
                                Open
                            </button>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-2">
                    { isLoading === 'idle' && <button
                      type="button"
                      className="btn btn-success"
                      onClick={() =>
                        handleVehicleVerification(selectedVehicle.id)
                      }
                    >
                      Verify
                    </button>}
                    { isLoading === 'loading' && <button
                      type="button"
                      className="btn btn-success"
                      disabled
                    >
                      Verifying <i className="fas fa-spinner fa-spin"></i>
                    </button>}
                    <button type="button" className="btn btn-primary mx-3">
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Inspect Dialog */}
      <Dialog
        open={openInspectDetail}
        onClose={handleCloseInspectDetail}
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <DialogTitle>Rider Details</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <div className="modal-content">
              <div className="modal-header">
                <p
                  className="modal-title"
                  id="exampleModalCenteredScrollableTitle"
                >
                  Stage 2 Verification
                </p>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={handleCloseInspectDetail}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="alert alert-info" role="alert">
                  <div className="mm-alert-text">Inspect vehicle condition</div>
                </div>

                <div className="media align-items-start">
                  <div className="media-body ml-2 row">
                    <div className="col-md-8">
                      <p className="mb-0 mt-1">
                        {selectedInspectVehicle.regNo}
                      </p>
                      <p className="mb-0 font-weight-bold">
                        <span className="">
                          {selectedInspectVehicle &&
                            selectedInspectVehicle.vehicleType &&
                            selectedInspectVehicle.vehicleType.name}
                        </span>
                        <span className="">
                          [
                          {selectedInspectVehicle &&
                            selectedInspectVehicle.vehicleSize &&
                            selectedInspectVehicle.vehicleSize.name}
                          ]
                        </span>
                      </p>
                    </div>
                    <div className="col-md-3 pt-2">
                      <button type="button" className="btn btn-primary btn-sm">
                        Disable
                      </button>
                    </div>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Brand</p>
                    <span className="font-weight-bold d-block">
                      {selectedInspectVehicle &&
                        selectedInspectVehicle.vehicleBrand &&
                        selectedInspectVehicle.vehicleBrand.name}
                    </span>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Model</p>
                    <span className="font-weight-bold d-block">
                      {selectedInspectVehicle &&
                        selectedInspectVehicle.model &&
                        selectedInspectVehicle.model.name}
                    </span>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <p className="text mb-0">Year</p>
                    <span className="font-weight-bold d-block">
                      {selectedInspectVehicle.year}
                    </span>
                  </div>
                </div>
                <div className="media align-items-start mt-4">
                  <Table aria-labelledby="tableTitle">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th">1</TableCell>
                        <TableCell component="th">Road Worthiness</TableCell>
                        <TableCell component="th">
                          <span className="badge badge-info">Pending</span>
                        </TableCell>
                        <TableCell component="th">
                          <button className="btn btn-secondary btn-sm">
                            Open
                          </button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">2</TableCell>
                        <TableCell component="th">
                          Vehicle Registration
                        </TableCell>
                        <TableCell component="th">
                          <span className="badge badge-info">Pending</span>
                        </TableCell>
                        <TableCell component="th">
                          <button className="btn btn-secondary btn-sm">
                            Open
                          </button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">3</TableCell>
                        <TableCell component="th">Road Insurance</TableCell>
                        <TableCell component="th">
                          <span className="badge badge-info">Pending</span>
                        </TableCell>
                        <TableCell component="th">
                          <button className="btn btn-secondary btn-sm">
                            Open
                          </button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">4</TableCell>
                        <TableCell component="th">Hackney Permit</TableCell>
                        <TableCell component="th">
                          <span className="badge badge-info">Pending</span>
                        </TableCell>
                        <TableCell component="th">
                          <button className="btn btn-secondary btn-sm">
                            Open
                          </button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="media align-items-start mt-4">
                  <div className="media-body">
                    <form onSubmit={handleSubmitVehicleInspectionForm}>
                      <p className="text mb-0">Inspection Note</p>
                      <textarea
                        className="form-control"
                        cols="30"
                        rows="5"
                        name="inspectionNote"
                        // value={ vehicleInspectionForm.inspectionNote }
                        value={inspectionNote}
                        onChange={(e) => setInspectionNote(e.target.value)}
                        // onChange={ handleVehicleInspectionInputChange }
                      ></textarea>
                      <div className="row">
                        <div className="col-md-12 pt-2">
                          { isLoading === 'idle' && <button
                            type="submit"
                            className="btn btn-success"
                          >
                            Submit
                          </button>}
                          { isLoading === 'loading' && <button
                            type="button"
                            className="btn btn-success"
                            disabled
                          >
                            Please wait... <i className="fas fa-spinner fa-spin"></i>
                          </button>}
                          <button type="button" className="btn btn-primary mx-3">
                            Reject
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="row">
                <div className="col-md-12 pt-2">
                  <button 
                  type="button" 
                  className="btn btn-success"
                  onClick={() => handleVehicleVerification(selectedInspectVehicle.id)}
                  >
                    Complete
                  </button>
                  <button type="button" className="btn btn-primary">
                    Reject
                  </button>
                </div>
              </div> */}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default UnverifiedVehicle;
