import React from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";

const ConfirmedPartner = () => {

    return(
        <>
        <NavBar/>
        <SideBar/>

        <div class="content-page">
<div class="container-fluid">
<div class="row">
<div class="col-sm-12">
<div class="card">
<div class="card-header d-flex justify-content-between">
<div class="header-title">
<h4 class="card-title">Confirmed Partners</h4>
</div>
</div>
<div class="card-body">
<div class="collapse" id="datatable-1">
<div class="card"></div>
</div>
<div class="table-responsive">
<table id="datatable" class="table data-table table-striped table-bordered">
<thead>
<tr>
   <th>ID</th>
   <th>Name</th>
   <th>Address</th>
   <th>City</th>
   <th>State</th>
   <th>Email</th>
   <th>Service</th>
   <th></th>
</tr>
</thead>
<tbody>
<tr>
   <td>A01</td>
   <td>Meuve Enterprise</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Ikeja</td>
   <td>Lagos</td>
   <td>meuveenterprise@gmail.com</td>
   <td>Agency</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>A24</td>
   <td>Josh & Co.</td>
   <td>58, Railway Line</td>
   <td>Ibadan</td>
   <td>Oyo</td>
   <td>joshcarriers@outlook.com</td>
   <td>CSP</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>A38</td>
   <td>Mint Cargo Ltd</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Victoria Island</td>
   <td>Lagos</td>
   <td>meuveenterprise@gmail.com</td>
   <td>Agency</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>B21</td>
   <td>Murphy Nig Ltd</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Ikotun</td>
   <td>Lagos</td>
   <td>meuveenterprise@gmail.com</td>
   <td>Agency</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>B34</td>
   <td>Eddison Courier Service</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Ikeja</td>
   <td>Lagos</td>
   <td>meuveenterprise@gmail.com</td>
   <td>CSP</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>C11</td>
   <td>Murphy Nig. Ltd</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Challenge</td>
   <td>Ibadan</td>
   <td>meuveenterprise@gmail.com</td>
   <td>CSP</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>C14</td>
   <td>Josh & Co.</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Ikeja</td>
   <td>Lagos</td>
   <td>meuveenterprise@gmail.com</td>
   <td>Agency</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>D02</td>
   <td>Mint Cargo Ltd</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Ogbomoso</td>
   <td>Oyo</td>
   <td>meuveenterprise@gmail.com</td>
   <td>Agency</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>D23</td>
   <td>Meuve Enterprise</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Minna</td>
   <td>Niger</td>
   <td>meuveenterprise@gmail.com</td>
   <td>CSP</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
<tr>
   <td>E05</td>
   <td>Eddison Courier Services</td>
   <td>35, Wagmi Industrial Estate</td>
   <td>Ikorodu</td>
   <td>Lagos</td>
   <td>meuveenterprise@gmail.com</td>
   <td>Agency</td>
   <td>
      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#orderDetails1">
         View Details
      </button>
   </td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</div>



        </>
    )
}

export default ConfirmedPartner;