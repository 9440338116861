import { mapConstants } from "../actions/constants";

const initState = {
    placeId: null,
    location: {},
    status: 'idle'
};

export const mapReducer = (state = initState, action) => {
    switch (action.type) {
        case mapConstants.SUGGEST_ADDR_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case mapConstants.SUGGEST_ADDR_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
                // placeId: action.payload.placeId,
            });
        case mapConstants.ADDR_DETAIL_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
                });
        case mapConstants.ADDR_DETAIL_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                location: action.payload.location,
            });

        default:
            return { ...state };
    }
};
