import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { signupAsAdmin } from "../../state/actions/account.actions";
import "./signup.css";
import SignupSigninFooter from "./SignupSigninFooter";

const Signup = () => {
    const dispatch = useDispatch();

    const [signupForm, setSignupForm] = useState({
        fullName: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const handleSignupInputForm = (e) => {
        const field = e.target.name;
        const nextFormState = {
            ...signupForm,
            [field]: e.target.value,
        };
        setSignupForm(nextFormState);
    };
    const handleSubmitSignupForm = (e) => {
        e.preventDefault();
        if (signupForm.password === signupForm.confirmPassword) {
            console.log("signupForm :>> ", signupForm);
            dispatch(signupAsAdmin(signupForm));
        } else {
            console.log("password and confirm passsword do not match");
        }
    };
    return (
        <div>
            <div className="signup col-12">
                <div className="signup-card col-4 mx-auto border">
                    <div className="signup-header">
                        <div className="signup-logo my-3">
                            <img
                                src="assets/images/Redspeed-black.png"
                                className="img-fluid rounded-normal light-logo"
                                alt="logo"
                            />
                        </div>
                    </div>

                    <form onSubmit={handleSubmitSignupForm} className="mx-4">
                        <div>
                            <div className="form-row d-block">
                                <div className="form-group">
                                    <label for="validationDefault01">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        name="fullName"
                                        value={signupForm.oldPassword}
                                        onChange={handleSignupInputForm}
                                        placeholder="Full Name"
                                        className="form-control"
                                        id="validationDefault01"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="validationDefault02">
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        value={signupForm.newPassword}
                                        onChange={handleSignupInputForm}
                                        placeholder="Phone Number"
                                        className="form-control"
                                        id="validationDefault02"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="validationDefault02">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={signupForm.newPassword}
                                        onChange={handleSignupInputForm}
                                        placeholder="Email Address"
                                        className="form-control"
                                        id="validationDefault02"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="validationDefaultUsername">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={signupForm.password}
                                        onChange={handleSignupInputForm}
                                        placeholder="Password"
                                        className="form-control"
                                        id="validationDefaultUsername"
                                        aria-describedby="inputGroupPrepend2"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="validationDefaultUsername">
                                        Confirm Password
                                    </label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        value={signupForm.confirmPassword}
                                        onChange={handleSignupInputForm}
                                        placeholder="Confirm Password"
                                        className="form-control"
                                        id="validationDefaultUsername"
                                        aria-describedby="inputGroupPrepend2"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary my-2 px-4"
                                    >
                                        Signup
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="mx-5">
                <SignupSigninFooter />
            </div>
        </div>
    );
};

export default Signup;
