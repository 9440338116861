import {
    categoriesConstants,
    changePasswordConstants,
    getVehicleSizeConstants,
    getVehicleTypeConstants,
    getVehicleBrandConstants,
    getVehicleModelConstants,
    deleteVehicleModelConstants,
    editVehicleModelConstants,
    addVehicleModelConstants,
    deleteVehicleBrandConstants,
    editVehicleBrandConstants,
    addVehicleBrandConstants,
    deleteVehicleSizeConstants,
    editVehicleSizeConstant,
    deleteVehicleType,
    editVehicleType,
    addVehicleTypeConstant,
    addVehicleSizeConstants,
    ratesConstants,
    ratingQuestions
} from "../actions/constants";

const initState = {
    category: {},
    categories: [],
    vehicleTypes: [],
    vehicleSize: [],
    vehicleBrand: [],
    vehicleModel: [],
    newRates: [],
    questions: [],
    status: 'idle',
    error: {},

};

export const settingsReducer = (state = initState, action) => {
    switch (action.type) {
        case categoriesConstants.GET_CATEGORIES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case categoriesConstants.GET_CATEGORIES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                categories: action.payload.categories,
            });
        case categoriesConstants.POST_CATEGORIES_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case categoriesConstants.POST_CATEGORIES_SUCCESS:
            return (state = {
                ...state,
                // category: action.payload,
                // categories: action.payload,
                status: 'idle',
            });
        case categoriesConstants.UPDATE_CATEGORIES_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case categoriesConstants.UPDATE_CATEGORIES_SUCCESS:
            return (state = {
                ...state,
                // category: action.payload,
                status: 'idle',
            });
        case categoriesConstants.DELETE_CATEGORIES_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case categoriesConstants.DELETE_CATEGORIES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
            })
        case ratingQuestions.GET_RATING_QUESTIONS_REQUEST:
            return (state = {
                ...state,
            })
        case ratingQuestions.GET_RATING_QUESTIONS_SUCCESS:
            return (state = {
                ...state,
                questions: action.payload.questions,
            })
        case ratingQuestions.ADD_RATING_QUESTIONS_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case ratingQuestions.ADD_RATING_QUESTIONS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
            });
        case ratingQuestions.EDIT_RATING_QUESTIONS_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case ratingQuestions.EDIT_RATING_QUESTIONS_SUCCESS:
            return (state = {
                ...state,
                // category: action.payload,
                status: 'idle',
            });
        case ratingQuestions.DELETE_RATING_QUESTIONS_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case ratingQuestions.DELETE_RATING_QUESTIONS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
            })
        case ratesConstants.GET_RATES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case ratesConstants.GET_RATES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                newRates: action.payload.newRates,
            });
        case ratesConstants.ADD_RATES_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case ratesConstants.ADD_RATES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });
        case ratesConstants.UPDATE_RATE_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case ratesConstants.UPDATE_RATE_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
            });
        case ratesConstants.DELETE_RATE_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case ratesConstants.DELETE_RATE_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            })
        case getVehicleTypeConstants.GET_VEHICLE_TYPES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case getVehicleTypeConstants.GET_VEHICLE_TYPES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                vehicleTypes: action.payload.data,
            });
        case addVehicleTypeConstant.ADD_VEHICLE_MODEL_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case addVehicleTypeConstant.ADD_VEHICLE_MODEL_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            })
        case editVehicleType.EDIT_VEHICLE_MODEL_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case editVehicleType.EDIT_VEHICLE_MODEL_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });
        case deleteVehicleType.DELETE_VEHICLE_MODEL_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case deleteVehicleType.DELETE_VEHICLE_MODEL_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            })

        case getVehicleSizeConstants.GET_VEHICLE_SIZE_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case getVehicleSizeConstants.GET_VEHICLE_SIZE_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                vehicleSize: action.payload.data,
            });
        case addVehicleSizeConstants.ADD_VEHICLE_SIZE_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case addVehicleSizeConstants.ADD_VEHICLE_SIZE_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });
        case editVehicleSizeConstant.EDIT_VEHICLE_SIZE_REQUEST:
            return (state = {
                ...state,
                status: 'loading',
            });
        case editVehicleSizeConstant.EDIT_VEHICLE_SIZE_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });
        case deleteVehicleSizeConstants.DELETE_VEHICLE_SIZE_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case deleteVehicleSizeConstants.DELETE_VEHICLE_SIZE_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            })
        
        case getVehicleBrandConstants.GET_VEHICLE_BRANDS_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case getVehicleBrandConstants.GET_VEHICLE_BRANDS_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                vehicleBrand: action.payload.data,
            });
        case addVehicleBrandConstants.ADD_VEHICLE_BRAND_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            })
        case addVehicleBrandConstants.ADD_VEHICLE_BRAND_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });
        case editVehicleBrandConstants.EDIT_VEHICLE_BRAND_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case editVehicleBrandConstants.EDIT_VEHICLE_BRAND_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            });
        case deleteVehicleBrandConstants.DELETE_VEHICLE_BRAND_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case deleteVehicleBrandConstants.DELETE_VEHICLE_BRAND_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            })

        case getVehicleModelConstants.GET_VEHICLE_MODEL_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
            });
        case getVehicleModelConstants.GET_VEHICLE_MODEL_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                vehicleModel: action.payload.data,
            });
        case addVehicleModelConstants.ADD_VEHICLE_MODEL_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case addVehicleModelConstants.ADD_VEHICLE_MODEL_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            })
        case editVehicleModelConstants.EDIT_VEHICLE_MODEL_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case editVehicleModelConstants.EDIT_VEHICLE_MODEL_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            })
        case deleteVehicleModelConstants.DELETE_VEHICLE_MODEL_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            });
        case deleteVehicleModelConstants.DELETE_VEHICLE_MODEL_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
            })
        case changePasswordConstants.CHANGE_PASSWORD_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case changePasswordConstants.CHANGE_PASSWORD_SUCCESS:
            break;
        case changePasswordConstants.CHANGE_PASSWORD_FAILURE:
            break;
        default:
            return { ...state };
    }
};

export const changePasswordReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return { ...state };
    }
};
