import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    unstable_HistoryRouter,
} from "react-router-dom";
import Home from "./pages/Homepage/home";
import IncidentReport from "./pages/TripAdministration/IncidentReport";
import TripOverview from "./pages/TripAdministration/TripOverview";
import OrderList from "./pages/OrderManagement/OrderList";
import Customer from "./pages/CustomerManagement/customer";
import OrderOverview from "./pages/OrderManagement/OrderOverview";
import ConfirmedPartner from "./pages/PartnerManagement/confirmedPartner";
import UnverifiedPartner from "./pages/PartnerManagement/newPartner";
import UnconfirmedPartner from "./pages/PartnerManagement/unconfirmedPartner";
import PartnerOverview from "./pages/PartnerManagement/partnerOverview";
import ConfirmedVehicle from "./pages/VehicleManagement/confirmedVehicle";
import UnconfirmedVehicle from "./pages/VehicleManagement/unconfirmedVehicle";
import UnverifiedVehicle from "./pages/VehicleManagement/unverifiedVehicle";
import VehicleOverview from "./pages/VehicleManagement/vehicleOverview";
import ConfirmedRider from "./pages/DriverManagement/confirmedRider";
import DisciplinaryMeasure from "./pages/DriverManagement/disciplinaryMeasure";
import UnconfirmedRider from "./pages/DriverManagement/unconfirmedRider";
import UnverifiedRider from "./pages/DriverManagement/unverifiedRider";
import Incomes from "./pages/FinanceManagement/incomes";
import Settlement from "./pages/FinanceManagement/settlement";
import Wallet from "./pages/FinanceManagement/wallet";
import Categories from "./pages/Settings/categories";
import ChangePassword from "./pages/Settings/changePassword";
import Permissions from "./pages/Settings/permissions";
import PriceManagement from "./pages/Settings/priceManagement";
import RatingQuestions from "./pages/Settings/ratingQuestions";
import Roles from "./pages/Settings/roles";
import UserProfile from "./pages/user/profile";
import { useDispatch, useSelector } from "react-redux";
import {
    getApprovedPartners,
    getNewPartners,
    getNewVehicles,
    getOrderReportByMonth,
    getOrderReportByYear,
    getOrders,
    getVehicleTypes,
    getVerifiedPartners,
    getIncomes
} from "./state/actions";
import fetchData from "./utils/fetchData";
import { useCallback, useEffect, useState } from "react";
import VehiclesSettings from "./pages/Settings/Vehicles";
import VehiclesSettings2 from "./pages/Settings/Vehicles2";
import KeySettings from "./pages/Settings/keys";
import Signup from "./pages/Signup/Signup";
import Footer from "./components/footer/footer";
import Signin from "./pages/Signin/Signin";
import { PrivateRoute } from "./utils/PrivateRoute";
import NewPartner from "./pages/PartnerManagement/newPartner";
import VerifiedPartners from "./pages/PartnerManagement/verifiedPartners";
import ApprovedPartner from "./pages/PartnerManagement/approvedPartners";
import UnconfirmedVehicle2 from "./pages/VehicleManagement/unconfirmedVehicle";
import NewVehicle from "./pages/VehicleManagement/newVehicle";
import PickUp from "./pages/PickUpManagement/pickUp";
import PickUpDetails from "./pages/PickUpManagement/pickUpDetails";
import CODReport from "./pages/OrderManagement/CODReport";
import DailySales from "./pages/OrderManagement/DailySales";
import DailyShipments from "./pages/OrderManagement/DailyShipments";
import DailySaleDetails from "./pages/OrderManagement/DailySaleDetails";
// import { PrivateRoute } from "./utils/PrivateRoute";
// import { isUserLoggedIn } from "./state/actions/account.actions";
// import { useHistory } from "react-router-dom";

// const resource = fetchData("/orders");

const AppRoutes = () => {
    const authenticated = useSelector((state) => state.auth.authenticated);
    // useEffect(() => {
    //     if (!authenticated) {
    //         return <Navigate replace={true} to="/signin" />;
    //     }
    // }, []);
    // useEffect(() => {
    //     return () => {
    //         if (!authenticated) {
    //             return <Navigate replace={true} to="/signin" />;
    //         }
    //     };
    // }, [authenticated]);

    // !authenticated && <Navigate replace={true} to="/signin" />;

    const dispatch = useDispatch();
    // dispatch(getOrders());
    // const auth = useState((state) => state.auth);
    // console.log("auth", auth);

    useEffect(() => {
        if(authenticated){
        dispatch(getOrders())
        dispatch(getVehicleTypes());
        dispatch(getNewPartners());
        dispatch(getVerifiedPartners());
        dispatch(getApprovedPartners());
        dispatch(getNewVehicles());
        dispatch(getOrderReportByMonth());
        dispatch(getOrderReportByYear());
        }
    }, [dispatch]);

    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
                <Route path="/pickUpDetails" element={<PickUpDetails />} />
                <Route path="/report" element={<IncidentReport />} />
                <Route path="/tripoverview" element={<TripOverview />} />
                <Route
                    path="/orders"
                    element={
                        <PrivateRoute>
                            <OrderList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/customers"
                    element={
                        <PrivateRoute>
                            <Customer />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/orderoverview"
                    element={
                        <PrivateRoute>
                            <OrderOverview />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/daily-shipments"
                    element={
                        <PrivateRoute>
                            <DailyShipments />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/rcs"
                    element={
                        <PrivateRoute>
                            <DailySales />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/rcs/:id"
                    element={
                        <PrivateRoute>
                            <DailySaleDetails />
                        </PrivateRoute>
                    }
                />
                <Route path="/partner-overview" element={<PartnerOverview />} />
                <Route
                    path="/confirmed-partners"
                    element={<ConfirmedPartner />}
                />
                <Route
                    path="/approved-partners"
                    element={<ApprovedPartner />}
                />
                <Route
                    path="/unconfirmed-partners"
                    element={
                        <PrivateRoute>
                            <UnconfirmedPartner />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/unverified-partners"
                    element={
                        <PrivateRoute>
                            <UnverifiedPartner />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/verified-partners"
                    element={
                        <PrivateRoute>
                            <VerifiedPartners />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/new-partners"
                    element={
                        <PrivateRoute>
                            <NewPartner />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/confirmed-vehicle"
                    element={<ConfirmedVehicle />}
                />
                <Route path="/new-vehicle" element={<NewVehicle />} />
                <Route
                    path="/unconfirmed-vehicle"
                    element={<UnconfirmedVehicle />}
                />
                <Route
                    path="/unverified-vehicle"
                    element={<UnverifiedVehicle />}
                />
                <Route path="/vehicle-overview" element={<VehicleOverview />} />
                <Route path="/confirmed-rider" element={<ConfirmedRider />} />
                <Route
                    path="/disciplinary-measure"
                    element={<DisciplinaryMeasure />}
                />
                <Route
                    path="/unconfirmed-rider"
                    element={<UnconfirmedRider />}
                />
                <Route path="/unverified-rider" element={<UnverifiedRider />} />
                <Route path="/incomes" element={<Incomes />} />
                <Route path="/Settlement" element={<Settlement />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/categories" element={<Categories />} />
                <Route
                    path="/change-password"
                    element={
                        <PrivateRoute>
                            <ChangePassword />
                        </PrivateRoute>
                    }
                />
                <Route path="/permissions" element={<Permissions />} />
                <Route path="/price-management" element={<PriceManagement />} />
                <Route path="/rating-questions" element={<RatingQuestions />} />
                <Route path="/userRoles" element={<Roles />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route
                    path="/vehicle-settings"
                    element={<VehiclesSettings />}
                />
                <Route
                    path="/vehicle-settings-2"
                    element={
                        <PrivateRoute>
                            <VehiclesSettings2 />
                        </PrivateRoute>
                    }
                />
                <Route path="/key-settings" element={<KeySettings />} />
                {/* PUBLIC ROUTES */}
                <Route path="/register" element={<Signup />} />
                <Route path="/signin" element={<Signin />} />
                <Route
                    path="/pickUp"
                    element={
                        <PrivateRoute>
                            <PickUp />
                        </PrivateRoute>
                    }
                />
                <Route path="/cod-report" element={<CODReport />} />


                {/* <Route path="*" element={<Home />} /> */}

                {/* <Footer /> */}
            </Routes>
        </Router>
    );
};

export default AppRoutes;
