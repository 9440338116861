import axiosInstance from "../../utils/axios";
import fetchData, { fetchData2 } from "../../utils/fetchData";
import {
    ratesConstants,
    addVehicleBrandConstants,
    categoriesConstants,
    changePasswordConstants,
    getVehicleBrandConstants,
    getVehicleTypeConstants,
    deleteVehicleType,
    editVehicleType,
    orderConstants,
    addVehicleTypeConstant,
    addVehicleTypeConstants,
    getVehicleSizeConstants,
    getVehicleModelConstants,
    addVehicleSizeConstants,
    editVehicleSizeConstant,
    deleteVehicleSizeConstants,
    editVehicleBrandConstants,
    deleteVehicleBrandConstants,
    addVehicleModelConstants,
    editVehicleModelConstants,
    deleteVehicleModelConstants,
    ratingQuestions,
} from "./constants";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export const getVehicleCategories = () => {
    return async (dispatch) => {
        dispatch({ type: categoriesConstants.GET_CATEGORIES_REQUEST });
        const res = await axiosInstance.get("/Settings/VehicleCategories");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: categoriesConstants.GET_CATEGORIES_SUCCESS,
                payload: { categories: data },
            });
        } else {
        }
    };
};

export const addVehicleCategory = (category) => {
    // console.log("newVehicleCategory", category);

    return async (dispatch) => {
        dispatch({
            type: categoriesConstants.POST_CATEGORIES_REQUEST,
        });
        const res = await axiosInstance.post(
            "/Settings/AddVehicleCategory",
            category
        );
        console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: categoriesConstants.POST_CATEGORIES_SUCCESS,
                // payload: { categories:  data }
            });
            toast.success('Category added Successfully', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getVehicleCategories();
        } else {
        }
    };
};

export const deleteVehicleCategory = (id) => {
    return async (dispatch) => {
        dispatch({ type: categoriesConstants.DELETE_CATEGORIES_REQUEST });
        const res = await axiosInstance.delete(
            `/Settings/DeleteVehicleCategory/${id}`
        );
        console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: categoriesConstants.DELETE_CATEGORIES_SUCCESS,
            });
            toast.success(`Category "${data.categoryName}" deleted successfully`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getVehicleCategories();
        }
    };
};

export const updateVehicleCategory = (category) => {
    
    return async (dispatch) => {
        dispatch({
            type: categoriesConstants.UPDATE_CATEGORIES_REQUEST,
        });
        const res = await axiosInstance.put(
            "/Settings/UpdateVehicleCategory",
            category
        );
        console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: categoriesConstants.UPDATE_CATEGORIES_SUCCESS,
                // payload: { category: data }
            });
            toast.success(`Category "${data.categoryName}" updated Successfully`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
        }
    };
};

export const getRatingQuestions = () => {
    return async (dispatch) => {
        dispatch({ type: ratingQuestions.GET_RATING_QUESTIONS_REQUEST });
        const res = await axiosInstance.get("/Settings/Questions");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: ratingQuestions.GET_RATING_QUESTIONS_SUCCESS,
                payload: { questions: data },
            });
        } else {
        }
    };
};

export const addRatingQuestion = (question) => {

    return async (dispatch) => {
        dispatch({
            type: ratingQuestions.ADD_RATING_QUESTIONS_REQUEST,
        });
        const res = await axiosInstance.post(
            "/Settings/AddQuestion",
            question
        );
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: ratingQuestions.ADD_RATING_QUESTIONS_SUCCESS,
            });
            toast.success('Question added Successfully', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getRatingQuestions();
        } else {
        }
    };
};

export const editRatingQuestion = (question) => {
    
    return async (dispatch) => {
        dispatch({
            type: ratingQuestions.EDIT_RATING_QUESTIONS_REQUEST,
        });
        const res = await axiosInstance.put(
            "/Settings/UpdateQuestion",
            question
        );
        console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: ratingQuestions.EDIT_RATING_QUESTIONS_SUCCESS,
                // payload: { category: data }
            });
            toast.success(`Question updated Successfully`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
        }
    };
};

export const deleteRatingQuestion = (id) => {
    return async (dispatch) => {
        dispatch({ type: ratingQuestions.DELETE_RATING_QUESTIONS_REQUEST });
        const res = await axiosInstance.delete(
            `/Settings/DeleteQuestion/${id}`
        );
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: ratingQuestions.DELETE_RATING_QUESTIONS_SUCCESS,
            });
            toast.warning(`Question deleted successfully`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getVehicleCategories();
        }
    };
};

export const getRates = () => {
    return async (dispatch) => {
        dispatch({ type: ratesConstants.GET_RATES_REQUEST });
        const res = await axiosInstance.get("/Settings/Rates");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: ratesConstants.GET_RATES_SUCCESS,
                payload: { newRates: data },
            });
        } else {
        }
    };
};

export const addRate = (newRate) => {
    console.log("newRate", newRate);

    return async (dispatch, getState) => {
        dispatch({
            type: ratesConstants.ADD_RATES_REQUEST,
        });
        const res = await axiosInstance.post(
            "/Settings/AddRate",
            newRate
        );
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: ratesConstants.ADD_RATES_SUCCESS,
            });
            toast.success('Rate added Successfully', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            // getRates();
        } else {
            dispatch({
                type: ratesConstants.ADD_RATES_FAILURE,
            });
            toast.error("Error adding Rate")
        }
    };
};

export const updateRate = (rate) => {
    return async (dispatch) => {
        dispatch({
            type: ratesConstants.UPDATE_RATE_REQUEST,
        });
        const res = await axiosInstance.put(
            "/Settings/UpdateRate",
            rate
        );
        console.log("res", res);

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: ratesConstants.UPDATE_RATE_SUCCESS,
            });
            toast.success(`Price Rate of id "${data.id}" Updated Successfully`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
            getRates();
        } else {
            dispatch({
                type: ratesConstants.UPDATE_RATE_FAILURE,
            });
            toast.error("Error Updating Price Rate")
        }
    };
};

export const deleteRate = (id) => {
    return async (dispatch) => {
        dispatch({ type: ratesConstants.DELETE_RATE_REQUEST });
        const res = await axiosInstance.delete(
            `/Settings/DeleteRate/${id}`
        );
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: ratesConstants.DELETE_RATE_SUCCESS,
            });
            toast.warning("Price Rate Successfully Deleted", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getRates();
        }
    };
};

export const getCategories = () => {
    return async (dispatch) => {
        dispatch({ type: categoriesConstants.GET_CATEGORIES_REQUEST });
        const res = await axiosInstance.get("/categories");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: categoriesConstants.GET_CATEGORIES_SUCCESS,
                payload: { categories: data },
            });
        } else {
        }
    };
};

export const createCategories = (category) => {
    return async (dispatch) => {
        dispatch({
            type: categoriesConstants.POST_CATEGORIES_REQUEST,
        });
        const res = await axiosInstance.post("/Settings/AddVehicleCategory", category);

        if (res.status === 200) {
            const { data } = res;

            dispatch({
                type: categoriesConstants.POST_CATEGORIES_REQUEST,
                payload: { categories: data },
            });
        } else {
        }
    };
};

export const changePassword = (password) => {
    console.log("password", password);
    return async (dispatch) => {
        dispatch({
            type: changePasswordConstants.CHANGE_PASSWORD_REQUEST,
        });
        const res = await axiosInstance.post("/changePassword");

        if (res.status === 200) {
            dispatch({
                type: changePasswordConstants.CHANGE_PASSWORD_SUCCESS,
            });
        } else {
        }
    };
};



export const getVehicleTypes = () => {
    return async (dispatch) => {
        dispatch({ type: getVehicleTypeConstants.GET_VEHICLE_TYPES_REQUEST });
        const res = await axiosInstance.get("/Settings/VehicleTypes");
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: getVehicleTypeConstants.GET_VEHICLE_TYPES_SUCCESS,
                payload: { data },
            });
        }
    };
};

// export const addVehicleBrand = (newVehicleBrand) => {
//     return async (dispatch) => {
//         dispatch({ type: addVehicleBrandConstants.ADD_VEHICLE_BRAND_REQUEST });
//         const res = axiosInstance.post(
//             "/Settings/AddVehicleBrand",
//             newVehicleBrand
//         );
//         console.log("res", res);
//         if (res.status === 200) {
//             dispatch({
//                 type: addVehicleBrandConstants.ADD_VEHICLE_BRAND_SUCCESS,
//             });
//         }
//     };
// };

export const getVehicleBrands = () => {
    return async (dispatch) => {
        dispatch({ type: getVehicleBrandConstants.GET_VEHICLE_BRANDS_REQUEST });
        const res = axiosInstance.get("/Settings/DeleteVehicleType");
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: getVehicleBrandConstants.GET_VEHICLE_BRANDS_SUCCESS,
                payload: { data },
            });
        }
    };
};

export const deleteVehicle = (id) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: deleteVehicleType.DELETE_VEHICLE_MODEL_REQUEST,
            });
            const res = await axiosInstance.delete(`/Settings/DeleteVehicleType/${id}`);
            console.log("res", res);
            if (res.status === 200) {
                dispatch({
                    type: deleteVehicleType.DELETE_VEHICLE_MODEL_SUCCESS,
                });
                toast.success(`Vehicle Type "${res.data.name}" deleted`, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
    
            dispatch({
                type: deleteVehicleType.DELETE_VEHICLE_MODEL_FAILURE,
                payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

export const editVehicle = (data) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: editVehicleType.EDIT_VEHICLE_MODEL_REQUEST,
            });
            const res = await axiosInstance.put("/Settings/UpdateVehicleType",data).then(
                function(response){
                    return response
                }
            );
            console.log("res", res);
            
            if (res.status === 200) {
                dispatch({
                    type: editVehicleType.EDIT_VEHICLE_MODEL_SUCCESS,
                });
                toast.success(`Vehicle Type Changed to "${res.data.name}"`, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
    
            dispatch({
                type: editVehicleType.EDIT_VEHICLE_MODEL_FAILURE,
                // payload: { message: error.message, data: error.response.data },
            });
        }
    };
};


export const addVehicleType = (data) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: addVehicleTypeConstant.ADD_VEHICLE_MODEL_REQUEST,
            });
            const res = await axiosInstance.post("/Settings/AddVehicleType",data);
            console.log("res", res);
            if (res.status === 200) {
                dispatch({
                    type: addVehicleTypeConstant.ADD_VEHICLE_MODEL_SUCCESS,
                });
                toast.success('Vehicle Type added Successfully', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            return res
        } catch (error) {
    
            dispatch({
                type: addVehicleTypeConstant.ADD_VEHICLE_MODEL_FAILURE,
                // payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

// Vehicle Size Actions
export const getVehicleSize = () => {
    return async (dispatch) => {
        dispatch({ type: getVehicleSizeConstants.GET_VEHICLE_SIZE_REQUEST });
        const res = await axiosInstance.get("/Settings/VehicleSizes");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: getVehicleSizeConstants.GET_VEHICLE_SIZE_SUCCESS,
                payload: { data },
            });
        }
    };
};

export const addVehicleSize = (data) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: addVehicleSizeConstants.ADD_VEHICLE_SIZE_REQUEST,
            });
            const res = await axiosInstance.post("/Settings/AddVehicleSize",data);
            console.log("res", res);

            if (res.status === 200) {
                dispatch({
                    type: addVehicleSizeConstants.ADD_VEHICLE_SIZE_SUCCESS,
                });
                toast.success('Vehicle Size added Successfully', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            return res
        } catch (error) {
    
            dispatch({
                type: addVehicleSizeConstants.ADD_VEHICLE_SIZE_FAILURE,
                // payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

export const editVehicleSize = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: editVehicleSizeConstant.EDIT_VEHICLE_SIZE_REQUEST,
            });
            const res = await axiosInstance.put("/Settings/UpdateVehicleSize",data).then(
                function(response){
                    return response
                }
            );
            console.log("res", res);
            
            if (res.status === 200) {
                dispatch({
                    type: editVehicleSizeConstant.EDIT_VEHICLE_SIZE_SUCCESS,
                });
                toast.success(`Vehicle Size changed to "${res.data.name}"`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            }
        } catch (error) {
    
            dispatch({
                type: editVehicleSizeConstant.EDIT_VEHICLE_SIZE_FAILURE,
                // payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

export const deleteVehicleSize = (id) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: deleteVehicleSizeConstants.DELETE_VEHICLE_SIZE_REQUEST,
            });
            const res = await axiosInstance.delete(`/Settings/DeleteVehicleSize/${id}`);
            console.log("res", res);

            if (res.status === 200) {
                dispatch({
                    type: deleteVehicleSizeConstants.DELETE_VEHICLE_SIZE_SUCCESS,
                });
                toast.success(`Vehicle Size "${res.data.name}" deleted`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            }
        } catch (error) {
    
            dispatch({
                type: deleteVehicleSizeConstants.DELETE_VEHICLE_SIZE_FAILURE,
                payload: { message: error.message, data: error.response.data },
            });
        }
    };
};


// Vehicle Size Actions Ends


// Vehicle Brand Actions
export const getVehicleBrand = () => {
    return async (dispatch) => {
        dispatch({ type: getVehicleBrandConstants.GET_VEHICLE_BRANDS_REQUEST });
        const res = await axiosInstance.get("/Settings/VehicleBrands");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: getVehicleBrandConstants.GET_VEHICLE_BRANDS_SUCCESS,
                payload: { data },
            });
        }
    };
};

export const addVehicleBrand = (data) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: addVehicleBrandConstants.ADD_VEHICLE_BRAND_REQUEST,
            });
            const res = await axiosInstance.post("/Settings/AddVehicleBrand",data);
            console.log("res", res);

            if (res.status === 200) {
                dispatch({
                    type: addVehicleBrandConstants.ADD_VEHICLE_BRAND_SUCCESS,
                });
                toast.success('Vehicle Brand added Successfully', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            return res
        } catch (error) {
    
            dispatch({
                type: addVehicleBrandConstants.ADD_VEHICLE_BRAND_FAILURE,
                // payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

export const editVehicleBrand = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: editVehicleBrandConstants.EDIT_VEHICLE_BRAND_REQUEST,
            });
            const res = await axiosInstance.put("/Settings/UpdateVehicleBrand",data).then(
                function(response){
                    return response
                }
            );
            console.log("res", res);
            
            if (res.status === 200) {
                dispatch({
                    type: editVehicleBrandConstants.EDIT_VEHICLE_BRAND_SUCCESS,
                });
                toast.success(`Vehicle Brand name changed to "${res.data.name}"`, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
    
            dispatch({
                type: editVehicleBrandConstants.EDIT_VEHICLE_BRAND_FAILURE,
                // payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

export const deleteVehicleBrand = (id) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: deleteVehicleBrandConstants.DELETE_VEHICLE_BRAND_REQUEST,
            });
            const res = await axiosInstance.delete(`/Settings/DeleteVehicleBrand/${id}`);
            console.log("res", res);
            if (res.status === 200) {
                dispatch({
                    type: deleteVehicleBrandConstants.DELETE_VEHICLE_BRAND_SUCCESS,
                });
                toast.success(`Vehicle Brand "${res.data.name}" Deleted`, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
    
            dispatch({
                type: deleteVehicleBrandConstants.DELETE_VEHICLE_BRAND_FAILURE,
                payload: { message: error.message, data: error.response.data },
            });
            toast.error(`${error.res.data.title}`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }
    };
};




// Vehicle Brand Actions Ends



export const getVehicleModel = () => {
    return async (dispatch) => {
        dispatch({ type: getVehicleModelConstants.GET_VEHICLE_MODEL_REQUEST });
        const res = await axiosInstance.get("/Settings/VehicleModels");

        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: getVehicleModelConstants.GET_VEHICLE_MODEL_SUCCESS,
                payload: { data },
            });
        }
    };
};

export const addVehicleModel = (data) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: addVehicleModelConstants.ADD_VEHICLE_MODEL_REQUEST,
            });
            const res = await axiosInstance.post("/Settings/AddVehicleModel",data);
            console.log("res", res);

            if (res.status === 200) {
                dispatch({
                    type: addVehicleModelConstants.ADD_VEHICLE_MODEL_SUCCESS,
                });
                toast.success('Vehicle Model added Successfully', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            return res
        } catch (error) {
    
            dispatch({
                type: addVehicleModelConstants.ADD_VEHICLE_MODEL_FAILURE,
                // payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

export const editVehicleModel = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: editVehicleModelConstants.EDIT_VEHICLE_MODEL_REQUEST,
            });
            const res = await axiosInstance.put("/Settings/UpdateVehicleModel",data).then(
                function(response){
                    return response
                }
            );
            console.log("res", res);
            
            if (res.status === 200) {
                dispatch({
                    type: editVehicleModelConstants.EDIT_VEHICLE_MODEL_SUCCESS,
                });
                toast.success(`Vehicle model name changed to "${res.data.name}"`, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
    
            dispatch({
                type: editVehicleBrandConstants.EDIT_VEHICLE_BRAND_FAILURE,
                // payload: { message: error.message, data: error.response.data },
            });
        }
    };
};

export const deleteVehicleModel = (id) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: deleteVehicleModelConstants.DELETE_VEHICLE_MODEL_REQUEST,
            });
            const res = await axiosInstance.delete(`/Settings/DeleteVehicleModel/${id}`);
            console.log("res", res);

            if (res.status === 200) {
                // const data = res;
                dispatch({
                    type: deleteVehicleModelConstants.DELETE_VEHICLE_MODEL_SUCCESS,
                });
                toast.success(`Vehicle Model "${res.data.name}" deleted`, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
    
            dispatch({
                type: deleteVehicleModelConstants.DELETE_VEHICLE_MODEL_FAILURE,
                payload: { message: error.message, data: error.response.data },
            });
        }
    };
};