import React from "react";

const SignupSigninFooter = () => {
    return (
        <footer className="">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a href="privacy-policy.html">Privacy Policy</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="terms-of-service.html">Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                        <span className="mr-1">
                            Copyright
                            <script>
                                document.write(new Date().getFullYear())
                            </script>
                            ©{" "}
                            <a href="#" className="">
                                RedSpeed
                            </a>
                            All Rights Reserved.
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default SignupSigninFooter;
