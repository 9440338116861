import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TextField,
  } from "@mui/material";
 import React, { useCallback, useEffect, useState } from "react";
 import NavBar from "../../components/navbar/navbar.component";
 import SideBar from "../../components/sidebar/sidebar.component";
 import { useDispatch, useSelector } from "react-redux";
 
 const UserRoles = () => {
    const [openViewDetail, setOpenViewDetail] = useState(false);
    const handleClickOpenViewDetail = () => {
        setOpenViewDetail(true);
    }
    const handleCloseViewDetail = () => {
        setOpenViewDetail(false);
    }
 
    const [page, setPage] = useState(0);
 
    const handlePageChange = useCallback(
       (_, page) => {
          setPage(page);
       },
       [setPage]
    )
 
    let rowsPerPage = 10;
    return (
     <>
       <NavBar />
       <SideBar />
 
       <div className="content-page">
         <div className="container-fluid">
           <div className="row">
             <div className="col-sm-12">
               <div className="card">
                 <div className="card-header d-flex justify-content-between">
                   <div className="header-title">
                     <h4 className="card-title">Users List</h4>
                   </div>
                 </div>
                 <div className="card-body">
                    <div>
                        <span className="float-right mb-3 mr-2">
                            <button
                                type="button"
                                className="btn btn-primary mt-2"
                                onClick={handleClickOpenViewDetail}
                            >
                                <i className="ri-add-fill">
                                    <span className="pl-1">
                                        Add New
                                    </span>
                                </i>
                            </button>
                        </span>
                    </div>
                    <div>
                      <TablePagination
                      component="div"
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                         "aria-label": "Previous Page",
                      }}
                      nextIconButtonProps={{
                         "aria-label": "Next Page"
                      }}
                      onPageChange={handlePageChange}
                      />
                    </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </>
   );
 };
 
 export default UserRoles;
 