import axiosInstance from "../../utils/axios";
import axios from "axios";
// import fetchData, { fetchData2 } from "../../utils/fetchData";
import {
    approvedVehiclesConstants,
    newVehiclesConstants,
    verifiedVehiclesConstants,
    addInspectionConstants,
    verifyVehicleConstants,
    approveVehicleConstants,
    importsConstants
} from "./constants";
import { api } from "../../urlConfig";
import { axiosConfig } from "../../utils/axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const baseURL = api

export const getNewVehicles = () => {
    return async (dispatch) => {
        dispatch({
            type: newVehiclesConstants.GET_NEW_VEHICLES_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/vehicles/new");
        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: newVehiclesConstants.GET_NEW_VEHICLES_SUCCESS,
                payload: { newVehicles: data },
            });
        } else {
        }
    };
};

export const getVerifiedVehicles = () => {
    return async (dispatch) => {
        dispatch({
            type: verifiedVehiclesConstants.GET_VERIFIED_VEHICLES_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/vehicles/verified");
        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: verifiedVehiclesConstants.GET_VERIFIED_VEHICLES_SUCCESS,
                payload: { verifiedVehicles: data },
            });
        } else {
        }
    };
};

export const getApprovedVehicles = () => {
    return async (dispatch) => {
        dispatch({
            type: approvedVehiclesConstants.GET_APPROVED_VEHICLES_REQUEST,
        });
        const res = await axiosInstance.get("/Admin/vehicles/approved");
        // console.log("res", res);
        if (res.status === 200) {
            const { data } = res;
            dispatch({
                type: approvedVehiclesConstants.GET_APPROVED_VEHICLES_SUCCESS,
                payload: { approvedVehicles: data },
            });
        } else {
        }
    };
};

export const inspectVehicle = (vehicle) => {
    return async (dispatch) => {
        dispatch({
            type: addInspectionConstants.POST_ADD_INSPECTION_REQUEST,
        });
        const res = await axiosInstance.post("/Admin/InspectVehicle", vehicle);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: addInspectionConstants.POST_ADD_INSPECTION_SUCCESS,
                // payload: { inspectVehicles: data },
            });
            toast.success("Vehicle inspection carried out successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getVerifiedVehicles();
        } else {
        }
    };
};

async function getdownloadRow(fileName, filePath) {
  const response = await axios.get(
    `${baseURL}/Admin/DownloadVehicleTemplate`, {responseType: 'blob', headers: axiosConfig.headers});
  return response;
}

export const getVehicleTemplate = () => {
    getdownloadRow().then((res) => {
      console.log('res:', res.data)
      let url = window.URL.createObjectURL(res.data);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'VehicleTemplate.xlsx';
      a.click();
    });
  }

export const importVehicle = (id, file) => {
    return async (dispatch) => {
        dispatch({
            type: importsConstants.IMPORT_VEHICLE_REQUEST,
        });
        const formData = new FormData()
        formData.append('PartnerId', id)
        formData.append('File', file)
        const res = await axiosInstance.post(`/Admin/ImportVehicle`, formData);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: importsConstants.IMPORT_VEHICLE_SUCCESS,
            });
            toast.success("Vehicles Information Uploaded", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getNewVehicles();
        } else {
            dispatch({
                type: importsConstants.IMPORT_VEHICLE_ERROR,
            });
            toast.error(res.data.title, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };
};

export const verifyVehicle = (id) => {
    return async (dispatch) => {
        dispatch({
            type: verifyVehicleConstants.VERIFY_VEHICLE_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/VerifyVehicle/${id}`);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: verifyVehicleConstants.VERIFY_VEHICLE_SUCCESS,
            });
            toast.success("Vehicle verified successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getNewVehicles();
        }
    };
};

export const approveVehicle = (id) => {
    return async (dispatch) => {
        dispatch({
            type: approveVehicleConstants.APPROVE_VEHICLE_REQUEST,
        });
        const res = await axiosInstance.get(`/Admin/ApproveVehicle/${id}`);
        console.log("res", res);

        if (res.status === 200) {
            dispatch({
                type: approveVehicleConstants.APPROVE_VEHICLE_SUCCESS,
            });
            toast.success("Vehicle approved successfully", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            getApprovedVehicles();
        }
    };
};
