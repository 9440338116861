import {
    registerAsAdminConstants,
    signinAsAdminConstants,
} from "../actions/constants";

const initState = {
    loading: false,
    adminCreated: false,
    authenticated: false,
    error: "",
    userAdmin: {},
};

export const authReducer = (state = initState, action) => {
    switch (action.type) {
        case registerAsAdminConstants.REGISTER_AS_ADMIN_REQUEST:
            return (state = {
                ...state,
                loading: true,
            });
        case registerAsAdminConstants.REGISTER_AS_ADMIN_SUCCESS:
            return (state = {
                ...state,
                loading: false,
                adminCreated: true,
            });
        case registerAsAdminConstants.REGISTER_AS_ADMIN_FAILURE:
            return (state = {
                ...state,
                loading: false,
                error: action.payload.message,
            });
        case signinAsAdminConstants.GET_TOKEN_REQUEST:
            return (state = {
                ...state,
                authenticating: true,
            });
        case signinAsAdminConstants.SIGNIN_AS_ADMIN_REQUEST:
            return (state = {
                ...state,
                loading: true,
            });

        case signinAsAdminConstants.SIGNIN_AS_ADMIN_SUCCESS:
            localStorage.setItem("token", action.payload.data.token);
            return (state = {
                ...state,
                loading: false,
                authenticated: true,
                userAdmin: action.payload.data,
            });
        case signinAsAdminConstants.SIGNIN_AS_ADMIN_FAILURE:
            return;
        default:
            return { ...state };
    }
};
