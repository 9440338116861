import {
    approvedVehiclesConstants,
    newVehiclesConstants,
    verifiedVehiclesConstants,
    addInspectionConstants,
    verifyVehicleConstants,
    approveVehicleConstants,
    importsConstants
} from "../actions/constants";

const initState = {
    // Vehicles: [],
    newVehicles: [],
    verifiedVehicles: [],
    approvedVehicles: [],
    error: {},
};

export const vehiclesReducer = (state = initState, action) => {
    switch (action.type) {
        case approvedVehiclesConstants.GET_APPROVED_VEHICLES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case approvedVehiclesConstants.GET_APPROVED_VEHICLES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                approvedVehicles: action.payload.approvedVehicles,
            });
        case verifiedVehiclesConstants.GET_VERIFIED_VEHICLES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case verifiedVehiclesConstants.GET_VERIFIED_VEHICLES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                verifiedVehicles: action.payload.verifiedVehicles,
            });
        case newVehiclesConstants.GET_NEW_VEHICLES_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case newVehiclesConstants.GET_NEW_VEHICLES_SUCCESS:
            return (state = {
                ...state,
                status: 'idle',
                newVehicles: action.payload.newVehicles,
            });
        case addInspectionConstants.POST_ADD_INSPECTION_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             });
        case addInspectionConstants.POST_ADD_INSPECTION_SUCCESS:
            return (state = {
                ...state,
                status: 'idle'
                // inspectVehicles: action.payload.inspectVehicles,
            });
        case verifyVehicleConstants.VERIFY_VEHICLE_REQUEST:
            return (state = { 
                ...state,
                status: 'loading'
             })
        case verifyVehicleConstants.VERIFY_VEHICLE_SUCCESS: 
            return (state = { 
                ...state ,
                status: 'idle'
            });

        case approveVehicleConstants.APPROVE_VEHICLE_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            })
        case approveVehicleConstants.APPROVE_VEHICLE_SUCCESS: 
            return (state = { 
                ...state, 
                status: 'idle'
            });

        case importsConstants.IMPORT_VEHICLE_REQUEST:
            return (state = {
                ...state,
                status: 'loading'
            })
        case importsConstants.IMPORT_VEHICLE_SUCCESS:
            return (state ={
                ...state,
                status: 'idle'
            })
        case importsConstants.IMPORT_VEHICLE_ERROR:
            return (state ={
                ...state,
                status: 'idle'
            })

        default:
            return { ...state };
    }
};

// export const unAPPROVEDVehiclesReducer = (state = initState, action) => {
//     switch (action.type) {
//         case unAPPROVEDVehiclesConstants.GET_UNAPPROVED_VEHICLES_REQUEST:
//             return (state = { ...state });
//         case unAPPROVEDVehiclesConstants.GET_UNAPPROVED_VEHICLES_SUCCESS:
//             return (state = {
//                 ...state,
//                 unConfimedVehicles: action.payload.confimedVehicles,
//             });

//         default:
//             return { ...state };
//     }
// };

// export const unVerifiedVehiclesReducer = (state = initState, action) => {
//     switch (action.type) {
//         case unAPPROVEDVehiclesConstants.GET_UNAPPROVED_VEHICLES_REQUEST:
//             return (state = { ...state });
//         case unAPPROVEDVehiclesConstants.GET_UNAPPROVED_VEHICLES_SUCCESS:
//             return (state = {
//                 ...state,
//                 verifiedVehicles: action.payload.confimedVehicles,
//             });

//         default:
//             return { ...state };
//     }
// };
