import {
    Dialog,
    DialogContent,
    DialogContentText,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import {
    addVehicleCategory,
    getVehicleCategories,
    getVehicleSize,
    deleteVehicleCategory,
    updateVehicleCategory
} from "../../state/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const VehicleCategories = () => {
    const dispatch = useDispatch();
    const allCategories = useSelector((state) => state.settings.categories);
    const vehicleSizes = useSelector((state) => state.settings.vehicleSize);
    console.log("categories :>>", allCategories)
    const isLoading = useSelector((state) => state.settings.status)
    const [openViewDetail, setOpenViewDetail] = useState(false);
    const [openEditDetail, setOpenEditDetail] = useState(false);
    const [openDeleteDetail, setOpenDeleteDetail] = useState(false);
    const handleClickOpenViewDetail = () => {
        setOpenViewDetail(true);
    };

    const handleCloseViewDetail = () => {
        setOpenViewDetail(false);
    };
    const handleCloseEditDetail = () => {
        setOpenEditDetail(false);
    };
    const handleCloseDeleteDetail = () => {
        setOpenDeleteDetail(false);
    };
    
    useEffect(() => {
        dispatch(getVehicleCategories());
        dispatch(getVehicleSize())
    }, [dispatch]);

    const [vehicleCategoryForm, setVehicleCategoryForm] = useState({
        categoryName: "",
        // vehicleSize: "",
        baseFare: "",
        bookingFare: "",
        sizeId: "",
        limitDistance: 0,
        distanceFare: 0
    });
    const handleVehicleCategoryInputChange = (e) => {
        const field = e.target.name;
        const nextVehicleCategoryFormState = {
            ...vehicleCategoryForm,
            [field]: e.target.value,
        };
        setVehicleCategoryForm(nextVehicleCategoryFormState);
    };
    const [selectedCategoryEdit, setSelectedCategoryEdit] = useState({});
    const [vehicleCategoryFormEdit, setVehicleCategoryFormEdit] =
        useState(selectedCategoryEdit);
    const selectCategoryEdit = (category) => {
        const newCategory = {
            ...category,
            vehicleSize: vehicleSizes.name,
        };
        // setSelectedCategoryEdit(category);
        setVehicleCategoryFormEdit(newCategory);
        console.log("selectedCategoryEdit :>> ", category);
        setOpenEditDetail(true);
    };
    const handleVehicleCategoryInputChangeEdit = (e) => {
        const field = e.target.name;
        const nextVehicleCategoryFormStateEdit = {
            ...vehicleCategoryFormEdit,
            [field]: e.target.value,
        };
        setVehicleCategoryFormEdit(nextVehicleCategoryFormStateEdit);
    };

    const handleSubmitVehicleCategoryForm = (e) => {
        e.preventDefault();
        console.log("vehicleCategoryForm :>> ", vehicleCategoryForm);
        // setOpenViewDetail(false);
         dispatch(addVehicleCategory(vehicleCategoryForm)).then(() => {
            dispatch(getVehicleCategories());
            setOpenViewDetail(false);
         });
        // const nextVehicleCategoryFormState = {
        //     ...vehicleCategoryForm,
        // };
        // setVehicleCategoryForm(nextVehicleCategoryFormState);
        setVehicleCategoryForm("");
    };
   
    const [selectedForDelete, setSelectedForDelete] = useState({});
    const selectForDelete = (category) => {
        setSelectedForDelete(category);
        console.log("selectedForCategory :>> ", category);
        setOpenDeleteDetail(true);
    };

    const handleDeleteVehicleCategory = (id) => {
        dispatch(deleteVehicleCategory(id)).then(() => {
            dispatch(getVehicleCategories());
            setOpenDeleteDetail(false);
        });
    };

    // let rowsPerPage = 10;

    const handleSubmitVehicleCategoryFormEdit = (e) => {
        e.preventDefault();
        console.log("vehicleCategoryFormEdit :>> ", vehicleCategoryFormEdit);
        // setOpenEditDetail(false)
        dispatch(updateVehicleCategory(vehicleCategoryFormEdit)).then(() => {
            dispatch(getVehicleCategories());
            setOpenEditDetail(false)
        });
    };

    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            Pricing Categories
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <span className="float-right mb-3 mr-2">
                                        <button
                                            type="button"
                                            className="btn btn-primary mt-2"
                                            onClick={handleClickOpenViewDetail}
                                        >
                                            <i className="ri-add-fill">
                                                <span className="pl-1">
                                                    Add New
                                                </span>
                                            </i>
                                        </button>
                                    </span>
                                    {isLoading === 'loading' ?
                                    <div className="text-center">
                                        <div className="spinner-border text-danger">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <p className="small">Getting Categories...</p>
                                    </div> :
                                    allCategories.length > 0 ? (
                                        <Table aria-labelledby="tableTitle">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="fw-bold">
                                                        Category Name
                                                    </TableCell>
                                                    <TableCell className="fw-bold">
                                                        Vehicle Size
                                                    </TableCell>
                                                    <TableCell className="fw-bold">
                                                        Base Fare
                                                    </TableCell>
                                                    <TableCell className="fw-bold">
                                                        Booking Fare
                                                    </TableCell>
                                                    <TableCell className="fw-bold">
                                                        Additional distance by Km
                                                    </TableCell>
                                                    <TableCell className="fw-bold">
                                                        Limit Distance
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allCategories.length > 0 &&
                                                    allCategories.map(
                                                        (category, index) => (
                                                            <TableRow
                                                                hover
                                                                tabindex={-1}
                                                                key={index}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        category.categoryName
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    {category &&
                                                                        category.vehicleSize &&
                                                                        category
                                                                            .vehicleSize
                                                                            .name}
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        category.baseFare
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        category.bookingFare
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        category.distanceFare
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        category.limitDistance
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    <button
                                                                        className="btn btn-sm btn-success"
                                                                        onClick={() => {
                                                                            selectCategoryEdit(
                                                                                category
                                                                            );
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                </TableCell>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    sx={{
                                                                        padding:
                                                                            "0.2",
                                                                        margin: "0 !important",
                                                                    }}
                                                                >
                                                                    <button
                                                                        className="btn btn-sm btn-danger"
                                                                        onClick={() => {
                                                                            selectForDelete(
                                                                                category
                                                                            );
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                            </TableBody>
                                        </Table>
                                    ) : null}
                                </div>
                            </div>

                            {/* Add New Category */}

                            <Dialog
                                open={openViewDetail}
                                onClose={handleCloseViewDetail}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/* <DialogTitle>Rider Details</DialogTitle> */}
                                <DialogContent>
                                    <DialogContentText>
                                        <div className="modalContent">
                                            <div className="modal-header">
                                                <h5
                                                    className="modal-title"
                                                    id="exampleModalCenteredScrollableTitle"
                                                >
                                                    Add New Category
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        onClick={
                                                            handleCloseViewDetail
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form
                                                    onSubmit={
                                                        handleSubmitVehicleCategoryForm
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">
                                                            Category Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="categoryName"
                                                            value={
                                                                vehicleCategoryForm.categoryName
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">
                                                            Vehicle Size
                                                        </label>
                                                        {vehicleSizes.length >
                                                        0 ? (
                                                            <select
                                                                name="sizeId"
                                                                className="form-select form-control form-control-sm"
                                                                value={
                                                                    vehicleCategoryForm.sizeId
                                                                }
                                                                onChange={
                                                                    handleVehicleCategoryInputChange
                                                                }
                                                            >
                                                                {vehicleSizes.length >
                                                                    0 &&
                                                                    vehicleSizes.map(
                                                                        (
                                                                            vehicleSize,
                                                                            
                                                                        ) => (
                                                                            <option
                                                                                className=""
                                                                                id={
                                                                                    vehicleSize.id
                                                                                }
                                                                                value={
                                                                                    vehicleSize.id
                                                                                }
                                                                                key={vehicleSize.id}
                                                                            >
                                                                                {
                                                                                    vehicleSize.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </select>
                                                        ) : null}
                                                        ;
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="colFormLabel">
                                                            Base Fare
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="baseFare"
                                                            value={
                                                                vehicleCategoryForm.baseFare
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Booking Fare
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bookingFare"
                                                            value={
                                                                vehicleCategoryForm.bookingFare
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                     <div className="form-group">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Limit Distance
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="limitDistance"
                                                            value={
                                                                vehicleCategoryForm.limitDistance
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Additional Distance by km
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="distanceFare"
                                                            value={
                                                                vehicleCategoryForm.distanceFare
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="modal-footer px-0">
                                                        {isLoading === 'idle' && <button
                                                            type="submit"
                                                            className="table-add btn btn-primary"
                                                        >
                                                            Save
                                                        </button>}
                                                        {isLoading === 'loading' && <button
                                                            type="button"
                                                            className="table-add btn btn-primary"
                                                            disabled
                                                        >
                                                            Saving <i className="fas fa-spinner fa-spin"></i>
                                                        </button>}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                            {/* Edit Category */}

                            <Dialog
                                open={openEditDetail}
                                onClose={handleCloseEditDetail}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/* <DialogTitle>Rider Details</DialogTitle> */}
                                <DialogContent>
                                    <DialogContentText>
                                        <div className="modalContent">
                                            <div className="modal-header">
                                                <h5
                                                    className="modal-title"
                                                    id="exampleModalCenteredScrollableTitle"
                                                >
                                                    Edit Category
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        onClick={
                                                            handleCloseEditDetail
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form
                                                    onSubmit={
                                                        handleSubmitVehicleCategoryFormEdit
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">
                                                            Category Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="categoryName"
                                                            value={
                                                                vehicleCategoryFormEdit.categoryName
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChangeEdit
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">
                                                            Vehicle size
                                                        </label>
                                                        {vehicleSizes.length >
                                                        0 ? (
                                                            <select
                                                                name="sizeId"
                                                                className="form-select form-control form-control-sm"
                                                                value={
                                                                    vehicleCategoryFormEdit.sizeId
                                                                }
                                                                onChange={
                                                                    handleVehicleCategoryInputChangeEdit
                                                                }
                                                            >
                                                                {vehicleSizes.length >
                                                                    0 &&
                                                                    vehicleSizes.map(
                                                                        (
                                                                            vehicleSize,
                                                                            index
                                                                        ) => (
                                                                            <option
                                                                                value=""
                                                                                className=""
                                                                                key={index}
                                                                            >
                                                                                {
                                                                                    vehicleSize.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </select>
                                                        ) : null}
                                                        ;
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="colFormLabel">
                                                            Base Fare
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="baseFare"
                                                            value={
                                                                vehicleCategoryFormEdit.baseFare
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChangeEdit
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Booking Fare
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bookingFare"
                                                            value={
                                                                vehicleCategoryFormEdit.bookingFare
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChangeEdit
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Limit Distance
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="limitDistance"
                                                            value={
                                                                vehicleCategoryFormEdit.limitDistance
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChangeEdit
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Additional distance by km
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="distanceFare"
                                                            value={
                                                                vehicleCategoryFormEdit.distanceFare
                                                            }
                                                            onChange={
                                                                handleVehicleCategoryInputChangeEdit
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="modal-footer px-0">
                                                        {isLoading === 'idle' && <button
                                                            type="submit"
                                                            className="table-add btn btn-primary"
                                                        >
                                                            Save
                                                        </button>}
                                                        {isLoading === 'loading' && <button
                                                            type="button"
                                                            className="table-add btn btn-primary"
                                                            disabled
                                                        >
                                                            Please wait <i className="fas fa-spinner fa-spin"></i>
                                                        </button>}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                            {/* Delete Category */}
                            <Dialog
                                open={openDeleteDetail}
                                onClose={handleCloseDeleteDetail}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/* <DialogTitle>Rider Details</DialogTitle> */}
                                <DialogContent>
                                    <DialogContentText>
                                        <div className="modalContent">
                                            <div className="modal-header">
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        onClick={
                                                            handleCloseDeleteDetail
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div
                                                    className="alert alert-danger"
                                                    role="alert"
                                                >
                                                    <div className="mm-alert-text">
                                                        Do you want to delete
                                                        this category?
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Category Name
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.categoryName
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Base Fare
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.baseFare
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Booking Fare
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.bookingFare
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Limit Distance
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.limitDistance
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                dditional distance by km
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.distanceFare
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer px-0">
                                                    {isLoading === 'idle' && <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                        onClick={() =>
                                                            handleDeleteVehicleCategory(
                                                                selectedForDelete.id
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </button>}
                                                    {isLoading === 'loading' && <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                        disabled
                                                    >
                                                        Please wait <i className="fas fa-spinner fa-spin"></i>
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>
                            <ToastContainer />

                            {/* <div
                                className="modal fade"
                                id="newCategory"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalScrollableTitle"
                                aria-hidden="true"
                            >
                                <div
                                    className="modal-dialog modal-dialog-scrollable"
                                    role="document"
                                >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5
                                                className="modal-title"
                                                id="exampleModalScrollableTitle"
                                            >
                                                Add New Category
                                            </h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form
                                                onSubmit={
                                                    handleSubmitVehicleCategoryForm
                                                }
                                            >
                                                <div className="form-group">
                                                    <label for="colFormLabelSm">
                                                        Category Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="categoryName"
                                                        value={
                                                            vehicleCategoryForm.categoryName
                                                        }
                                                        onChange={
                                                            handleVehicleCategoryInputChange
                                                        }
                                                        className="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label for="colFormLabel">
                                                        Base Fare
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="baseFare"
                                                        value={
                                                            vehicleCategoryForm.baseFare
                                                        }
                                                        onChange={
                                                            handleVehicleCategoryInputChange
                                                        }
                                                        className="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    />
                                                </div>
                                                <div className="form-group mb-0">
                                                    <label
                                                        for="colFormLabelLg"
                                                        className="pb-0"
                                                    >
                                                        Booking Fare
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="bookingFare"
                                                        value={
                                                            vehicleCategoryForm.bookingFare
                                                        }
                                                        onChange={
                                                            handleVehicleCategoryInputChange
                                                        }
                                                        className="form-control form-control-sm"
                                                        id="colFormLabelSm"
                                                        placeholder=""
                                                    />
                                                </div>
                                                <div className="modal-footer px-0">
                                                    <button
                                                        type="submit"
                                                        className="table-add btn btn-primary"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleCategories;
