import {
  Dialog,
  DialogContent,
  DialogContentText,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { getRates, addRate, updateRate, deleteRate } from "../../state/actions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PriceManagement = () => {
  const dispatch = useDispatch();
  const rates = useSelector((state) => state.settings.newRates);
  const isLoading = useSelector((state) => state.settings.status)
  console.log('rates :>> ', rates);
  const [openEditDetail, setOpenEditDetail] = useState(false);
  const [openForDelete, setOpenForDelete] = useState(false);
  const handleCloseEditDetail = () => {
    setOpenEditDetail(false);
  };

  const handleCloseDeleteDetail = () => {
    setOpenForDelete(false);
  }

  useEffect(() => {
    dispatch(getRates())
  }, []);

  const [interStateForm, setInterStateForm] = useState({
    type: "",
    distanceRate: "",
    timeRate: "",
    waitingRate: "",
    otherLevy: ""
  })

  const handleInterStateInputChange = (e) => {
    const field = e.target.name;
    const nextInterStateFormState = {
      ...interStateForm,
      [field]: e.target.value,
    };
    setInterStateForm(nextInterStateFormState);
  };

  const handleSubmitInterState = (e) => {
    e.preventDefault();
    console.log("interStateForm :>> ", interStateForm);
    dispatch(addRate(interStateForm)).then(() => {
        dispatch(getRates())
    });
    const nextInterStateFormState = {
        ...interStateForm
    };
    setInterStateForm(nextInterStateFormState);
    const emptyObj = {
    type: '',
    distanceRate: "",
    timeRate: "",
    waitingRate: "",
    otherLevy: ""
    }
    setInterStateForm(emptyObj);

  };

  const [selectedRate, setSelectedRate] = useState({});
  const [rateEditForm, setRateEditForm] = useState(selectedRate);

  const selectRateEdit = (rate) => {
    const newRate = {
        ...rate,
    }
    setRateEditForm(newRate);
    console.log("selectedRate :>> ", rate);
    setOpenEditDetail(true);
  };
  const handleRateFormEditInputChange = (e) => {
    const field = e.target.name;
    const nextRateEditFormState = {
        ...rateEditForm,
        [field]: e.target.value,
    };
    setRateEditForm(nextRateEditFormState);
  };
  const handleSubmitRateEditForm = (e) => {
    e.preventDefault();
    console.log("rateEditForm :>> ", rateEditForm);
    // setOpenEditDetail(false)
    dispatch(updateRate(rateEditForm)).then(() => {
        dispatch(getRates());
        // toast.success("Price Rate Updated Successfully")
        setOpenEditDetail(false);
    });
  };

  const [selectedForDelete, setSelectedForDelete] = useState({});
  const selectForDelete = (rate) => {
    setSelectedForDelete(rate);
    console.log("selectedForDelete :>> ", rate);
    setOpenForDelete(true)
  };

  const handleDeleteRate = (id) => {
    // setOpenForDelete(false)
    dispatch(deleteRate(id)).then(() => {
        dispatch(getRates())
        setOpenForDelete(false);
    });
  }

  function rateType(type) {
    switch (type) {
        case 0:
            return ( <span>In-City</span> );
        case 1:
            return ( <span>Inter-State</span> );
        default:
            return;
    }
  }

  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-12 ">
              <div className="card">
                <div className="card-header justify-content-between">
                  <div className="header-title">
                    <h5 className="card-title">Price Management</h5>
                  </div>
                </div>
                <div className="card-body">
                  <ul
                  className="nav nav-tabs mt-2 mb-3 pb-0"
                  id="myTab-1"
                  role="tablist"
                  >
                    <li className="nav-item">
                        <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#ratesTable"
                        role="tab"
                        aria-controls="order"
                        aria-selected="true"
                        >
                            Rates
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#interStateForm"
                        role="tab"
                        aria-controls="recipient"
                        aria-selected="false"
                        >
                            Create New
                        </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div 
                    className="tab-pane fade show active" 
                    id="ratesTable"
                    role="tabpanel"
                    >
                        <div className="">
                            { isLoading === 'loading' ?
                            <div className="text-center">
                                <div className="spinner-border text-danger">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <p className="small">Fetching Price Rates...</p>
                            </div> :
                            rates.length > 0 ? (
                                <Table aria-labelledby="tableTitle">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Distance Rate</TableCell>
                                            <TableCell>Time Rate</TableCell>
                                            <TableCell>Waiting Rate</TableCell>
                                            <TableCell>Other Levy</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    { rates.length > 0 &&
                                        rates.map (
                                            (
                                                rate,
                                                index
                                            ) => (
                                                <TableRow
                                                hover
                                                tabindex={-1}
                                                key={index}
                                                >
                                                    <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        padding: "0.2",
                                                        margin: "0 !important"
                                                    }}
                                                    >
                                                        { rateType(rate.type) }
                                                    </TableCell>
                                                    <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        padding: "0.2",
                                                        margin: "0 !important"
                                                    }}
                                                    >
                                                        { rate.distanceRate }
                                                    </TableCell>
                                                    <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        padding: "0.2",
                                                        margin: "0 !important"
                                                    }}
                                                    >
                                                        { rate.timeRate}
                                                    </TableCell>
                                                    <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        padding: "0.2",
                                                        margin: "0 !important"
                                                    }}
                                                    >
                                                        { rate.waitingRate }
                                                    </TableCell>
                                                    <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        padding: "0.2",
                                                        margin: "0 !important"
                                                    }}
                                                    >
                                                        { rate.otherLevy }
                                                    </TableCell>
                                                    <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        padding: "0 !important",
                                                        margin: "0 !important"
                                                    }}
                                                    >
                                                        <button 
                                                        className="btn btn-sm btn-success"
                                                        type="button"
                                                        onClick={() => {
                                                            selectRateEdit(rate)
                                                        }}
                                                        >
                                                            Edit
                                                        </button>
                                                    </TableCell>
                                                    <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        padding: "0 !important",
                                                        margin: "0 !important"
                                                    }}
                                                    >
                                                        <button 
                                                        className="btn btn-sm btn-danger"
                                                        type="button"
                                                        onClick={() => {
                                                            selectForDelete(rate)
                                                        }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )
                                    }
                                    </TableBody>
                                </Table>
                            ) : null }
                        </div>

                        {/* Edit Rate */}
                        <Dialog
                                open={openEditDetail}
                                onClose={handleCloseEditDetail}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/* <DialogTitle>Rider Details</DialogTitle> */}
                                <DialogContent>
                                    <DialogContentText>
                                        <div className="modalContent">
                                            <div className="modal-header">
                                                <h5
                                                    className="modal-title"
                                                    id="exampleModalCenteredScrollableTitle"
                                                >
                                                    Edit Rate
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        onClick={
                                                            handleCloseEditDetail
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form
                                                    onSubmit={
                                                        handleSubmitRateEditForm
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <label for="colFormLabelSm">
                                                            Type
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="type"
                                                            value={
                                                                rateEditForm.type
                                                            }
                                                            onChange={
                                                                handleRateFormEditInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="colFormLabel">
                                                            Distance Rate
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="distanceRate"
                                                            value={
                                                                rateEditForm.distanceRate
                                                            }
                                                            onChange={
                                                                handleRateFormEditInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Time Rate
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="timeRate"
                                                            value={
                                                                rateEditForm.timeRate
                                                            }
                                                            onChange={
                                                                handleRateFormEditInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Waiting Rate
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="waitingRate"
                                                            value={
                                                                rateEditForm.waitingRate
                                                            }
                                                            onChange={
                                                                handleRateFormEditInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <label
                                                            for="colFormLabelLg"
                                                            className="pb-0"
                                                        >
                                                            Other Levy
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="otherLevy"
                                                            value={
                                                                rateEditForm.otherLevy
                                                            }
                                                            onChange={
                                                                handleRateFormEditInputChange
                                                            }
                                                            className="form-control form-control-sm"
                                                            id="colFormLabelSm"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <div className="modal-footer px-0">
                                                        {isLoading === 'idle' && <button
                                                            type="submit"
                                                            className="table-add btn btn-primary"
                                                        >
                                                            Save
                                                        </button> }
                                                        {isLoading === 'loading' && <button
                                                            type="button"
                                                            className="table-add btn btn-primary"
                                                            disabled
                                                        >
                                                            Adding changes <i className="fas fa-spinner fa-spin"></i>
                                                        </button> }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                        </Dialog>
                        <ToastContainer />

                        {/* Delete Rate */}
                        <Dialog
                                open={openForDelete}
                                onClose={handleCloseDeleteDetail}
                                fullWidth={true}
                                maxWidth="sm"
                            >
                                {/* <DialogTitle>Rider Details</DialogTitle> */}
                                <DialogContent>
                                    <DialogContentText>
                                        <div className="modalContent">
                                            <div className="modal-header">
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        onClick={
                                                            handleCloseDeleteDetail
                                                        }
                                                    >
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div
                                                    className="alert alert-danger"
                                                    role="alert"
                                                >
                                                    <div className="mm-alert-text">
                                                        Do you want to delete
                                                        this Rate?
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Type
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    rateType(selectedForDelete.type)
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Distance Rate
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.distanceRate
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Time Rate
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.timeRate
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Waiting Rate
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.waitingRate
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Other Levy
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {
                                                                    selectedForDelete.otherLevy
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer px-0">
                                                    {isLoading === 'idle' && <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                        onClick={() =>
                                                            handleDeleteRate(
                                                                selectedForDelete.id
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </button>}
                                                    {isLoading === 'loading' && <button
                                                        type="button"
                                                        className="table-add btn btn-primary"
                                                        disabled
                                                    >
                                                        Deleting <i className="fas fa-spinner fa-spin"></i>
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                        </Dialog>
                        {/* <ToastContainer/> */}
                    </div>
                    <div className="tab-pane fade show" id="interStateForm">
                      <div className="form-row d-block">
                        <form
                            onSubmit={
                                handleSubmitInterState
                            }
                        >
                            <div className="form-group ml-2">
                                <label for="validationDefault01">
                                    Type
                                </label>
                                <input
                                    type="number"
                                    name="type"
                                    value={
                                        interStateForm.type
                                    }
                                    onChange={
                                        handleInterStateInputChange
                                    }
                                    className="form-control"
                                    id="validationDefault01"
                                    required
                                />
                            </div>
                            <div className="form-group ml-2">
                                <label for="validationDefault01">
                                    Distance Rate
                                </label>
                                <input
                                    type="text"
                                    name="distanceRate"
                                    value={
                                        interStateForm.distanceRate
                                    }
                                    onChange={
                                        handleInterStateInputChange
                                    }
                                    className="form-control"
                                    id="validationDefault01"
                                    required
                                />
                            </div>
                            <div className="form-group ml-2">
                                <label for="validationDefault02">
                                    Time Rate
                                </label>
                                <input
                                    type="text"
                                    name="timeRate"
                                    value={
                                        interStateForm.timeRate
                                    }
                                    onChange={
                                        handleInterStateInputChange
                                    }
                                    className="form-control"
                                    id="validationDefault02"
                                    required
                                />
                            </div>
                            <div className="form-group ml-2">
                                <label for="validationDefault02">
                                    Waiting Rate
                                </label>
                                <input
                                    type="number"
                                    name="waitingRate"
                                    value={
                                        interStateForm.waitingRate
                                    }
                                    onChange={
                                        handleInterStateInputChange
                                    }
                                    className="form-control"
                                    id="validationDefault02"
                                    required
                                />
                            </div>
                            <div className="form-group ml-2">
                                <label for="validationDefaultUsername">
                                    Other Levy
                                </label>
                                <input
                                    type="text"
                                    name="otherLevy"
                                    value={
                                        interStateForm.otherLevy
                                    }
                                    onChange={
                                        handleInterStateInputChange
                                    }
                                    className="form-control"
                                    id="validationDefaultUsername"
                                    aria-describedby="inputGroupPrepend2"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                {isLoading === 'idle' && <button
                                    type="submit"
                                    className="btn btn-primary mr-2"
                                >
                                    Submit
                                </button> }
                                {isLoading === 'loading' && <button
                                    type="button"
                                    className="btn btn-primary mr-2"
                                    disabled
                                >
                                    Please wait... <i className="fas fa-spinner fa-spin"></i> 
                                </button> }
                            </div>
                        </form>
                      </div>
                      {/* <ToastContainer /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceManagement;
