import {
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { Box, getStackUtilityClass } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { getNewVehicles, verifyVehicle } from "../../state/actions";

const NewVehicle = () => {
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getNewVehicles());
    // }, []);
    const [page, setPage] = useState(0);
    const state = useSelector((state) => state);
    const {
        vehicles: { newVehicles },
    } = state;
    console.log("newVehicles :>> ", newVehicles);
    function getStatus(status) {
        switch (status) {
            case 0:
                return <span className="badge badge-danger">Pending</span>;
            case 1:
                return <span className="badge badge-success">Approved</span>;
            case 2:
                return (
                    <span className="badge badge-warning">
                        Awaiting Approval
                    </span>
                );

            default:
                return <span className="badge badge-danger">Pending</span>;
        }
    }
    const [selectedVehicle, setSelectedVehicle] = useState({});
    const [openViewDetail, setOpenViewDetail] = useState(false);
    const selectVehicle = (newVehicle) => {
        setSelectedVehicle(newVehicle);
        console.log("selectedVehicle :>> ", newVehicle);
        setOpenViewDetail(true);
    };
    const [selectedVerifiedVehicle, setSelectedVerifiedVehicle] = useState({});
    const [openViewDetailInspect, setOpenViewDetailInspect] = useState(false);
    const selectVehicleInspection = (verifiedVehicle) => {
        setSelectedVerifiedVehicle(verifiedVehicle);
        console.log("selectedVehicle :>> ", verifiedVehicle);
        setOpenViewDetailInspect(true);
    };

    const handleChangePage = useCallback(
        (_, page) => {
            setPage(page);
        },
        [setPage]
    );

    const handleCloseViewDetail = () => {
        setOpenViewDetail(false);
    };
    const handleCloseViewDetailInspect = () => {
        setOpenViewDetailInspect(false);
    };
    let rowsPerPage = 10;
    return (
        <>
            <NavBar />
            <SideBar />

            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">
                                            Unconfirmed Vehicles
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="collapse" id="datatable-1">
                                        <div className="card"></div>
                                    </div>
                                    <div className="table-responsive">
                                        <table
                                            id="datatable"
                                            className="table data-table table-striped table-bordered"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Vehicle Name</th>
                                                    <th>Registration No.</th>
                                                    <th>Driver Name</th>
                                                    <th>Verification Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>B56</td>
                                                    <td>Chint XXI</td>
                                                    <td>AMD889FJ</td>
                                                    <td>Alli Lawan</td>
                                                    <td>
                                                        <span className="badge badge-success">
                                                            Verified
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>B78</td>
                                                    <td>Mark VII</td>
                                                    <td>EKY123FJ</td>
                                                    <td>Samuel John</td>
                                                    <td>
                                                        <span className="badge badge-success">
                                                            Verified
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>B82</td>
                                                    <td>Mazda</td>
                                                    <td>IKD173GI</td>
                                                    <td>Samuel John</td>
                                                    <td>
                                                        <span className="badge badge-success">
                                                            Verified
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>D45</td>
                                                    <td>Ford VII</td>
                                                    <td>ZNK456AA</td>
                                                    <td>Mr. Shittu</td>
                                                    <td>
                                                        <span className="badge badge-success">
                                                            Verified
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>E78</td>
                                                    <td>Mark VIX</td>
                                                    <td>EKY223KM</td>
                                                    <td>Kassim Sheu</td>
                                                    <td>
                                                        <span className="badge badge-success">
                                                            Verified
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            data-toggle="modal"
                                                            data-target="#orderDetails1"
                                                        >
                                                            View Details
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <Box
                                        // width="110%"
                                        sx={{
                                            backgroundColor: "#fff",
                                            // marginLeft: theme.spacing(3),
                                        }}
                                    >
                                        <div>
                                            {newVehicles &&
                                            newVehicles.length > 0 ? (
                                                <Table aria-labelledby="tableTitle">
                                                    {/* <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={
                                                        handleRequestSort
                                                    }
                                                    rowCount={
                                                        newPartners.length
                                                    }
                                                    rows={rows}
                                                /> */}
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                sx={{
                                                                    width: "5% !important",
                                                                    padding:
                                                                        "0 5",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                ID
                                                            </TableCell>
                                                            <TableCell>
                                                                Registration No
                                                            </TableCell>
                                                            <TableCell>
                                                                Vehicle Brand
                                                            </TableCell>
                                                            <TableCell>
                                                                Vehicle Model
                                                            </TableCell>
                                                            <TableCell>
                                                                Year
                                                            </TableCell>
                                                            <TableCell>
                                                                Vehicle Type
                                                            </TableCell>
                                                            <TableCell>
                                                                Status
                                                            </TableCell>
                                                            <TableCell>
                                                                View Detail
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {newVehicles.length >
                                                            0 &&
                                                            newVehicles.map(
                                                                (
                                                                    newVehicle,
                                                                    index
                                                                ) => (
                                                                    <TableRow
                                                                        hover
                                                                        tabIndex={
                                                                            -1
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        onClick={() => {
                                                                            // selectRow(
                                                                            //     trackOrder
                                                                            // );
                                                                        }}
                                                                        sx={{
                                                                            height: "30 !important",
                                                                            // maxWidth: "50rem",
                                                                        }}
                                                                    >
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            sx={{
                                                                                width: "5% !important",
                                                                                padding:
                                                                                    "0 5",
                                                                                margin: "0 !important",
                                                                            }}
                                                                        >
                                                                            {newVehicle &&
                                                                                newVehicle.id}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            // component="th"
                                                                            scope="row"
                                                                        >
                                                                            {newVehicle &&
                                                                                newVehicle.regNo}
                                                                        </TableCell>

                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            sx={{
                                                                                // width: "5% !important",
                                                                                padding:
                                                                                    "0 5",
                                                                                margin: "0 !important",
                                                                            }}
                                                                        >
                                                                            {newVehicle &&
                                                                                newVehicle.vehicleBrand &&
                                                                                newVehicle
                                                                                    .vehicleBrand
                                                                                    .name}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            sx={{
                                                                                // width: "5% !important",
                                                                                padding:
                                                                                    "0 5",
                                                                                margin: "0 !important",
                                                                            }}
                                                                        >
                                                                            {newVehicle &&
                                                                                newVehicle.model &&
                                                                                newVehicle
                                                                                    .model
                                                                                    .name}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            // sx={{
                                                                            //     width: "5% !important",
                                                                            //     padding:
                                                                            //         "0 5",
                                                                            //     margin: "0 !important",
                                                                            // }}
                                                                        >
                                                                            {newVehicle &&
                                                                                newVehicle.year}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            // sx={{
                                                                            //     width: "5% !important",
                                                                            //     padding:
                                                                            //         "0 5",
                                                                            //     margin: "0 !important",
                                                                            // }}
                                                                        >
                                                                            {newVehicle &&
                                                                                newVehicle.vehicleType &&
                                                                                newVehicle
                                                                                    .vehicleType
                                                                                    .name}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            sx={{
                                                                                // width: "5% !important",
                                                                                padding:
                                                                                    "0 5",
                                                                                margin: "0 !important",
                                                                            }}
                                                                        >
                                                                            {getStatus(
                                                                                newVehicle.status
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                        >
                                                                            {newVehicle &&
                                                                                newVehicle.status &&
                                                                                newVehicle.status ===
                                                                                    0 && (
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            selectVehicle(
                                                                                                newVehicle
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Verify
                                                                                    </button>
                                                                                )}
                                                                            {newVehicle &&
                                                                                newVehicle.status &&
                                                                                newVehicle.status ===
                                                                                    2 && (
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            selectVehicleInspection(
                                                                                                newVehicle
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Inspect
                                                                                    </button>
                                                                                )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                    </TableBody>
                                                </Table>
                                            ) : // <Box m={2}>
                                            //     <HighlightedInformation>
                                            //         {emptyLineNotification}
                                            //     </HighlightedInformation>
                                            // </Box>
                                            null}
                                        </div>
                                        <div>
                                            <TablePagination
                                                component="div"
                                                count={newVehicles.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                backIconButtonProps={{
                                                    "aria-label":
                                                        "Previous Page",
                                                }}
                                                nextIconButtonProps={{
                                                    "aria-label": "Next Page",
                                                }}
                                                onPageChange={handleChangePage}
                                                labelRowsPerPage=""
                                            />
                                        </div>
                                    </Box>
                                    <Dialog
                                        open={openViewDetail}
                                        onClose={handleCloseViewDetail}
                                    >
                                        {/* <DialogTitle>Subscribe</DialogTitle> */}
                                        <DialogContent>
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalCenteredScrollableTitle"
                                                    >
                                                        Partner details
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            onClick={
                                                                handleCloseViewDetail
                                                            }
                                                        >
                                                            ×
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="media align-items-start">
                                                        <img
                                                            src="assets/images/user/1.jpg"
                                                            className="img-fluid avatar-rounded avatar-60"
                                                            alt="user"
                                                        />
                                                        <div className="media-body ml-2 row">
                                                            <div className="col-md-12">
                                                                <p className="mb-0 mt-1">
                                                                    {}
                                                                </p>
                                                                <span className="font-weight-bold">
                                                                    {}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-2">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Contact Name
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Contact Email
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Contact Phone
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Company Address
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12 pt-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                onClick={() =>
                                                                    dispatch(
                                                                        verifyVehicle(
                                                                            selectedVehicle.id
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Verify
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                            >
                                                                Reject
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        open={openViewDetailInspect}
                                        onClose={handleCloseViewDetailInspect}
                                    >
                                        {/* <DialogTitle>Subscribe</DialogTitle> */}
                                        <DialogContent>
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title"
                                                        id="exampleModalCenteredScrollableTitle"
                                                    >
                                                        Partner details
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            onClick={
                                                                handleCloseViewDetail
                                                            }
                                                        >
                                                            ×
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="media align-items-start">
                                                        <img
                                                            src="assets/images/user/1.jpg"
                                                            className="img-fluid avatar-rounded avatar-60"
                                                            alt="user"
                                                        />
                                                        <div className="media-body ml-2 row">
                                                            <div className="col-md-12">
                                                                <p className="mb-0 mt-1">
                                                                    {}
                                                                </p>
                                                                <span className="font-weight-bold">
                                                                    {}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-2">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Contact Name
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Contact Email
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Contact Phone
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media align-items-start mt-4">
                                                        <div className="media-body">
                                                            <p className="text mb-0">
                                                                Company Address
                                                            </p>
                                                            <span className="font-weight-bold d-block">
                                                                {}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12 pt-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                onClick={() =>
                                                                    dispatch(
                                                                        verifyVehicle(
                                                                            selectedVehicle.id
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Verify
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                            >
                                                                Reject
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Pop up */}

            <div
                id="orderDetails1"
                className="modal fade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenteredScrollableTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title"
                                id="exampleModalCenteredScrollableTitle"
                            >
                                Stage 2 Verfication
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-info" role="alert">
                                <div className="mm-alert-text">
                                    Approve Vehicle
                                </div>
                            </div>

                            <div className="media align-items-start">
                                <div className="media-body ml-2 row">
                                    <div className="col-md-8">
                                        <p className="mb-0 mt-1">IKD 567 MK</p>
                                        <span className="font-weight-bold">
                                            Truck (45 ft)
                                        </span>
                                    </div>
                                    <div className="col-md-3 pt-2">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                        >
                                            Disable
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="media align-items-start mt-4">
                                <div className="media-body">
                                    <p className="text mb-0">Model</p>
                                    <span className="font-weight-bold d-block">
                                        Ford 2009
                                    </span>
                                </div>
                            </div>
                            <div className="media align-items-start mt-4">
                                <div className="media-body">
                                    <p className="text mb-0">Color</p>
                                    <span className="font-weight-bold d-block">
                                        Blue
                                    </span>
                                </div>
                            </div>
                            <div className="media align-items-start mt-4">
                                <div className="media-body">
                                    <p className="text mb-0">Mileage</p>
                                    <span className="font-weight-bold d-block">
                                        156890KM
                                    </span>
                                </div>
                            </div>
                            <div className="media align-items-start mt-4">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Road Worthiness</td>
                                            <td>
                                                <span className="badge badge-success">
                                                    Verified
                                                </span>
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td> V Registration</td>
                                            <td>
                                                <span className="badge badge-success">
                                                    Verified
                                                </span>
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Road Insurance</td>
                                            <td>
                                                <span className="badge badge-success">
                                                    Verified
                                                </span>
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td> Hackney Permit</td>
                                            <td>
                                                <span className="badge badge-success">
                                                    Verified
                                                </span>
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-sm"
                                                >
                                                    Open
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="media align-items-start mt-4">
                                <div className="media-body">
                                    <p className="text mb-0">Inspection Note</p>
                                    <textarea
                                        className="form-control"
                                        cols="30"
                                        rows="3"
                                        disabled
                                    >
                                        Brake working fine, Engine is fine, Good
                                        tyre with extra.
                                    </textarea>
                                </div>
                            </div>
                            <div className="media align-items-start mt-4">
                                <div className="media-body">
                                    <p className="text mb-0">
                                        Vehicle Category
                                    </p>
                                    <select className="form-control">
                                        <option>40ft Container</option>
                                        <option>20ft Container</option>
                                        <option>Big Van</option>
                                        <option>Small Van</option>
                                        <option>Bike</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 pt-2">
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                    >
                                        Approve
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Reject
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewVehicle;
