import { orderConstants } from "../actions/constants";

const initState = {
  allOrders: [],
  reportByMonth: [],
  reportByYear: [],
  reportByStatus: [],
  codReport: [],
  cities: [],
  drivers: [],
  shipments: [],
  rcs: [],
  rcsDetail: {},
  fileData: null,
  status: "idle",
  download: "idle",
  posting: "idle",
  creating: "idle",
  downloading: "idle",
  error: {},
};

export const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case orderConstants.GET_ORDERS_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_ORDERS_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        allOrders: action.payload.allOrders,
      });
    case orderConstants.GET_ORDER_REPORT_BY_MONTH_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_ORDER_REPORT_BY_MONTH_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        reportByMonth: action.payload.reportByMonth,
      });
    case orderConstants.GET_ORDER_REPORT_BY_YEAR_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_ORDER_REPORT_BY_YEAR_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        reportByYear: action.payload.reportByYear,
      });

    case orderConstants.GET_ORDER_REPORT_BY_STATUS_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_ORDER_REPORT_BY_STATUS_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        reportByStatus: action.payload.reportByStatus,
      });
    case orderConstants.CANCEL_ORDER_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.CANCEL_ORDER_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
      });

    case orderConstants.GET_COD_REPORT_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_COD_REPORT_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        codReport: action.payload.codReport,
      });

    case orderConstants.EXPORT_COD_REQUEST:
      return (state = {
        ...state,
        download: "loading",
      });
    case orderConstants.EXPORT_COD_SUCCESS:
      return (state = {
        ...state,
        download: "idle"
      });
    case orderConstants.EXPORT_COD_FAILURE:
      return (state = {
        ...state,
        download: "idle"
      });

    case orderConstants.GET_TEMPLATE_REQUEST:
      return (state = {
        ...state,
        downloading: "loading",
      });
    case orderConstants.GET_TEMPLATE_SUCCESS:
      return (state = {
        ...state,
        downloading: "idle",
        fileData: action.payload.fileData,
      });
    case orderConstants.GET_TEMPLATE_FAILURE:
      return (state = {
        ...state,
        downloading: "idle",
      });
    
    case orderConstants.IMPORT_SHIPMENTS_REQUEST:
      return (state = {
        ...state,
        posting: "loading",
      });
    case orderConstants.IMPORT_SHIPMENTS_SUCCESS:
      return (state = {
        ...state,
        posting: "idle",
      });
    case orderConstants.IMPORT_SHIPMENTS_FAILURE:
      return (state = {
        ...state,
        posting: "idle",
        error: action.error
      });

    case orderConstants.GET_SHIPMENTS_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_SHIPMENTS_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        shipments: action.payload.shipments,
      });
    case orderConstants.GET_SHIPMENTS_FAILURE:
      return (state = {
        ...state,
        status: "idle",
      });
    
    case orderConstants.GENERATE_RCS_REQUEST:
      return (state = {
        ...state,
        creating: "loading",
      });
    case orderConstants.GENERATE_RCS_SUCCESS:
      return (state = {
        ...state,
        creating: "idle",
      });
    case orderConstants.GENERATE_RCS_FAILURE:
      return (state = {
        ...state,
        creating: "idle",
      });
    
    case orderConstants.GET_RCS_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_RCS_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        rcs: action.payload.rcs,
      });
    case orderConstants.GET_RCS_FAILURE:
      return (state = {
        ...state,
        status: "idle",
      });
    
    case orderConstants.GET_RCS_DETAIL_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_RCS_DETAIL_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        rcsDetail: action.payload.rcsDetail,
      });
    case orderConstants.GET_RCS_DETAIL_FAILURE:
      return (state = {
        ...state,
        status: "idle",
      });

    case orderConstants.CONFIRM_HANDOVER_REQUEST:
      return (state = {
        ...state,
        posting: "loading",
      });
    case orderConstants.CONFIRM_HANDOVER_SUCCESS:
      return (state = {
        ...state,
        posting: "idle"
      });
    case orderConstants.CONFIRM_HANDOVER_FAILURE:
      return (state = {
        ...state,
        posting: "idle",
      });

    case orderConstants.GET_CITIES_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_CITIES_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        cities: action.payload.cities,
      });
    case orderConstants.GET_CITIES_FAILURE:
      return (state = {
        ...state,
        status: "idle",
      });

    case orderConstants.GET_DRIVERS_REQUEST:
      return (state = {
        ...state,
        status: "loading",
      });
    case orderConstants.GET_DRIVERS_SUCCESS:
      return (state = {
        ...state,
        status: "idle",
        drivers: action.payload.drivers,
      });
    case orderConstants.GET_DRIVERS_FAILURE:
      return (state = {
        ...state,
        status: "idle",
      });

    default:
      return { ...state };
  }
};
