// * React imports
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom"

// * Third Party imports
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";

// * MUI imports
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// * action file imports
import { getRcsDetail, confirmRcs } from "../../state/actions";

const DailySaleDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  
  const rcsDetail = useSelector((state) => state.orders.rcsDetail);
  const isLoading = useSelector((state) => state.orders.status);
  const isPosting = useSelector((state) => state.orders.posting)

  useEffect(() => {
    dispatch(getRcsDetail(id))
  }, [dispatch]);

  const handleConfirm = async () => {
    const userAcknowledgement = window.confirm('Are you sure you want to confirm that all shipments have been picked by the courier?');
    if (userAcknowledgement) {
      try {
        const result = await dispatch(confirmRcs(rcsDetail.id))
        if (result && result.error) {
          console.log(result.error)
          alert(result.error)
        } else {
          alert('RCS Handover successful')
          navigate('/rcs')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <>
      <NavBar />
      <SideBar />

      <div class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  <div class="header-title">
                    <h4 class="card-title">RCS - {rcsDetail.no}</h4>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div className="d-flex justify-content-end">
                      { isPosting === 'loading' ?
                        (<button
                          className="btn btn-sm btn-primary"
                        >
                            <CircularProgress />
                        </button>
                        ) : (
                            <button
                            className="btn btn-sm btn-primary"
                            disabled={!rcsDetail.dischargedConfirmedByDriver}
                            onClick={handleConfirm}
                            >Confirm Handover</button>
                        )
                      }
                    </div>
                    {isLoading === "loading" ? (
                      <div class="text-center mt-4">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p className="small">Fetching Orders...</p>
                      </div>
                    ) : rcsDetail?.rcSaleItems?.length > 0 ? (
                      <div className="table-responsive">
                        <Table aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Order Number</TableCell>
                              <TableCell>Origin</TableCell>
                              <TableCell>Destination</TableCell>
                              <TableCell>Content</TableCell>
                              <TableCell>Weight</TableCell>
                              <TableCell>Quantity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rcsDetail?.rcSaleItems
                              ?.map((r, index) => (
                                <TableRow hover tabindex={-1} key={index}>
                                  <TableCell>
                                    { r.shipment.waybillNumber }
                                  </TableCell>
                                  <TableCell>
                                    { r.shipment.origin.name }
                                  </TableCell>
                                  <TableCell>
                                    { r.shipment.city.name }
                                  </TableCell>
                                  <TableCell>
                                    { r.shipment.contentDescription }
                                  </TableCell>
                                  <TableCell>
                                    { r.shipment.weight + "kg" }
                                  </TableCell>
                                  <TableCell>
                                    { r.shipment.unit }
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      <div className="text-center mx-auto mt-4">
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailySaleDetails;
