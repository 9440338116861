import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import { useDispatch, useSelector } from "react-redux";
import { getPickUps, getHubs } from "../../state/actions/pickUp.actions";
import { useNavigate } from "react-router-dom";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const PickUp = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pickUps, setPickups] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isLoading = useSelector((state) => state.pickup.status);
  // const pickUps = useSelector((state) => state.pickup.pickUps);
  const hubs = useSelector((state) => state.pickup.hubs);

  console.log("hubs :>>", hubs);

  useEffect(() => {
    dispatch(getHubs());
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pickUps.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGetPickups = (e) => {
    e.preventDefault();
    dispatch(getPickUps(hub)).then((res) => {
      setPickups(res);
    });
  };

  const navigate = useNavigate();
  const [hub, setHub] = useState(null);
  const [request, setIsRequest] = useState(false);
  // const [pickUps, setPickups] = useState([])
  const [selectedPickUp, setSelectedPickUP] = useState({});
  const selectPickUp = (id) => {
    localStorage.setItem("bagId", id);
    navigate("/pickUpDetails");
  };

  const handleHubSelect = (e) => {
    console.log("selectedHub", e.target.value);
    setHub(e.target.value);
  };

  return (
    <>
      <NavBar />
      <SideBar />

      <div className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <div className="header-title">
                    <h4 className="card-title">Pick a Bag</h4>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleGetPickups} className="d-flex">
                    <div className="form-group">
                      <label className="text mb-0">Select Hub:</label>
                      <select
                        className="form-select mx-4 px-2"
                        value={hub}
                        onChange={handleHubSelect}
                      >
                        <option value={null}>-- Select --</option>
                        {hubs.map((h) => (
                          <option value={h.id} key={h.id}>
                            {h.name}
                          </option>
                        ))}
                        {/* <option value={}>Hub</option> */}
                      </select>
                    </div>
                    <div className="">
                      <button type="submit" className="btn btn-sm btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                  <div>
                    {isLoading === "loading" ? (
                      <div className="text-center">
                        {/* <div className="spinner-border text-danger" role="status">
                         <span className="sr-only">Loading...</span>
                       </div> */}
                        <p className="small">Getting Pick Up Bags...</p>
                      </div>
                    ) : pickUps.length > 0 ? (
                      <Table aria-labelledby="tableTitle">
                        <TableHead>
                          <TableRow>
                            <TableCell>Bag Number</TableCell>
                            {/* <TableCell>Driver Name</TableCell>
                             <TableCell>Destination</TableCell> */}
                            <TableCell>Number of Items</TableCell>
                            {/* <TableCell>Total Weight</TableCell>
                             <TableCell>Content</TableCell> */}
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? pickUps.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : pickUps
                          )?.map((bag, index) => (
                            <TableRow hover tabindex={-1} key={index}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  // width: "5% !important",
                                  padding: "0.5",
                                  margin: "0 !important",
                                }}
                              >
                                {bag.pickupNo}
                              </TableCell>
                              {/* <TableCell
                                     component="th"
                                     scope="row"
                                     sx={{
                                       // width: "5% !important",
                                       padding: "0.5",
                                       margin: "0 !important"
                                     }}
                                   >
                                     
                                   </TableCell> */}
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  // width: "5% !important",
                                  padding: "0.5",
                                  margin: "0 !important",
                                }}
                              >
                                {bag.pickupItems.length}
                              </TableCell>
                              {/* <TableCell
                                     component="th"
                                     scope="row"
                                     sx={{
                                       // width: "5% !important",
                                       padding: "0.5",
                                       margin: "0 !important"
                                     }}
                                   >
                                     {
                                       3
                                     }
                                   </TableCell> */}
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    selectPickUp(bag.id);
                                  }}
                                >
                                  View Details
                                </button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <div className="text-center">
                        <p>No Pick Up Available</p>
                      </div>
                    )}
                  </div>
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      component="div"
                      count={pickUps.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickUp;
